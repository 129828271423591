import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { BLANK_IMAGE } from '../../assets/images';

const Image = ({ ...rest }) => (
	<LazyLoadImage
		{...rest}
		delayMethod="debounce"
		effect="blur"
		placeholderSrc={BLANK_IMAGE}
	/>
);

export default Image;
