import { styled as MuiStyled, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

export const StyledTab = MuiStyled(Tab)(() => ({
  textTransform: "none",
  "&.Mui-selected": {
    color: "var(--color-revival-orange-orange-5)",
  },
}));

export const StyledTabs = styled(Tabs)`
  & span.MuiTabs-indicator {
    background: var(--color-revival-orange-orange-5);
  }
`;
