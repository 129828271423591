import i18n from "i18next";
import * as Yup from "yup";

import { DATA_KEY, POPUP_TYPES } from "../../utils/constants";

export const AddGroupValidation = (type) => {
  const t = i18n.t;
  return Yup.object().shape({
    type: Yup.string().notRequired().nullable(),
    group_name: Yup.string().when("type", {
      is: (value) => type === POPUP_TYPES.CREATE_A_GROUP,
      then: Yup.string().required(t("ERROR_GROUP_NAME_REQUIRED")),
    }),
    well_site_name: Yup.string().when("type", {
      is: (value) => type === POPUP_TYPES.ADD_A_WELL_SITE,
      then: Yup.string().required(t("ERROR_WELL_SITE_NAME_REQUIRED")),
    }),
    account_admin_names: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    operators: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    assigned_group: Yup.string(),
    assigned_well_site: Yup.array().of(Yup.string()),
  });
};

export const EditGroupValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    group_name: Yup.string().required(t("ERROR_GROUP_NAME_REQUIRED")),
    account_admin_names: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    operators: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    assigned_group: Yup.array().of(Yup.string()),
  });
};

export const formatRowData = ({ groupId, groupName }) => {
  return {
    id: groupId,
    group_id: groupId,
    group_name: groupName,
    assigned_group: [],
  };
};

export const headCells = [
  { id: 1, name: "group_id", title: "Group ID" },
  { id: 2, name: "group_name", title: "Group Name" },
];

export const getDataArray = (data) => {
  return data?.groups;
};

export const formatGroupNames = (data) => {
  return data?.groups;
};

export const formatAccountAdminsNames = (data) => {
  return data?.user;
};

export const formatOperatorsNames = (data) => {
  return data?.user;
};

export const formatWellSitesList = (data) => {
  return data?.wellsites?.rows;
};

export const getWellSiteID = (wellSites, selectedWellSite) => {
  const data = wellSites?.wellsites?.rows.filter((wellSite) =>
    selectedWellSite.includes(wellSite[DATA_KEY.WELL_SITES])
  );

  return data.map((item) => item["wellsiteId"]);
};

export const getAccountAdminID = (accountAdmins, selectedAccountAdmins) => {
  const data = accountAdmins?.user?.filter((accountAdmin) =>
    selectedAccountAdmins.includes(accountAdmin[DATA_KEY.ACCOUNT_ADMINS])
  );
  return data.map((item) => item["userId"]);
};

export const getOperatorID = (operators, selectedOperator) => {
  const data = operators?.user?.filter((operator) =>
    selectedOperator.includes(operator[DATA_KEY.OPERATORS])
  );
  return data.map((item) => item["userId"]);
};

export const getReducedArray = (array, key, roleID) => {
  return array
    .filter((item) => item["roleId"] === roleID)
    .map((item) => item[key]);
};

export const getReducedString = (arr, key) => {
  return arr.map((item) => item[key]) || [];
};

export const getDerivedGroupData = ({ groupsDetail }) => {
  const { groupName, userDetails, wellsitesDetails, groupId } = groupsDetail;

  return {
    group_id: groupId,
    group_name: groupName,
    account_admin_names:
      getReducedArray(userDetails, DATA_KEY.ACCOUNT_ADMINS, 2) || [],
    operators: getReducedArray(userDetails, DATA_KEY.OPERATORS, 3) || [],
    assigned_well_site:
      getReducedString(wellsitesDetails, DATA_KEY.WELL_SITES) || "",
  };
};
