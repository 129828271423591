import { makeCancellableAPIRequest } from ".";

export function getWellSiteDetailsAPI(id) {
  const apiParams = {
    urlPath: `Wellsite/${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}
export function getWellSiteListAPI(params) {
  const apiParams = {
    urlPath: "wellsite",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function getWellSiteAcitvityListAPI(id) {
  const apiParams = {
    urlPath: `alert/audit/${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveWellSiteDetailsAPI(data) {
  const apiParams = {
    urlPath: "Wellsite",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function editWellSiteDetailsAPI(data) {
  const apiParams = {
    urlPath: "well-site-management/edit",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function deleteWellSiteDetailsAPI(id) {
  const apiParams = {
    urlPath: `wellsite/${id}`,
    method: "DELETE",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function deleteWellSiteAcitivityAPI(data) {
  const apiParams = {
    urlPath: `alert/clear`,
    method: "POST",
    options: { data },
  };
  return makeCancellableAPIRequest(apiParams);
}
