import { LocationOn } from "@mui/icons-material";
import { ClickAwayListener, List, ListItemText } from "@mui/material";
import React, { forwardRef, useEffect, useState, useCallback } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { Input } from "..";
import { GMAP_API_KEY } from "../../utils/config";
import { LocationSearchWrapper, StyledListItem, StyledPaper } from "./styles";

const LocationSearch = forwardRef(
  (
    {
      placeholder,
      restrictToIndia = false,
      onPlaceSelect,
      handleChange,
      defaultVal,
      showIcon = true,
      invalidMessage,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [localError, setLocalError] = useState(null);
    const [val, setVal] = useState("");

    useEffect(() => {
      if (defaultVal) setVal(defaultVal);
    }, [defaultVal]);

    const { placePredictions, getPlacePredictions } = usePlacesService({
      apiKey: GMAP_API_KEY,
    });

    useEffect(() => {
      if (showSuggestion && !placePredictions.length) {
        setLocalError(invalidMessage ?? t("INVALID_LOCATION_LABEL"));
      } else {
        setLocalError(null);
      }
    }, [placePredictions, showSuggestion, t, invalidMessage]);

    const debounced = useDebouncedCallback(
      // to memoize debouncedFunction we use useCallback hook.
      // In this case all linters work correctly
      useCallback((value) => {
        handleChange(value);
      }, []),
      700,
      // The maximum time func is allowed to be delayed before it's invoked:
      { maxWait: 1000 }
    );

    const hideSuggestions = () => {
      setShowSuggestion(false);
      onPlaceSelect("");
      setVal("");
    };

    const handleOnChange = (value) => {
      if (value.length === 0 || value === undefined) {
        setVal("");
        setShowSuggestion(false);
        handleChange && debounced("");
      } else {
        getPlacePredictions({
          componentRestrictions: { country: restrictToIndia ? "in" : "" },
          input: value,
        });
        setVal(value);
        setShowSuggestion(true);
        handleChange && debounced(value);
      }
    };

    const extraInputProps = rest.extraInputProps;
    return (
      <LocationSearchWrapper>
        <Input
          {...rest}
          ref={ref}
          value={val}
          type="location"
          placeholder={placeholder ?? t("SEARCH_LOCATION")}
          extraInputProps={{
            inputProps: { autoComplete: "off" },
            ...extraInputProps,
          }}
          onChange={(evt) => handleOnChange(evt.target.value)}
          helperText={localError ?? (!val ? rest.helperText : "")}
          endAdornment={showIcon ? <LocationOn /> : <></>}
        />

        {showSuggestion && placePredictions?.length > 0 ? (
          <ClickAwayListener onClickAway={hideSuggestions}>
            <StyledPaper>
              <List dense>
                {placePredictions.map((place) => {
                  return (
                    <StyledListItem
                      key={place.place_id}
                      id={place}
                      value={place.description}
                      onClick={() => {
                        setVal(place.description);
                        onPlaceSelect(place);
                        setShowSuggestion(false);
                      }}
                    >
                      <ListItemText primary={place.description} />
                    </StyledListItem>
                  );
                })}
              </List>
            </StyledPaper>
          </ClickAwayListener>
        ) : null}
      </LocationSearchWrapper>
    );
  }
);

export default LocationSearch;
