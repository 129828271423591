import i18n from "i18next";
import _ from "lodash";
import * as Yup from "yup";

import { checkEmailRegex, checkPhoneRegex } from "../../utils";

export const AddOrganizationValidation = () => {
  const t = i18n.t;

  return Yup.object().shape({
    organization_name: Yup.string().required(
      t("ERROR_ORGANIZATION_NAME_REQUIRED")
    ),
    first_name: Yup.string().required(t("ERROR_FIRST_NAME_REQUIRED")),
    last_name: Yup.string().required(t("ERROR_LAST_NAME_REQUIRED")),
    work_email: Yup.string()
      .required(t("ERROR_EMAIL_REQUIRED"))
      .matches(checkEmailRegex(), t("ERROR_INVALID_EMAIL")),
    phone_number: Yup.string()
      .required(t("ERROR_MOBILE_NUMBER_REQUIRED"))
      .matches(checkPhoneRegex(), t("ERROR_INVALID_MOBILE_NUMBER")),
    user_roles: Yup.string().required(t("ERROR_FIELD_REQUIRED")),
  });
};

export const BillingContactValidation = (adminAddressChecked) => {
  const t = i18n.t;
  return Yup.object().shape({
    adminAddressChecked: Yup.string().notRequired().nullable(),
    first_name: Yup.string().when("adminAddressChecked", {
      is: () => !adminAddressChecked,
      then: Yup.string().required(t("ERROR_FIRST_NAME_REQUIRED")),
    }),
    last_name: Yup.string().when("adminAddressChecked", {
      is: () => !adminAddressChecked,
      then: Yup.string().required(t("ERROR_LAST_NAME_REQUIRED")),
    }),
    work_email: Yup.string().when("adminAddressChecked", {
      is: () => !adminAddressChecked,
      then: Yup.string()
        .required(t("ERROR_EMAIL_REQUIRED"))
        .matches(checkEmailRegex(), t("ERROR_INVALID_EMAIL")),
    }),
    phone_number: Yup.string().when("adminAddressChecked", {
      is: () => !adminAddressChecked,
      then: Yup.string()
        .required(t("ERROR_MOBILE_NUMBER_REQUIRED"))
        .matches(checkPhoneRegex(), t("ERROR_INVALID_MOBILE_NUMBER")),
    }),
    billing_address: Yup.string().required(t("ERROR_BILLING_ADDRESS_REQUIRED")),
  });
};

export const headCells = [
  { id: 1, name: "organization", title: "Organization" },
  { id: 2, name: "users", title: "Users" },
  { id: 3, name: "active_devices", title: "Active Devices" },
  { id: 4, name: "well_sites", title: "Wellsites" },
  { id: 5, name: "tenant_uid", title: "Tenant UID" },
];

export const formatRowData = ({
  organizationName,
  usersCount,
  activeDevicesCount,
  wellsitesCount,
  tenantUID,
  tenantId,
}) => {
  return {
    organization: organizationName,
    users: usersCount,
    active_devices: activeDevicesCount,
    well_sites: wellsitesCount,
    tenant_uid: tenantId.toUpperCase(),
    tenant_id: tenantId,
  };
};

export const getDataArray = (data) => {
  return data?.rows;
};

export const formatUserRole = (data) => {
  return data?.roles;
};

export const cleanObject = (object) => {
  return _.omitBy(object, _.isNil);
};

export const cleanArray = (array) => {
  return _.compact(array);
};
