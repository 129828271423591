import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../Input";
import { StyledContainer, StyledInputIcon } from "./styles";

export default function SearchBar({ ...rest }) {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Input
        className={"searchInput"}
        placeholder={t("SEARCH")}
        startAdornment={<StyledInputIcon />}
        {...rest}
      />
    </StyledContainer>
  );
}
