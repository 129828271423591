import { Chip, FormHelperText, OutlinedInput } from "@mui/material";
import { styled as muistyled } from "@mui/styles";
import styled from "styled-components";

export const StyledOutlinedInput = muistyled(OutlinedInput)(() => ({
  borderRadius: "10px",

  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: " var(--color-gray-gray-6) !important",
  },
}));

export const StyledFormHelperText = muistyled(FormHelperText)(() => ({
  marginLeft: "14px",
  "&.Mui-error": {
    color: "#f24949",
  },
}));

export const StyledSpan = styled.div`
  margin-top: -12px;
  font-weight: 500;
  margin-bottom: 14px;
  &.required:after {
    content: " *";
    color: red;
  }
`;

export const StyledLabel = styled.label`
  margin-top: -12px;
  font-weight: 500;
  margin-bottom: 14px;
  &.errorTextColor {
    color: var(--color-revival-red-red-1) !important;
  }
  &.required:after {
    content: " *";
    color: red;
  }
`;

export const OutlinedInputContainer = muistyled("div")(() => ({
  marginTop: "32px",
}));

export const StyledChip = muistyled(Chip)(() => ({
  color: "var(--color-revival-orange-orange-5)",
  backgroundColor: "#ffefe8",
  border: "2px solid var(--color-revival-orange-orange-5)",
}));
