import axios from "axios";
import React, { useEffect } from "react";
import GifLoader from "react-gif-loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ICON_LOADING } from "../../assets/images";
import { isLoggedIn } from "../../utils";
import { ROUTES } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { logger } from "../../utils/logger";
import { triggerValidateLinkAPI } from "../../utils/restAPI/AuthAPI";

const log = logger("Activate-User");
const ActivateUserContainer = ({ params, ...rest }) => {
  const { triggerValidateLinkData } = useActiveUserContext();

  const queryParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  useEffect(() => {
    const link = encodeURIComponent(queryParams.get("link"));

    const data = {
      link,
      cb: reDirecToDashboard,
    };
    link && triggerValidateLinkData(data);
  }, []);

  const reDirecToDashboard = () => {
    if (isLoggedIn()) history.push(ROUTES.RESET_PASSWORD);
    else history.push(ROUTES.RESEND_LINK);
  };
  return (
    <GifLoader
      loading={true}
      imageSrc={ICON_LOADING}
      // imageStyle={imageStyle}
      overlayBackground="rgba(255,255,255)"
    />
  );
};

export default ActivateUserContainer;
