import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import { customToast } from "../../utils/common";
import { TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { restoreDefaultsAlertsAPI, saveChangesConfigAPI, toggleAlertAPI, triggerAlertListAPI } from "../../utils/restAPI/AlertAPI";
import AlertManagementView from "../../views/AlertManagementView";

const log = logger("AlertManagement");
export default function AlertManagementContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const [fetchedData, setFetchedData] = useState({});

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    setIsLoading(true);
    const { promise: alertListPromise } = triggerAlertListAPI();

    const [alertListRes] = await Promise.all([alertListPromise]);
    await setFetchedData({
      alertList: alertListRes.data,
    });
    setIsLoading(false);
  };

  const saveChangesData = ({ params, cb }) => {
    const { promise, cancel } = saveChangesConfigAPI(params);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_SAVE_CHANGES`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        cb && cb();
        log.error(e);
      });

    return cancel;
  };

  const toggleAlertData = ({ params, cb }) => {
    const { promise, cancel } = toggleAlertAPI(params);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );

        getFormData();
      })
      .catch((e) => {
        cb && cb();
        log.error(e);
      });

    return cancel;
  };

  const restoreDefaultsData = ({ params, cb }) => {
    const { tenantId, id } = params;
    const { promise, cancel } = restoreDefaultsAlertsAPI(tenantId, id);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_DEFAULT_VALUES`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
        getFormData();
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  return !isLoading ? (
    <AlertManagementView
      fetchedData={fetchedData}
      restoreDefaultsData={restoreDefaultsData}
      saveChangesData={saveChangesData}
      toggleAlertData={toggleAlertData}
    />
  ) : (
    <Loader />
  );
}
