import { Card } from "@mui/material";
import styled from "styled-components";

import { ICON_DELETE_GRAY_COMPONENT } from "../../assets/images";

export const ContainerWrapper = styled.div`
  padding: 22px;
  width: 100%;
  height: 100vh;
  background-color: var(--color-gray-gray-11);
`;

export const PageHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;

  /* Black */

  color: #000000;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.02em;
`;

export const CardContainer = styled(Card)(({ theme }) => ({
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "16px",
}));

export const BlankPageContainer = styled.div`
  padding: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeading1 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #000000;
`;

export const StyledHeading2 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #808191;
  margin-bottom: 40px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px;
`;

export const ListWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
`;
export const ScrollDiv = styled.div`
  overflow: scroll;
  height: 61vh;
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d8dbe3;
  padding-bottom: 14px;
`;

export const FormHeading = styled.span`
  border-bottom: 1px solid #f9f9f9;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150.26%;
  /* identical to box height, or 30px */

  color: #11142d;
`;

export const StyledSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
  color: #ff5c1e;
  cursor: pointer;
`;

export const FormFooterContainer = styled.div`
  border-top: 1px solid #d8dbe3;
  display: flex;
  margin-top: 32px;
  padding-top: 28px;
  gap: 10px;
  &.direction-row-reverse {
    flex-direction: row-reverse;
  }
`;

export const StyledIcon = styled.img`
  height: 15px;
  margin-right: 10px;
`;

export const StyledDeleteIcon = styled(ICON_DELETE_GRAY_COMPONENT)`
  margin: 0px;
  width: 25px;
`;

export const StyledImg = styled.img`
  cursor: pointer;
`;
