import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  CHART_LINE_COLORS,
  CHARTS_CONTANTS,
  getTime,
  UNITS,
  xAxisFormatter,
  yAxisFormatter,
} from "./helper";
import { StyledText } from "./styles";

export const DualAxesCharts = ({ chartData }) => {
  const { data, title, alarm_raised_time } = chartData;

  const fixedData = [...data, { time: 24 }];

  return (
    <>
      <StyledText>{title}</StyledText>

      <ResponsiveContainer width="90%" height={400}>
        <LineChart
          width={CHARTS_CONTANTS.CHART_WIDTH}
          height={CHARTS_CONTANTS.CHART_HEIGHT}
          data={fixedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            type="number"
            tickFormatter={xAxisFormatter}
            scale="time"
            label={{ value: "Local Time", position: "insideBottom", dy: 5 }}
          />
          <YAxis
            yAxisId="motorVibrationIntensity"
            orientation="left"
            tickFormatter={(value) =>
              yAxisFormatter(value, UNITS.MOTOR_VIBRATION)
            }
          />

          <YAxis
            yAxisId="motorCurrent"
            orientation="right"
            tickFormatter={(value) => yAxisFormatter(value, UNITS.CURRENT_DRAW)}
          />
          <Tooltip />
          <ReferenceLine
            x={alarm_raised_time}
            yAxisId="motorVibrationIntensity"
            stroke={CHARTS_CONTANTS.ALARM_STROKE}
            label={getTime(alarm_raised_time)}
          />
          <Line
            yAxisId="motorVibrationIntensity"
            type="monotone"
            dataKey="motorVibrationIntensity"
            activeDot={{ r: CHARTS_CONTANTS.ACTIVE_DOT_RADIUS }}
            dot={{ r: CHARTS_CONTANTS.DOT_RADIUS }}
            stroke={CHART_LINE_COLORS[0]}
          />
          <Line
            yAxisId="motorCurrent"
            type="monotone"
            dataKey="motorCurrent"
            stroke={CHART_LINE_COLORS[1]}
            activeDot={{ r: 6 }}
            dot={{ r: 6 }}
          />
          {/* <Brush /> */}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
