import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { ICON_BACK, ICON_SAVE_COMPONENT } from "../../assets/images";
import { Button, Input } from "../../components";
import { ChipInput } from "../../components/ChipInput";
import { ConfirmDeleteDialog } from "../../components/Dialog";
import Loader from "../../components/Loader";
import { DATA_KEY, MAX_LENGTH, ROUTES } from "../../utils/constants";
import { EditGroupValidation, formatAccountAdminsNames, formatOperatorsNames, formatWellSitesList, getAccountAdminID, getDerivedGroupData, getOperatorID, getWellSiteID } from "./helper";
import { FormFooterContainer, FormWrapper, StyledDeleteIcon } from "./styles";
import { CardContainer, ContainerWrapper, PageHeading, StyledIcon, StyledSpan, TopContainer } from "./styles";

export default function EditView({
  selectedGroupData,
  fetchedData,
  saveGroupDetailsData,
  deleteGroupData,
  loading,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);

  const defaultValues = {
    ...getDerivedGroupData(selectedGroupData),
  };

  const { groupName, groupId } = selectedGroupData?.groupsDetail;

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(EditGroupValidation()),
  });

  const onSubmit = (formValues) => {
    setIsLoading(true);
    saveGroupDetailsData({
      params: {
        groupId: formValues?.group_id,
        groupName: formValues?.group_name,
        userIds:
          [
            ...getAccountAdminID(
              fetchedData?.accountAdminsList,
              formValues?.account_admin_names
            ),
            ...getOperatorID(fetchedData?.operatorsList, formValues?.operators),
          ] || [],
        wellsiteId:
          getWellSiteID(
            fetchedData?.assignedWellSitesList,
            formValues?.assigned_well_site
          ) || null,
      },
      cb: handleBack,
    });
  };

  const handleBack = () => {
    setIsLoading(false);
    reset();
    history.push(ROUTES.GROUP_MANAGEMENT);
  };

  const handleDelete = () => {
    deleteGroupData({
      params: { id: groupId },
      cb: handleBack,
    });
  };

  return !loading ? (
    <>
      <ConfirmDeleteDialog
        open={showConfirmDeletePopUp}
        setOpen={setShowConfirmDeletePopUp}
        handleDelete={handleDelete}
      />
      <ContainerWrapper>
        <div style={{ display: "flex" }}>
          <StyledSpan onClick={handleBack}>
            <StyledIcon src={ICON_BACK} alt="back icon" loading="eager" />
            {"Back"}
          </StyledSpan>
        </div>
        <TopContainer>
          <PageHeading>{groupName}</PageHeading>
        </TopContainer>
        <CardContainer elevation={0}>
          <FormWrapper className={"fullwidth-container"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                required
                id="edit-group-group-name-input"
                label={t("GROUP_NAME")}
                placeholder={t("GROUP_NAME_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.TEXT,
                  },
                }}
                {...register("group_name")}
                helperText={errors.group_name?.message}
              />

              <ChipInput
                required
                id="edit-group-account-admin-names-input"
                data={formatAccountAdminsNames(fetchedData.accountAdminsList)}
                dataKey={DATA_KEY.ACCOUNT_ADMINS}
                name="account_admin_names"
                defaultValue={true}
                multiple
                label={t("ACCOUNT_ADMINS")}
                placeholder={t("ACCOUNT_ADMINS_PLACEHOLDER")}
                control={control}
                setValue={setValue}
              />
              <ChipInput
                required
                id="edit-group-operators-input"
                data={formatOperatorsNames(fetchedData.operatorsList)}
                dataKey={DATA_KEY.OPERATORS}
                name="operators"
                defaultValue={true}
                multiple
                label={t("OPERATORS")}
                placeholder={t("OPERATORS_PLACEHOLDER")}
                control={control}
                setValue={setValue}
              />

              <ChipInput
                id="edit-group-create-group-input"
                data={formatWellSitesList(fetchedData.assignedWellSitesList)}
                dataKey={DATA_KEY.WELL_SITES}
                name="assigned_well_site"
                defaultValue={true}
                multiple
                label={t("ASSIGNED_WELL_SITE")}
                placeholder={t("ASSIGNED_WELL_SITE_PLACEHOLDER")}
                control={control}
                setValue={setValue}
              />

              <FormFooterContainer>
                <Button
                  className={"form-button outlined-button gray-border"}
                  title={t("DELETE")}
                  onClick={() => setShowConfirmDeletePopUp(true)}
                  endIcon={<StyledDeleteIcon />}
                  disabled={isLoading}
                />
                <Button
                  className={"form-button"}
                  title={t("SAVE_CHANGES")}
                  endIcon={<ICON_SAVE_COMPONENT />}
                  type="submit"
                  disabled={isLoading}
                />
              </FormFooterContainer>
            </form>
          </FormWrapper>
        </CardContainer>
      </ContainerWrapper>
    </>
  ) : (
    <Loader />
  );
}
