import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { customToast, getUserData, setUserData } from "../../utils/common";
import { TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { ROUTES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import {
  triggerAssignedGroupsAPI,
  triggerAssignedWellSitesAPI,
  triggerGetUserRolesAPI,
} from "../../utils/restAPI/AdminAPI";
import {
  deleteUserDetailsAPI,
  getUserDetailsAPI,
  getUserListAPI,
  saveUserDetailsAPI,
} from "../../utils/restAPI/UserManagementAPI";
import UserManagementView from "../../views/UserManagementView";
import EditUserView from "../../views/UserManagementView/EditView";

const log = logger("UserManagement");
export default function UserManagementContainer({ params, ...rest }) {
  const history = useHistory();
  const [isEmpty, setIsEmpty] = useState(true);
  const [fetchedData, setFetchedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const user = getUserData();
    if (user) {
      setSelectedUserData(user);
    }
    setIsEmpty(false);
    getFormData();
  }, []);

  const getUserDetails = ({ id, cb }) => {
    const { promise, cancel } = getUserDetailsAPI(id);
    promise
      .then((res) => {
        setSelectedUserData(res?.data);
        setUserData(res?.data);

        cb && cb(res.message);
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const getFormData = async () => {
    const { promise: userRolesPromise } = triggerGetUserRolesAPI();
    const { promise: assignedGroupsPromise } = triggerAssignedGroupsAPI();
    const { promise: assignedWellSitesPromise } = triggerAssignedWellSitesAPI();

    const [userRolesRes, assignedGroupsRes, assignedWellSitesRes] =
      await Promise.all([
        userRolesPromise,
        assignedGroupsPromise,
        assignedWellSitesPromise,
      ]);
    await setFetchedData({
      userRolesList: userRolesRes.data,
      assignedGroupsList: assignedGroupsRes.data,
      assignedWellSitesList: assignedWellSitesRes.data,
    });

    setIsLoading(false);
  };

  const saveUserDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveUserDetailsAPI(params);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const deleteUserData = ({ params, cb }) => {
    const { promise, cancel } = deleteUserDetailsAPI(params?.id);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_USER_DELETION`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const handleRowClick = (id) => {
    getUserDetails({ id, cb: redirectToEditUser });
  };
  const redirectToEditUser = (id) => {
    history.push(ROUTES.EDIT_USER);
  };

  return params?.isEdit && selectedUserData && !isLoading ? (
    <EditUserView
      selectedUserData={selectedUserData}
      fetchedData={fetchedData}
      saveUserDetailsData={saveUserDetailsData}
      deleteUserData={deleteUserData}
      loading={isLoading}
    />
  ) : (
    <UserManagementView
      fetchedData={fetchedData}
      saveUserDetailsData={saveUserDetailsData}
      isEmpty={isEmpty}
      onRowClick={handleRowClick}
      api={getUserListAPI}
    />
  );
}
