import { Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { AlertCard, Button, StrechedCard } from "../../components";
import TableLayout from "../../components/TableLayout";
import { ALERT_TYPES, ROUTES, TILE_TYPES } from "../../utils/constants";
import { getWellSiteListAPI } from "../../utils/restAPI/WellSiteManagementAPI";
import { formatRowData, getDataArray, headCells } from "../WellSiteManagementView/helper";
import { AlertPanel, HeadingsWrapper, StyledHeading1, StyledHeading2, StyledPageHeading, StyledPageSubHeading } from "./styles";
import { CardContainer, ContainerWrapper, PanelContainer } from "./styles";

export default function DashboardView({ dashboardData }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleAddWellSite = () => {
    history.push(ROUTES.WELL_SITE_MANAGEMENT);
  };

  const getAlertPanel = () => {
    return (
      <PanelContainer>
        <AlertPanel>
          <AlertCard
            type={ALERT_TYPES.ALARMS}
            number={dashboardData?.noOfAlarms || 0}
            title={t("ALARMS")}
          ></AlertCard>
          <AlertCard
            type={ALERT_TYPES.WARNINGS}
            number={dashboardData?.noOfWarnings || 0}
            title={t("WARNINGS")}
          ></AlertCard>
        </AlertPanel>
        <Divider />
        <AlertPanel>
          <StrechedCard
            type={TILE_TYPES.OPERATIONAL_WELL_SITES}
            number={dashboardData?.noOfWellsites || 0}
            title={t("OPERATIONAL_WELL_SITES")}
          ></StrechedCard>
          <StrechedCard
            type={TILE_TYPES.ACKNOWLEDGED_ALERTS}
            number={dashboardData?.noOfAcknowledgedAlerts || 0}
            title={t("ACKNOWLEDGED_ALERTS")}
          ></StrechedCard>
        </AlertPanel>
      </PanelContainer>
    );
  };

  return (
    <>
      {/* <PopUpForm
        open={open}
        setOpen={setOpen}
        type={POPUP_TYPES.ADD_A_WELL_SITE}
      /> */}

      <ContainerWrapper>
        <StyledPageHeading>
          {dashboardData?.companyName || ""}
        </StyledPageHeading>
        <StyledPageSubHeading>{t("DASHBOARD")}</StyledPageSubHeading>
        <CardContainer elevation={0}>
          <HeadingsWrapper>
            <StyledHeading1>
              {t("WELCOME_TO_THE_REVIVAL_WEB_PORTAL")}
            </StyledHeading1>
            <StyledHeading2>
              {t("TO_GET_STARTED_PLEASE_ADD_A_WELL_SITE")}
            </StyledHeading2>
          </HeadingsWrapper>
          <Button
            className={"header-button lg-padding"}
            title={t("ADD_A_WELL_SITE")}
            onClick={handleAddWellSite}
          />
        </CardContainer>

        <TableLayout
          searchEnabled={true}
          opacity={0}
          api={getWellSiteListAPI}
          headCells={headCells}
          defaultView={getAlertPanel()}
          formatRowData={formatRowData}
          getDataArray={getDataArray}
          relative
        />
      </ContainerWrapper>
    </>
  );
}
