import { Box } from "@mui/material";
import styled from "styled-components";

import { LAYOUT_CONSTANTS } from "../../assets/css/theme";
import { HALF_PAGE_LAYOUT_IMG } from "../../assets/images";

export const LayoutImageContainer = styled(Box)`
  background: url(${HALF_PAGE_LAYOUT_IMG}) no-repeat center center;
  width: calc(100% - 720px);
  background-size: cover;
  height: 100%;
`;

export const HalfPageLayoutContainer = styled(Box)`
  transition: ${LAYOUT_CONSTANTS.TRANSITION};
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
`;

export const HalfPageHeader = styled.header`
  user-select: none;
  margin-top: 1rem;
`;

export const ComponentContainer = styled(Box)`
  background-color: #fff;
  padding: 32px;
`;

export const FormContainer = styled(Box)`
  justify-content: space-between;
  background-color: #fff;
  flex-direction: column;
  padding: 32px 58px;
  @media (max-width: 600px) {
    padding: 32px 42px;
  }
  @media (max-width: 400px) {
    padding: 16px;
  }
  display: flex;
  height: 100%;
  width: 676px;
`;

export const HalfPageFooter = styled.div`
  position: absolute;
  bottom: 26px;
  width: 676px;
`;

export const HeadingBox = styled(Box)`
  margin-bottom: 8px;
  line-height: 38px;
  font-weight: 600;
  font-size: 32px;
  color: #383838;
`;

export const HalfPageContent = styled.section`
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 100%;
`;
