import React from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { CHART_LINE_COLORS, CHARTS_CONTANTS, xAxisFormatter, yAxisFormatter } from "./helper";
import { StyledText } from "./styles";

export const SingleAxisCharts = ({ chartData, unit = "" }) => {
  const { lines, data, title } = chartData;
  const fixedData = [...data, { time: 24 }];

  return (
    <>
      <StyledText>{title}</StyledText>
      <ResponsiveContainer width="90%" height={400}>
        <LineChart
          width={CHARTS_CONTANTS.CHART_WIDTH}
          height={CHARTS_CONTANTS.CHART_HEIGHT}
          data={fixedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            name="Local Time"
            type="number"
            tickFormatter={xAxisFormatter}
            label={{ value: "Local Time", position: "insideBottom", dy: 5 }}
          />
          <YAxis tickFormatter={(value) => yAxisFormatter(value, unit)} />
          <Tooltip />

          {lines.map((line, index) => {
            return (
              <Line
                key={index}
                type="monotone"
                dataKey={line}
                stroke={CHART_LINE_COLORS[index]}
                activeDot={{ r: CHARTS_CONTANTS.ACTIVE_DOT_RADIUS }}
                dot={{ r: CHARTS_CONTANTS.DOT_RADIUS }}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
