import Dialog from "@mui/material/Dialog";
import { styled as mStyled } from "@mui/material/styles";
import styled from "styled-components";

export const StyledDialog = mStyled(Dialog)(({ theme }) => ({
  ".MuiPaper-root": {
    padding: "32px",
    borderRadius: "20px",
    maxWidth: "484px",
  },
}));

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeading1 = styled.h2`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const StyledPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 36px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  border-top: 1px solid #d8dbe3;
  padding-top: 36px;
`;
