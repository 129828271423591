import React, { useEffect, useState } from "react";

import { customToast } from "../../utils/common";
import { TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { getDeviceListAPI, getUnassignedDeviceListAPI, returnDeviceDetailsAPI, saveDeviceDetailsAPI } from "../../utils/restAPI/DeviceManagementAPI";
import DeviceManagementView from "../../views/DeviceManagementView";

const log = logger("DeviceManagement");
export default function DeviceManagementContainer() {
  const [isEmpty, setIsEmpty] = useState(true);
  const [fetchedData, setFetchedData] = useState({});

  useEffect(() => {
    setIsEmpty(false);
    getFormData();
  }, []);

  const getFormData = async () => {
    const { promise: deviceListPromise } = getUnassignedDeviceListAPI();
    const { promise: assignedDeviceListPromise } = getDeviceListAPI();

    const [deviceListRes, assignedDeviceListRes] = await Promise.all([
      deviceListPromise,
      assignedDeviceListPromise,
    ]);

    await setFetchedData({
      deviceList: deviceListRes.data,
      assignedDeviceList: assignedDeviceListRes.data,
    });
  };

  const saveDeviceDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveDeviceDetailsAPI(params);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });
    return cancel;
  };

  const returnDeviceDetailsData = ({ params, cb }) => {
    const { promise, cancel } = returnDeviceDetailsAPI(params?.id);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });
    return cancel;
  };

  return (
    <DeviceManagementView
      fetchedData={fetchedData}
      saveDeviceDetailsData={saveDeviceDetailsData}
      returnDeviceDetailsData={returnDeviceDetailsData}
      isEmpty={isEmpty}
      api={getDeviceListAPI}
    />
  );
}
