import { Drawer, MenuItem, MenuList, styled as muiStyled } from "@mui/material";
import styled from "styled-components";

export const StyledImage = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

export const SideNavDrawer = muiStyled(Drawer)(({ theme, open }) => ({
  transform: `translateY(0px) !important`,
  "&.MuiDrawer-docked .MuiDrawer-paper": {
    transform: "translateY(10px)",
    padding: "16px",
    boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "-9px",
  },
}));

export const StyledMenuList = styled(MenuList)`
  height: 100vh;
  margin-bottom: 70px;
  li:nth-last-child(1) {
    bottom: 100px;
    position: fixed;
    width: -webkit-fill-available;
  }
`;
export const StyledMenuItem = muiStyled(MenuItem)(({ menu, theme }) => ({
  gap: "18px",
  height: "50px",
  borderRadius: "8px",
  "&.Mui-selected": {
    backgroundColor: "#FFEFE8",
    color: "#F05423",
  },

  "&.Mui-selected:hover": { backgroundPosition: "99.9% center" },

  "&:hover .MuiListItemIcon-root, &.Mui-selected .MuiListItemIcon-root": {},
}));
