import {
  ICON_ALERT_MANAGEMENT,
  ICON_DASHBOARD,
  ICON_DEVICE_MANAGEMENT,
  ICON_LOGOUT,
  ICON_ORGANIZATION_MANAGEMENT,
  ICON_USER_MANAGEMENT,
  ICON_WELL_SITE_MANAGEMENT,
} from "../../assets/images";
import { logout } from "../common/localStorageUtils";

export const ROUTES = {
  HOME: "/",
  ERROR_FILE_NOT_FOUND: "/404",
  ACTIVATE_USER: "/activate-user/link",
  CHARTS: "/charts/:id",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESEND_LINK: "/resend-link",
  PASSWORD_RESET_SUCCESSFULLY: "/reset-successfully",
  DASHBOARD: "/dashboard",
  WELL_SITE_MANAGEMENT: "/well-site-management",
  EDIT_WELL_SITE: "/edit-well-site",
  USER_MANAGEMENT: "/user-management",
  EDIT_USER: "/edit-user",
  ORGANIZATION_MANAGEMENT: "/organization-management",
  DEVICE_MANAGEMENT: "/device-management",
  GROUP_MANAGEMENT: "/group-management",
  EDIT_GROUP: "/edit-group",
  ALERT_MANAGEMENT: "/alert-management",
  SETTINGS: "/settings",
};

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN";

export const POPUP_TYPES = {
  CREATE_A_GROUP: "create_a_group",
  ADD_A_WELL_SITE: "add_a_well_site",
};

export const ACCOUNT_TYPES = {
  SYSTEM_ADMIN: "System Admin",
  ACCOUNT_ADMIN: "Account Admin",
};
export const ALERT_TYPES = {
  ALARMS: "Alarms",
  WARNINGS: "Warnings",
};

export const ALERT_TYPES_ID = {
  ALARMS: 1,
  WARNINGS: 2,
};
export const NOTIFICATION_CATEGORY = {
  SEND_NOTIFICATION_EVERY: "sendNotificationEvery",
  NOTIFY_SUPERVISOR_ON: "notifySupervisorOn",
};

export const TILE_TYPES = {
  OPERATIONAL_WELL_SITES: "OPERATIONAL_WELL_SITES",
  ACKNOWLEDGED_ALERTS: "ACKNOWLEDGED_ALERTS",
};

export const DRAWER_WIDTH = 300;

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  DEFAULT: "default",
};

export const TOAST_DURATION = {
  VERY_SHORT: 1000,
  SHORT: 2000,
  MEDIUM: 3000,
  LONG: 4000,
  VERY_LONG: 5000,
};

export const SIDE_MENU_NAV = [
  {
    name: "Dashboard",
    iconName: ICON_DASHBOARD,
    path: ROUTES.DASHBOARD,
    systemAdminOnly: false,
  },
  {
    name: "Organization Management",
    iconName: ICON_ORGANIZATION_MANAGEMENT,
    path: ROUTES.ORGANIZATION_MANAGEMENT,
    systemAdminOnly: true,
  },
  {
    name: "User Management",
    iconName: ICON_USER_MANAGEMENT,
    systemAdminOnly: false,
    path: ROUTES.USER_MANAGEMENT,
  },
  {
    name: "Device Management",
    iconName: ICON_DEVICE_MANAGEMENT,
    systemAdminOnly: false,
    path: ROUTES.DEVICE_MANAGEMENT,
  },
  {
    name: "Wellsite Management",
    iconName: ICON_WELL_SITE_MANAGEMENT,
    systemAdminOnly: false,
    path: ROUTES.WELL_SITE_MANAGEMENT,
  },
  {
    name: "Group Management",
    iconName: ICON_DEVICE_MANAGEMENT,
    systemAdminOnly: false,
    path: ROUTES.GROUP_MANAGEMENT,
  },
  {
    name: "Alert Management",
    iconName: ICON_ALERT_MANAGEMENT,
    systemAdminOnly: false,
    path: ROUTES.ALERT_MANAGEMENT,
  },
  {
    name: "Logout",
    iconName: ICON_LOGOUT,
    path: ROUTES.LOGIN,
    systemAdminOnly: false,
    action: () => logout(),
  },
];

export const MAX_LENGTH = {
  EMAIL: 50,
  TEXT: 30,
  LONG_TEXT: 80,
  PASSWORD: 80,
  PHONE: 10,
  AMOUNT: 50,
  PERCENTAGE: 3,
  LATLNG: 20,
  DATE: 2,
};

export const DATA_KEY = {
  ORGANIZATION_NAME: "organizationName",
  USER_ROLES: "roleName",
  GROUP_NAME: "groupName",
  DEVICE_NAME: "deviceName",
  WELL_SITES: "wellsiteName",
  ACCOUNT_ADMINS: "userName",
  OPERATORS: "userName",
  USER: "name",
  NAME: "name",
};

export const BADGE_TYPES = {
  ACTIVE: "Active",
  ASSIGNED: "Assigned",
  DEPLOYED: "Deployed",
  FACTORY_DEFAULT: "Factory Default",
  RETURNED: "Returned",
};

export const NOTIFICATION_INTERVALS = [
  "2 Hours",
  "4 Hours",
  "6 Hours",
  "8 Hours",
];

export const SUPERVISOR_NOTIFICATION = [
  "2nd Notification",
  "3rd Notification",
  "4th Notification",
];
