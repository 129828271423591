// import { useEffect, useState } from "@mui/material";
import React from "react";

import { AppHeader, SideNav } from "..";
import { ContainerWrapper, MainContainer, MainWrapper } from "./styles";

const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <ContainerWrapper>
      <MainWrapper>
        <SideNav isSideDrawerOpen={true} toggleSideDrawer={true} />
        <MainContainer>
          <AppHeader />
          <Component {...rest} />
        </MainContainer>
      </MainWrapper>
    </ContainerWrapper>
  );
};

export default MainLayout;
