import { pick } from "lodash";
import moment from "moment";
import momentTimeZone from "moment-timezone";

export const CHARTS_CONTANTS = {
  CHART_WIDTH: 500,
  CHART_HEIGHT: 200,
  ACTIVE_DOT_RADIUS: 6,
  DOT_RADIUS: 7,
  ALARM_STROKE: "red",
};

export const CHART_LINE_COLORS = [
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
  "#FF5C1E",
  "#675C68",
  "#159A22",
  "#C96C00",
  "#39A7D7",
];

export const UNITS = {
  CURRENT_DRAW: "A",
  MOTOR_VIBRATION: "g",
  TEMPRATURE: "C",
  BATTERY_VOLTAGE: "V",
};

export const CHART_XAXIS_LABELS = [
  "12AM",
  "2AM",
  "4AM",
  "6AM",
  "8AM",
  "10AM",
  "12PM",
  "2PM",
  "4PM",
  "6PM",
  "8PM",
  "10PM",
  "12AM",
];

const getLocalTimeZoneOfClientSystem = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const convertTimeNumberToTimeString = (value) => {
  return value > 23 || value < 1
    ? "12:00 AM"
    : value.toFixed(2) < 12
    ? value.toFixed(2).replace(".", ":") + " AM"
    : value >= 12 && value < 13
    ? value.toFixed(2).replace(".", ":") + " PM"
    : (value % 12).toFixed(2).replace(".", ":") + " PM";
};

export const xAxisFormatter = (value) => {
  const timeZone = getLocalTimeZoneOfClientSystem();

  const tempTimeString = convertTimeNumberToTimeString(value);

  const convertedTempTimeStringToMomentWithTodaysDate = moment(
    moment(tempTimeString, "hh:mm A").utc()._d
  ).format("YYYY-MM-DD hh:mm A");

  const timeStringInCurrentTimezoneMoment = momentTimeZone
    .utc(convertedTempTimeStringToMomentWithTodaysDate)
    .tz(timeZone)
    .format();

  const finalTimeStringToDisplay = moment(
    timeStringInCurrentTimezoneMoment
  ).format("hh:mm A");

  return finalTimeStringToDisplay;
};

export const yAxisFormatter = (value, unit) => {
  return `${value} ${unit}`;
};

export const getTime = (value) => {
  return xAxisFormatter(value);
};

export const extractArray = (array, keys) => {
  let arr = array.map((item) => {
    return pick(item, keys);
  });
  return arr;
};

export const formatTime = (time) => {
  let [h, m] = time.split(":");
  h = parseInt(h);
  m = parseInt(m) / 6;
  m = Math.floor(m);

  return `${h}.${m}`;
};

export const formatChartData = (data1, data2, data3) => {
  return {
    chart1: {
      title: "Current Draw & Motor Vibration",
      lines: ["motorCurrent", "motorVibrationIntensity"],
      alarm_raised_time: 12.22,
      data: [
        {
          motorVibrationIntensity: 10.8,
          time: 2.3,
          motorCurrent: 11.3,
        },
        {
          motorVibrationIntensity: 11.9,
          time: 11.3,
          motorCurrent: 11,
        },
        {
          motorVibrationIntensity: 1.9,
          time: 17.3,
          motorCurrent: 8.9,
        },
      ],
    },
    chart2: {
      title: "Leak & Temprature",
      unit: "UNITS.TEMPRATURE",
      lines: [
        "motorVibrationIntensity",
        "voltageBattery",
        "tempAmbient",
        "leakSensor1",
        "leakSensor2",
        "tempThermistor1",
        "tempThermistor2",
        "motorState",
        "orientationX",
        "orientationY",
        "orientationZ",
        "motorCurrent",
      ],
      data: [
        {
          motorVibrationIntensity: 10.8,
          voltageBattery: 11.8,
          tempAmbient: 21.5,
          leakSensor1: 10,
          leakSensor2: 11.3,
          tempThermistor1: 12.3,
          tempThermistor2: 14.5,
          motorState: 0,
          orientationX: 0,
          orientationY: 0,
          orientationZ: 0,
          motorCurrent: 11.3,
          time: 1.3,
        },
        {
          motorVibrationIntensity: 11.9,
          voltageBattery: 12.8,
          tempAmbient: 22.5,
          leakSensor1: 12,
          leakSensor2: 12.3,
          tempThermistor1: 13.3,
          tempThermistor2: 15.5,
          motorState: 0,
          orientationX: 0,
          orientationY: 0,
          orientationZ: 0,
          motorCurrent: 11,
          time: 4.3,
        },
        {
          motorVibrationIntensity: 1.9,
          voltageBattery: 14.8,
          tempAmbient: 26.5,
          leakSensor1: 15,
          leakSensor2: 13.3,
          tempThermistor1: 16.3,
          tempThermistor2: 14.5,
          motorState: 0,
          orientationX: 0,
          orientationY: 0,
          orientationZ: 0,
          motorCurrent: 8.9,
          time: 14.3,
        },
      ],
    },
    chart3: {
      title: "Battery Voltage",
      lines: ["voltageBattery"],
      unit: "UNITS.BATTERY_VOLTAGE",
      data: [
        {
          voltageBattery: 11.8,
          time: 2,
        },
        {
          voltageBattery: 12.8,
          time: 4,
        },
        {
          voltageBattery: 14.8,
          time: 14,
        },
        {
          voltageBattery: 2,
          time: 24,
        },
      ],
    },
  };
};
