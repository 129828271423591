import i18n from "i18next";
import * as Yup from "yup";

import { DATA_KEY, POPUP_TYPES } from "../../utils/constants";

export const AddWellSiteValidation = (type) => {
  const t = i18n.t;
  return Yup.object().shape({
    type: Yup.string().notRequired().nullable(),
    group_name: Yup.string().when("type", {
      is: (value) => type === POPUP_TYPES.CREATE_A_GROUP,
      then: Yup.string().required(t("ERROR_GROUP_NAME_REQUIRED")),
    }),
    well_site_name: Yup.string().when("type", {
      is: (value) => type === POPUP_TYPES.ADD_A_WELL_SITE,
      then: Yup.string().required(t("ERROR_WELL_SITE_NAME_REQUIRED")),
    }),
    account_admin_names: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    operators: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    assigned_group: Yup.array().of(Yup.string()),
    assigned_well_site: Yup.array().of(Yup.string()),
  });
};

export const EditWellSiteValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    well_site_name: Yup.string().required(t("ERROR_WELL_SITE_NAME_REQUIRED")),
    account_admins: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    operators: Yup.array()
      .of(Yup.string().required(t("ERROR_FIELD_REQUIRED")))
      .min(1, t("ERROR_AT_LEAST_ONE_NAME_MUST_BE_SELECTED")),
    assigned_group: Yup.array().of(Yup.string()),
  });
};

export const formatRowData = ({
  wellsiteId,
  wellsiteName,
  accountAdmins,
  operatorCount,
  groupName,
}) => {
  return {
    id: wellsiteId || 0,
    well_site_name: wellsiteName || "",
    account_admins: accountAdmins || [],
    operators: operatorCount || 0,
    assigned_group: groupName || "",
  };
};

export const headCells = [
  { id: 1, name: "well_site_name", title: "Wellsite Name" },
  { id: 2, name: "account_admins", title: "Account Admins" },
  { id: 3, name: "operators", title: "Operators" },
  { id: 4, name: "assigned_group", title: "Group" },
];

export const getDataArray = (data) => {
  return data?.wellsites?.rows;
};

export const formatGroupNames = (data) => {
  return data?.groups;
};

export const formatAccountAdminsNames = (data) => {
  return data?.user;
};

export const formatOperatorsNames = (data) => {
  return data?.user;
};

export const formatWellSitesList = (data) => {
  return data?.wellsites;
};

export const getGroupID = (groups, selectedGroup) => {
  const data = groups.groups.filter(
    (group) => group[DATA_KEY.GROUP_NAME] === selectedGroup
  );
  return data[0]?.groupId;
};
export const getAccountAdminID = (accountAdmins, selectedAccountAdmins) => {
  const data = accountAdmins?.user?.filter((accountAdmin) =>
    selectedAccountAdmins.includes(accountAdmin[DATA_KEY.ACCOUNT_ADMINS])
  );
  return data.map((item) => item["userId"]);
};

export const getOperatorID = (operators, selectedOperator) => {
  const data = operators?.user?.filter((operator) =>
    selectedOperator.includes(operator[DATA_KEY.OPERATORS])
  );
  return data.map((item) => item["userId"]);
};

export const getReducedArray = (array, key, roleID) => {
  return array
    .filter((item) => item["roleID"] === roleID)
    .map((item) => item[key]);
};
export const getReducedString = (obj, key) => {
  return obj[key];
};

export const getDerivedWellSiteData = ({ wellsite }) => {
  const { wellsiteId, wellsiteName, group, users } = wellsite;

  return {
    wellsiteId,
    well_site_name: wellsiteName,
    account_admin_names: getReducedArray(users, DATA_KEY.USER, 2) || [],
    operators: getReducedArray(users, DATA_KEY.USER, 3) || [],
    assigned_groups: (group && getReducedString(group, DATA_KEY.NAME)) || "",
  };
};
