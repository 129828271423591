import styled from "styled-components";

import { SEARCH_ICON } from "../../assets/images";

export const StyledContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledInputIcon = styled(SEARCH_ICON)``;
