import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { ICON_BACK, ICON_SAVE_COMPONENT } from "../../assets/images";
import { Button, Input } from "../../components";
import { ActivityCard } from "../../components/Cards";
import Charts from "../../components/Charts";
import { ChipInput } from "../../components/ChipInput";
import { Tab, TabPanel, Tabs } from "../../components/CustomTabs";
import { ConfirmDeleteDialog } from "../../components/Dialog";
import { DropDown2 } from "../../components/DropDown2";
import { ACCOUNT_TYPES, DATA_KEY, MAX_LENGTH, ROUTES } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { EditWellSiteValidation, formatAccountAdminsNames, formatGroupNames, formatOperatorsNames, getAccountAdminID, getDerivedWellSiteData, getGroupID, getOperatorID } from "./helper";
import { FormFooterContainer, FormWrapper, ListWrapper, ScrollDiv, StyledDeleteIcon } from "./styles";
import { CardContainer, ContainerWrapper, PageHeading, StyledIcon, StyledSpan, TopContainer } from "./styles";

export default function EditView({
  selectedWellSiteData,
  fetchedData,
  saveWellSiteDetailsData,
  deleteWellSiteData,
  deleteWellSiteActivity,
  loading,
}) {
  const { t } = useTranslation();
  const { wellsiteId, wellsiteName, devices } = selectedWellSiteData?.wellsite;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const { accountDetails } = useActiveUserContext();

  const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);
  const [tabvalue, setTabValue] = useState(0);

  useEffect(() => {
    setIsSystemAdmin(accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN);
    setSelectedDevice(devices[0] || null);
  }, [accountDetails, devices]);

  const handleChange = (_, newTabValue) => {
    setTabValue(newTabValue);
  };

  const defaultValues = {
    ...getDerivedWellSiteData(selectedWellSiteData),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(EditWellSiteValidation()),
  });

  const onSubmit = (formValues) => {
    setIsLoading(true);
    saveWellSiteDetailsData({
      params: {
        wellsiteId: formValues?.wellsiteId,
        wellsiteName: formValues?.well_site_name,
        organizationId: 1,
        groupId:
          getGroupID(
            fetchedData?.assignedGroupsList,
            formValues?.assigned_groups
          ) || null,
        users:
          [
            ...getAccountAdminID(
              fetchedData?.accountAdminsList,
              formValues?.account_admin_names
            ),
            ...getOperatorID(fetchedData?.operatorsList, formValues?.operators),
          ] || [],
      },
      cb: handleBack,
    });
  };

  const handleBack = () => {
    setIsLoading(false);
    reset();
    history.push(ROUTES.WELL_SITE_MANAGEMENT);
  };

  // const handleDeleteAlert = () => {
  //   setShowConfirmDeletePopUp(true);
  // };

  const handleDelete = () => {
    deleteWellSiteData({
      params: { id: wellsiteId },
      cb: handleBack,
    });
  };

  const handleDeleteSiteActivity = (alertId) => {
    deleteWellSiteActivity({
      params: { alertId },
      cb: handleBack,
    });
  };

  const handleChangeDevice = ({ id, value }) => {
    setSelectedDevice({
      deviceId: id,
      customName: value,
    });
  };

  return !loading ? (
    <>
      <ConfirmDeleteDialog
        open={showConfirmDeletePopUp}
        setOpen={setShowConfirmDeletePopUp}
        handleDelete={handleDelete}
      />
      <ContainerWrapper>
        <div style={{ display: "flex" }}>
          <StyledSpan onClick={handleBack}>
            <StyledIcon src={ICON_BACK} alt="back icon" loading="eager" />
            {"Back"}
          </StyledSpan>
        </div>
        <TopContainer>
          <PageHeading>{`${wellsiteName}`}</PageHeading>
        </TopContainer>
        <CardContainer elevation={0}>
          <FormWrapper className={"fullwidth-container"}>
            <Tabs value={tabvalue} onChange={handleChange}>
              <Tab label={t("WELL_SITE_INFO")} index={0} />
              <Tab label={t("SITE_ACTIVITY")} index={1} />
              <Tab label={t("ANALYTICS")} index={2} />
            </Tabs>
            <TabPanel value={tabvalue} index={0}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  required
                  id="edit-well-site-well-site-id-input"
                  label={t("WELL_SITE_NAME")}
                  placeholder={t("WELL_SITE_NAME_PLACEHOLDER")}
                  fullWidth
                  extraInputProps={{
                    inputProps: {
                      autoComplete: "text",
                      maxLength: MAX_LENGTH.TEXT,
                    },
                  }}
                  {...register("well_site_name")}
                  helperText={errors.well_site_name?.message}
                />

                <ChipInput
                  required
                  id="edit-well-site-account-admin-names-input"
                  data={formatAccountAdminsNames(fetchedData.accountAdminsList)}
                  dataKey={DATA_KEY.ACCOUNT_ADMINS}
                  name="account_admin_names"
                  defaultValue={true}
                  multiple
                  label={t("ACCOUNT_ADMINS")}
                  placeholder={t("ACCOUNT_ADMINS_PLACEHOLDER")}
                  control={control}
                  setValue={setValue}
                />
                <ChipInput
                  id="edit-well-site-assigned-group-input"
                  data={formatGroupNames(fetchedData.assignedGroupsList)}
                  dataKey={DATA_KEY.GROUP_NAME}
                  name="assigned_groups"
                  defaultValue={true}
                  label={t("ASSIGNED_GROUPS")}
                  placeholder={t("ASSIGNED_GROUPS_PLACEHOLDER")}
                  control={control}
                  setValue={setValue}
                />

                <ChipInput
                  required
                  id="edit-well-site-operators-input"
                  data={formatOperatorsNames(fetchedData.operatorsList)}
                  dataKey={DATA_KEY.OPERATORS}
                  name="operators"
                  defaultValue={true}
                  multiple
                  label={t("OPERATORS")}
                  placeholder={t("OPERATORS_PLACEHOLDER")}
                  control={control}
                  setValue={setValue}
                />

                <FormFooterContainer>
                  <Button
                    className={"form-button outlined-button gray-border"}
                    title={t("DELETE")}
                    disabled={isLoading}
                    onClick={() => setShowConfirmDeletePopUp(true)}
                    endIcon={<StyledDeleteIcon />}
                  />
                  <Button
                    className={"form-button"}
                    title={t("SAVE_CHANGES")}
                    disabled={isLoading}
                    endIcon={<ICON_SAVE_COMPONENT />}
                    type="submit"
                  />
                </FormFooterContainer>
              </form>
            </TabPanel>

            <TabPanel value={tabvalue} index={1}>
              <ListWrapper>
                {selectedWellSiteData &&
                  selectedWellSiteData?.activity_list?.alerts?.map(
                    ({
                      alertCategory,
                      alertType,
                      alertId,
                      description,
                      acknowledgedBy,
                      reportedTime,
                    }) => {
                      return (
                        <ActivityCard
                          key={alertId}
                          type={alertCategory}
                          title={alertType}
                          content={description}
                          date={moment(reportedTime).format("DD MMM YYYY")}
                          author={acknowledgedBy}
                          handleDelete={() => handleDeleteSiteActivity(alertId)}
                        />
                      );
                    }
                  )}
              </ListWrapper>
            </TabPanel>
            <TabPanel value={tabvalue} index={2}>
              <ScrollDiv>
                {selectedDevice && (
                  <DropDown2
                    id="select-indicator"
                    data={devices ?? []}
                    value={selectedDevice}
                    dataKey={"deviceId"}
                    dataVal={"customName"}
                    handleChange={handleChangeDevice}
                    style={{
                      width: "320px",
                    }}
                  />
                )}

                <Charts selectedDevice={selectedDevice} />
              </ScrollDiv>
            </TabPanel>
          </FormWrapper>
        </CardContainer>
      </ContainerWrapper>
    </>
  ) : (
    <></>
  );
}
