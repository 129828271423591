import { makeCancellableAPIRequest } from ".";

export function getOrganizationListAPI(params) {
  const apiParams = {
    urlPath: "Organization",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveOrganizationDetailsAPI(data) {
  const apiParams = {
    urlPath: "organization/create",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
