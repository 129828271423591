import { forwardRef } from "react";

import { InputContainer, StyledInputAdornment, StyledLabel } from "./styles";
import { StyledTextField } from "./styles";

const Input = forwardRef(
  (
    {
      id,
      type,
      label,
      required = false,
      className,
      OnChange,
      startAdornment,
      endAdornment,
      helperText,
      placeholder,
      extraInputProps,
      ...rest
    },
    ref
  ) => {
    return (
      <InputContainer>
        <StyledLabel className={`${required ? "required" : null}`} htmlFor={id}>
          {label}
        </StyledLabel>
        <StyledTextField
          id={id}
          type={type}
          placeholder={placeholder}
          className={`${className}  ${helperText ? "errorTextColor" : null} ${
            startAdornment ? "with-StartAdornment" : null
          } ${endAdornment ? "with-EndAdornment" : null}`}
          helperText={helperText ? helperText : null}
          ref={ref}
          InputProps={{
            ...extraInputProps,
            startAdornment: startAdornment && (
              <StyledInputAdornment position="start">
                {startAdornment}
              </StyledInputAdornment>
            ),
            endAdornment: endAdornment && (
              <StyledInputAdornment position="end">
                {endAdornment}
              </StyledInputAdornment>
            ),
          }}
          FormHelperTextProps={{
            fontSize: "22px",
          }}
          {...rest}
        />
      </InputContainer>
    );
  }
);

Input.propTypes = {
  // onChange: func,
  // helperText: string,
  // endAdornment: object,
  // startAdornment: object,
  // extraInputProps: object,
};

export default Input;
