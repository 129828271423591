import { Close, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { ListItemText, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import _without from "lodash/without";
import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";

import {
  OutlinedInputContainer,
  StyledChip,
  StyledFormHelperText,
  StyledOutlinedInput,
  StyledSpan,
} from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const ChipInput = ({
  data = [],
  label,
  placeholder,
  name,
  dataKey,
  defaultValue,
  control,
  multiple = false,
  disabled = false,
  setValue,
  required = false,
  ...rest
}) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [loading, setLoading] = useState(true);
  const [chipData, setChipData] = useState(data || []);
  const [selectedValues, setSelectedValues] = useState([]);
  const controller = useController({ name, control });
  const { ref, value, onChange, ...fieldControllerProps } = controller.field;
  const { error } = controller.fieldState;

  useEffect(() => {
    if (dataKey) {
      setChipData(
        data.map((item) => {
          return item[dataKey];
        })
      );
    }
    defaultValue && !!value && setSelectedValues(value);
    setLoading(false);
  }, [multiple, name, setValue]);

  useEffect(() => {
    !multiple && !!value && setValue(name, value);
    multiple && setValue(name, selectedValues);
  }, [selectedValues]);

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    onChange(event);
  };

  const handleDelete = (e, value) => {
    if (disabled) return;
    e.preventDefault();
    setSelectedValues((current) => _without(current, value));
    !multiple && setValue(name, "");
  };

  return (
    <OutlinedInputContainer>
      <StyledSpan className={`${required ? "required" : null}`}>
        {label}
      </StyledSpan>
      <Select
        multiple={multiple}
        value={selectedValues}
        onChange={handleChange}
        input={<StyledOutlinedInput fullWidth disabled={disabled} />}
        IconComponent={KeyboardArrowDownOutlined}
        displayEmpty={true}
        renderValue={(selected) =>
          selected && selected?.length === 0 ? (
            placeholder
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {multiple ? (
                selected.map((value) => (
                  <StyledChip
                    key={value}
                    label={value}
                    clickable
                    deleteIcon={
                      <Close
                        style={{
                          color: "var(--color-revival-orange-orange-5)",
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(e) => handleDelete(e, value)}
                  />
                ))
              ) : (
                <StyledChip
                  key={value}
                  label={value}
                  clickable
                  deleteIcon={
                    <Close
                      style={{
                        color: "var(--color-revival-orange-orange-5)",
                      }}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              )}
            </Box>
          )
        }
        MenuProps={MenuProps}
        {...fieldControllerProps}
        inputRef={ref}
        error={!!error?.message}
        {...rest}
      >
        {!loading &&
          chipData.map((name) => (
            <MenuItem key={name} value={name}>
              <ListItemText primary={name || ""} />
            </MenuItem>
          ))}
      </Select>
      {error?.message && (
        <StyledFormHelperText error={!!error?.message}>
          {error?.message}
        </StyledFormHelperText>
      )}
    </OutlinedInputContainer>
  );
};
