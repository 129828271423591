import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import { formatData } from "./helper";
import { OutlinedInputContainer, StyledOutlinedInput, StyledSpan } from "./styles";

export const DropDown = ({
  value,
  handleChange,
  data = [],
  dataKey = null,
  label,
  placeholder,
  name,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const onChange = (e, val) => {
    handleChange(e);
  };

  useEffect(() => {
    const formattedData = formatData(data, dataKey);
    dataKey ? setList(formattedData) : setList(data);

    setIsLoading(false);
  }, [data, dataKey]);

  return !isLoading ? (
    <OutlinedInputContainer>
      <StyledSpan>{label}</StyledSpan>
      <Select
        value={value}
        onChange={onChange}
        input={<StyledOutlinedInput fullWidth />}
        displayEmpty={value === 0}
        {...rest}
      >
        {list &&
          list.map((name, index) => (
            <MenuItem key={index} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </OutlinedInputContainer>
  ) : null;
};
