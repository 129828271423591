import { Card } from "@mui/material";
import styled from "styled-components";

export const CardContainer = styled(Card)(({ theme }) => ({
  maxWidth: "710px",
  marginTop: "24px",
  marginBottom: "24px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "30px !important",
  border: "1px solid  #C2C2C2",
  padding: "24px !important",
}));

export const StyledText = styled.span`
  margin-top: 80px;
  margin-bottom: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;

  /* Color / Revival Mauve / Mauve 10 */

  color: #0a090a;
`;
