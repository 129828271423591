import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  customToast,
  getWellSiteData,
  setWellSiteData,
} from "../../utils/common";
import { ROUTES, TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import {
  triggerAccountAdminsAPI,
  triggerAssignedGroupsAPI,
  triggerOperatorsAPI,
} from "../../utils/restAPI/AdminAPI";
import {
  deleteWellSiteAcitivityAPI,
  deleteWellSiteDetailsAPI,
  getWellSiteAcitvityListAPI,
  getWellSiteDetailsAPI,
  getWellSiteListAPI,
  saveWellSiteDetailsAPI,
} from "../../utils/restAPI/WellSiteManagementAPI";
import WellSiteManagementView from "../../views/WellSiteManagementView";
import EditView from "../../views/WellSiteManagementView/EditView";

const log = logger("Well-Site-Management");
export default function WellSiteManagementContainer({ params, ...rest }) {
  const history = useHistory();
  const [isEmpty, setIsEmpty] = useState(true);
  const [fetchedData, setFetchedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWellSiteData, setSelectedWellSiteData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    getFormData();
    const wellsite = getWellSiteData();
    if (wellsite) {
      // setSelectedWellSiteData(wellsite);
      getWellSiteActivityDetails({ id: wellsite?.wellsite?.wellsiteId });
    }
    setIsEmpty(false);
  }, []);

  const getWellSiteDetails = async ({ id, cb }) => {
    const { promise, cancel } = getWellSiteDetailsAPI(id);
    promise
      .then((res) => {
        setSelectedWellSiteData(res?.data);
        setWellSiteData(res?.data);

        cb && cb(res.message);
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const getWellSiteActivityDetails = async ({ id, cb }) => {
    const { promise, cancel } = getWellSiteAcitvityListAPI(id);
    promise
      .then((res) => {
        setSelectedWellSiteData({
          ...getWellSiteData(),
          activity_list: res?.data,
        });
      })
      .catch((e) => {
        log.error(e);
      });
    return cancel;
  };

  const getFormData = async () => {
    const { promise: accountAdminsPromise } = triggerAccountAdminsAPI();
    const { promise: assignedGroupsPromise } = triggerAssignedGroupsAPI();
    const { promise: operatorsPromise } = triggerOperatorsAPI();

    const [accountAdminsRes, assignedGroupsRes, operatorsRes] =
      await Promise.all([
        accountAdminsPromise,
        assignedGroupsPromise,
        operatorsPromise,
      ]);

    setFetchedData({
      accountAdminsList: accountAdminsRes.data,
      assignedGroupsList: assignedGroupsRes.data,
      operatorsList: operatorsRes.data,
    });
    setIsLoading(false);
  };

  const saveWellSiteDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveWellSiteDetailsAPI(params);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        cb && cb();

        log.error(e);
      });

    return cancel;
  };

  const deleteWellSiteData = ({ params, cb }) => {
    const { promise, cancel } = deleteWellSiteDetailsAPI(params?.id);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_WELLSITE_DELETION`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const deleteWellSiteActivity = ({ params, cb }) => {
    const { promise, cancel } = deleteWellSiteAcitivityAPI(params);
    promise
      .then((res) => {
        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => null,
          TOAST_DURATION.SHORT
        );
        // cb && cb(res.message);
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const handleRowClick = (id) => {
    getWellSiteDetails({ id, cb: redirectToEditWellSite });
  };
  const redirectToEditWellSite = (id) => {
    history.push(ROUTES.EDIT_WELL_SITE);
  };

  return params?.isEdit && selectedWellSiteData && !isLoading ? (
    <EditView
      selectedWellSiteData={selectedWellSiteData}
      fetchedData={fetchedData}
      saveWellSiteDetailsData={saveWellSiteDetailsData}
      deleteWellSiteData={deleteWellSiteData}
      deleteWellSiteActivity={deleteWellSiteActivity}
      loading={isLoading}
    />
  ) : (
    <WellSiteManagementView
      fetchedData={fetchedData}
      saveWellSiteDetailsData={saveWellSiteDetailsData}
      isEmpty={isEmpty}
      onRowClick={handleRowClick}
      api={getWellSiteListAPI}
      {...rest}
    />
  );
}
