import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ICON_PLUS_WHITE_COMPONENT } from "../../assets/images";
import { Button } from "../../components";

import TableLayout from "../../components/TableLayout";
import { POPUP_TYPES } from "../../utils/constants";
import { formatRowData, getDataArray, headCells } from "./helper";
import NoDataView from "./NoDataView";
import PopUpForm from "./PopUpForm";
import {
  ButtonsContainer,
  ContainerWrapper,
  PageHeading,
  TopContainer,
} from "./styles";

export default function GroupManagementView({
  loading,
  isEmpty,
  api,
  onRowClick,
  ...rest
}) {
  const { t } = useTranslation();

  const [type, setType] = useState(null);

  const handleAddWellSite = () => {
    setType(POPUP_TYPES.ADD_A_WELL_SITE);
  };
  const handleCreateGroup = () => {
    setType(POPUP_TYPES.CREATE_A_GROUP);
  };

  return (
    !loading && (
      <>
        <PopUpForm open={!!type} setOpen={setType} type={type} {...rest} />
        <ContainerWrapper>
          <TopContainer>
            <PageHeading>{t("GROUP_MANAGEMENT")}</PageHeading>
            <ButtonsContainer>
              <Button
                className={"header-button"}
                title={t("CREATE_GROUP")}
                startIcon={<ICON_PLUS_WHITE_COMPONENT />}
                onClick={handleCreateGroup}
              />
            </ButtonsContainer>
          </TopContainer>

          <TableLayout
            api={api}
            headCells={headCells}
            formatRowData={formatRowData}
            onRowClick={onRowClick}
            getDataArray={getDataArray}
            emptyView={
              <NoDataView
                handleAddWellSite={handleAddWellSite}
                handleCreateGroup={handleCreateGroup}
              />
            }
          />
        </ContainerWrapper>
      </>
    )
  );
}

