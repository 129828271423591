import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ICON_SAVE_COMPONENT } from "../../assets/images";
import { Button } from "../../components";
import { AlarmAndWarningCard } from "../../components/Cards";
import { StyledAlertSectionHeading, StyledAlertSectionSubHeading } from "../../components/Cards/styles";
import { Tab, TabPanel, Tabs } from "../../components/CustomTabs";
import { DropDown } from "../../components/DropDown";
import { ACCOUNT_TYPES, ALERT_TYPES, ALERT_TYPES_ID, NOTIFICATION_CATEGORY, NOTIFICATION_INTERVALS, SUPERVISOR_NOTIFICATION } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { getDerivedData, isLeak } from "./helper";
import { CardContainer, CheckBoxDiv, ColumnDiv, ContainerWrapper, FormFooterContainer, FormWrapper, PageHeading, RowDiv, StyledCheckBox, StyledGrid, StyledRefreshIcon, TopContainer } from "./styles";

export default function AlertManagementView({
  fetchedData,
  restoreDefaultsData,
  saveChangesData,
  toggleAlertData,
}) {
  const { t } = useTranslation();

  const [alertConfigData, setAlertConfigData] = useState({});
  const [alertData, setAlertData] = useState({});
  const [tenantId, setTenantId] = useState(null);

  const [isDisabled, setIsDisabled] = useState(null);

  const { accountDetails } = useActiveUserContext();

  useEffect(() => {
    setIsDisabled(accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN);
  }, [accountDetails]);

  useEffect(() => {
    const [alarmsConfig, warningsConfig] = getDerivedData(fetchedData);

    setAlertConfigData({ alarmsConfig, warningsConfig });

    setTenantId(alarmsConfig?.tenantId);

    setAlertData({
      alarmsData: alarmsConfig?.alertConfigDetails,
      warningsData: warningsConfig?.alertConfigDetails,
    });
  }, [fetchedData]);

  const [tabvalue, setTabValue] = useState(0);

  const handleRestoreDefaultAlertsData = (type) => {
    tenantId &&
      restoreDefaultsData({
        params: {
          tenantId: tenantId,
          id: type,
        },
        cb: handleBack,
      });
  };

  const handleSaveChange = (id) => {
    const key =
      id === ALERT_TYPES_ID.ALARMS ? "alarmsConfig" : "warningsConfig";
    tenantId &&
      saveChangesData({
        params: {
          ...alertConfigData?.[key],
        },
        cb: handleBack,
      });
  };

  const handleAlertToggle = (
    alertConfigurationDetailId,
    alertConfigurationId
  ) => {
    tenantId &&
      toggleAlertData({
        params: {
          alertConfigurationDetailId,
          alertConfigurationId: alertConfigurationId === 1 ? 2 : 1,

        },
        cb: handleBack,
      });
  };

  const getNotificationConfig = (data) => {
    const {
      notificationViaEmail,
      notificationViaInApp,
      notificationViaSms,
      alertConfigurationId,
    } = data;

    const handleChange = (checkOption, key) => {
      let changeState = { [key]: !checkOption };
      let configType =
        alertConfigurationId === 1 ? "alarmsConfig" : "warningsConfig";

      setAlertConfigData((prevState) => ({
        ...prevState,
        [configType]: {
          ...prevState?.[configType],
          ...changeState,
        },
      }));
    };

    return (
      <CheckBoxDiv>
        <div>
          <StyledCheckBox
            checked={notificationViaEmail}
            onChange={() =>
              handleChange(notificationViaEmail, "notificationViaEmail")
            }
            disabled={isDisabled}
          />
          Email
        </div>
        <div>
          <StyledCheckBox
            checked={notificationViaSms}
            onChange={() =>
              handleChange(notificationViaSms, "notificationViaSms")
            }
            disabled={isDisabled}
          />
          SMS
        </div>
        <div>
          <StyledCheckBox
            checked={notificationViaInApp}
            onChange={() =>
              handleChange(notificationViaInApp, "notificationViaInApp")
            }
            disabled={isDisabled}
          />
          In App
        </div>
      </CheckBoxDiv>
    );
  };

  const handleBack = () => {};

  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleDropDownChange = (event, alertType, key) => {
    let changeState = { [key]: event.target.value };
    let configType =
      alertType === ALERT_TYPES_ID.ALARMS ? "alarmsConfig" : "warningsConfig";

    setAlertConfigData((prevState) => ({
      ...prevState,
      [configType]: {
        ...prevState?.[configType],
        ...changeState,
      },
    }));
  };
  return (
    <>
      <ContainerWrapper>
        <TopContainer>
          <PageHeading>{t("ALERT_MANAGEMENT")}</PageHeading>
        </TopContainer>
        <CardContainer elevation={0}>
          <FormWrapper className={"fullwidth-container"}>
            <Tabs value={tabvalue} onChange={handleChange}>
              <Tab label={t("ALARMS")} index={0} />
              <Tab label={t("WARNINGS")} index={1} />
            </Tabs>
            <TabPanel value={tabvalue} index={0}>
              <StyledAlertSectionHeading>
                Notifications
              </StyledAlertSectionHeading>

              <RowDiv spaceBetween>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Notification Channels
                  </StyledAlertSectionSubHeading>
                  {alertConfigData?.alarmsConfig &&
                    getNotificationConfig(alertConfigData?.alarmsConfig)}
                </ColumnDiv>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Send Notifications Every
                  </StyledAlertSectionSubHeading>
                  <DropDown
                    value={
                      alertConfigData?.alarmsConfig?.sendNotificationEvery ||
                      false
                    }
                    data={NOTIFICATION_INTERVALS}
                    handleChange={(e) =>
                      handleDropDownChange(
                        e,
                        ALERT_TYPES_ID.ALARMS,
                        NOTIFICATION_CATEGORY.SEND_NOTIFICATION_EVERY
                      )
                    }
                    disabled={isDisabled}
                    defaultValue={NOTIFICATION_INTERVALS[0]}
                  />
                </ColumnDiv>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Notify Supervisor On
                  </StyledAlertSectionSubHeading>
                  <DropDown
                    value={
                      alertConfigData?.alarmsConfig?.notifySupervisorOn || false
                    }
                    data={SUPERVISOR_NOTIFICATION}
                    handleChange={(e) =>
                      handleDropDownChange(
                        e,
                        ALERT_TYPES_ID.ALARMS,
                        NOTIFICATION_CATEGORY.NOTIFY_SUPERVISOR_ON
                      )
                    }
                    disabled={isDisabled}
                    defaultValue={SUPERVISOR_NOTIFICATION[0]}
                  />
                </ColumnDiv>
              </RowDiv>

              <StyledAlertSectionHeading>Alarm Types</StyledAlertSectionHeading>

              <StyledGrid container>
                {alertData?.alarmsData?.map(
                  (
                    {
                      alarmType,
                      alertConfigurationDetailId,
                      alertConfigurationId,
                    },
                    index
                  ) => {
                    return (
                      <Grid item key={index}>
                        <AlarmAndWarningCard
                          type={ALERT_TYPES.ALARMS}
                          title={alarmType}
                          number={alertConfigurationDetailId}
                          onClick={() =>
                            handleAlertToggle(
                              alertConfigurationDetailId,
                              alertConfigurationId
                            )
                          }
                          switchDisabled={isLeak(alarmType) || isDisabled}
                        ></AlarmAndWarningCard>
                      </Grid>
                    );
                  }
                )}
              </StyledGrid>

              <RowDiv>
                <FormFooterContainer>
                  <Button
                    className={"form-button outlined-button gray-border"}
                    title={t("RESTORE_DEFAULTS")}
                    disabled={isDisabled}
                    onClick={() =>
                      handleRestoreDefaultAlertsData(ALERT_TYPES_ID.ALARMS)
                    }
                    endIcon={<StyledRefreshIcon />}
                  />
                  <Button
                    className={"form-button"}
                    title={t("SAVE_CHANGES")}
                    disabled={isDisabled}
                    endIcon={<ICON_SAVE_COMPONENT />}
                    onClick={() => handleSaveChange(ALERT_TYPES_ID.ALARMS)}
                    type="submit"
                  />
                </FormFooterContainer>
              </RowDiv>
            </TabPanel>
            <TabPanel value={tabvalue} index={1}>
              <StyledAlertSectionHeading>
                Notifications
              </StyledAlertSectionHeading>

              <RowDiv spaceBetween>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Notification Channels
                  </StyledAlertSectionSubHeading>
                  {alertConfigData?.warningsConfig &&
                    getNotificationConfig(alertConfigData?.warningsConfig)}
                </ColumnDiv>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Send Notifications Every
                  </StyledAlertSectionSubHeading>
                  <DropDown
                    data={NOTIFICATION_INTERVALS}
                    value={
                      alertConfigData?.warningsConfig?.sendNotificationEvery ||
                      false
                    }
                    handleChange={(e) =>
                      handleDropDownChange(
                        e,
                        ALERT_TYPES_ID.WARNINGS,
                        NOTIFICATION_CATEGORY.SEND_NOTIFICATION_EVERY
                      )
                    }
                    disabled={isDisabled}
                    defaultValue={NOTIFICATION_INTERVALS[0]}
                  />
                </ColumnDiv>
                <ColumnDiv>
                  <StyledAlertSectionSubHeading>
                    Notify Supervisor On
                  </StyledAlertSectionSubHeading>
                  <DropDown
                    data={SUPERVISOR_NOTIFICATION}
                    value={
                      alertConfigData?.warningsConfig?.notifySupervisorOn ||
                      false
                    }
                    handleChange={(e) =>
                      handleDropDownChange(
                        e,
                        ALERT_TYPES_ID.WARNINGS,
                        NOTIFICATION_CATEGORY.NOTIFY_SUPERVISOR_ON
                      )
                    }
                    disabled={isDisabled}
                    defaultValue={SUPERVISOR_NOTIFICATION[0]}
                  />
                </ColumnDiv>
              </RowDiv>

              <StyledAlertSectionHeading>
                Warnings Types
              </StyledAlertSectionHeading>

              <StyledGrid container>
                {alertData?.warningsData?.map(
                  (
                    {
                      alarmType,
                      alertConfigurationDetailId,
                      alertConfigurationId,
                    },
                    index
                  ) => {
                    return (
                      <Grid item key={index}>
                        <AlarmAndWarningCard
                          key={index}
                          type={ALERT_TYPES.WARNINGS}
                          title={alarmType}
                          number={alertConfigurationDetailId}
                          onClick={() =>
                            handleAlertToggle(
                              alertConfigurationDetailId,
                              alertConfigurationId
                            )
                          }
                          switchDisabled={isLeak(alarmType) || isDisabled}
                        ></AlarmAndWarningCard>
                      </Grid>
                    );
                  }
                )}
              </StyledGrid>
              <RowDiv>
                <FormFooterContainer>
                  <Button
                    className={"form-button outlined-button gray-border"}
                    title={t("RESTORE_DEFAULTS")}
                    disabled={isDisabled}
                    onClick={() =>
                      handleRestoreDefaultAlertsData(ALERT_TYPES_ID.WARNINGS)
                    }
                    endIcon={<StyledRefreshIcon />}
                  />
                  <Button
                    className={"form-button"}
                    title={t("SAVE_CHANGES")}
                    disabled={isDisabled}
                    onClick={() => handleSaveChange(ALERT_TYPES_ID.WARNINGS)}
                    endIcon={<ICON_SAVE_COMPONENT />}
                    type="submit"
                  />
                </FormFooterContainer>
              </RowDiv>
            </TabPanel>
          </FormWrapper>
        </CardContainer>
      </ContainerWrapper>
    </>
  );
}
