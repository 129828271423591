import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "../../components/Button";
import Footer from "../../components/Footer";
import HalfPageLayout from "../../components/HalfPageLayout";
import Input from "../../components/Input";
import InputPassword from "../../components/InputPassword";
import { checkEmailRegex } from "../../utils";
import { MAX_LENGTH } from "../../utils/constants";
import {
  FormFoooterContainer,
  StyledForm,
  StyledHead,
  StyledInputIcon,
  StyledLinkWithText,
} from "./styles";

export default function LoginView({ fetchAPIData }) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const onSubmit = (params) => {
    fetchAPIData(params);
  };

  const renderFooter = () => <Footer />;

  return (
    <HalfPageLayout footer={renderFooter()}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledHead>{t("LOGIN")}</StyledHead>

        <Input
          required
          id="login-company-email-input"
          label={t("COMPANY_EMAIL")}
          placeholder={t("EMAIL_PLACEHOLDER")}
          startAdornment={<StyledInputIcon />}
          extraInputProps={{
            inputProps: {
              autoComplete: "email",
              maxLength: MAX_LENGTH.EMAIL,
            },
          }}
          {...register("email", {
            required: t("ERROR_EMAIL_REQUIRED"),
            pattern: {
              value: checkEmailRegex(),
              message: t("ERROR_INVALID_EMAIL"),
            },
          })}
          helperText={errors.email?.message}
        />
        <InputPassword
          required
          id="login-password-input"
          label={t("PASSWORD")}
          placeholder={t("ENTER_PASSWORD")}
          InputLabelProps={{
            shrink: false,
          }}
          extraInputProps={{
            inputProps: {
              autoComplete: "current-password",
              maxLength: MAX_LENGTH.PASSWORD,
            },
          }}
          {...register("password", {
            required: t("ERROR_PASSWORD_REQUIRED"),
          })}
          helperText={errors.password?.message}
        />
        <StyledLinkWithText
          text={t("FORGOT_PASSWORD?")}
          to="/forgot-password"
        />

        <FormFoooterContainer>
          <Button
            title={t("LOGIN")}
            type="submit"
            fullWidth
            disabled={!isValid}
          />
        </FormFoooterContainer>
      </StyledForm>
    </HalfPageLayout>
  );
}
