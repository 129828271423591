import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  height: 56px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 16px;
  background-color: var(--color-revival-orange-orange-5);
  box-shadow: none;
  text-transform: none;
  &.MuiButton-root {
    font-size: 19px;
    font-weight: 500;

    &.Mui-disabled {
      color: white;
    }
  }

  &.MuiButton-root:hover {
    background-color: var(--color-revival-orange-orange-5);
  }

  &.header-button {
    background: #f05423;
    /* Color / Revival Orange / Orange 5 */

    border: 2px solid #f05423;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 24px;
    font-size: 15px !important;
    margin-top: 0px !important;
  }
  &.lg-padding {
    padding: 10px 76px;
  }
  @media screen and (max-width: 1200px) {
    &.lg-padding {
      padding: 2px 36px;
    }
  }

  &.outlined-button {
    color: #f05423;
    background: #ffffff;
    border: 2px solid #f05423;
  }
  &.outlined-button:hover {
    color: #f05423;
    background: #ffffff;
    border: 2px solid #f05423;
  }

  &.gray-border {
    color: #675c68 !important;
    border: 2px solid #675c68 !important;
  }
  &.form-button {
    font-size: 15px;
    padding-inline: 32px;
  }
  &.disabled-button {
    color: #d6d6d6 !important;
    border: 2px solid #d6d6d6 !important;
  }
`;
