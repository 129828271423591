import styled from "@emotion/styled";
import { OutlinedInput } from "@mui/material";

export const StyledOutlinedInput = styled(OutlinedInput)`
  border-radius: 10px;
  height: 49px;
  margin: 0px;

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e1e1e1;
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 178px;
    padding-left: 18px;
  }
`;

export const StyledSpan = styled("div")(() => ({
  marginTop: "-12px",
  marginBottom: "14px",
  fontWeight: "500",
}));

export const OutlinedInputContainer = styled("div")(() => ({
  marginTop: "0px",
}));

export const AlarmCard = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px 13px;
  gap: 10px;

  /* Color / System / Red / Red 2 */

  background: #ffcdcd;
  border-radius: 8px 8px 0px 0px;
`;
