import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants";

// export function setToken(token) {
//   localStorage.setItem(ACCESS_TOKEN_KEY, token);
// }

export function setToken(obj) {
  localStorage.setItem(ACCESS_TOKEN_KEY, obj.access_token);
  localStorage.setItem(REFRESH_TOKEN_KEY, obj.refresh_token);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function clearToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function setUserData(data) {
  localStorage.setItem("USERDATA", JSON.stringify(data));
}

export function getUserData() {
  return JSON.parse(localStorage.getItem("USERDATA"));
}

export function setGroupData(data) {
  localStorage.setItem("GROUPDATA", JSON.stringify(data));
}

export function getGroupData() {
  return JSON.parse(localStorage.getItem("GROUPDATA"));
}

export function setWellSiteData(data) {
  localStorage.setItem("WELLSITEDATA", JSON.stringify(data));
}

export function getWellSiteData() {
  return JSON.parse(localStorage.getItem("WELLSITEDATA"));
}

export function setAccountName(data) {
  localStorage.setItem("ACCOUNTNAME", JSON.stringify(data));
}

export function getAccountName() {
  return JSON.parse(localStorage.getItem("ACCOUNTNAME"));
}

export function logout() {
  localStorage.clear();
}
