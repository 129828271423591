import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ICON_CLOSE } from "../../assets/images";
import { Button } from "../../components";
import { ChipInput } from "../../components/ChipInput";
import { DatePickerInputController } from "../../components/DatePickerInput";
import { ScrollDialog } from "../../components/Dialog";
import { DATA_KEY, ROUTES } from "../../utils/constants";
import {
  AssignDeviceValidation,
  formatDeviceList,
  getDeviceID,
  ReturnDeviceValidation,
} from "./helper";
import {
  FormFooterContainer,
  FormHeaderContainer,
  FormHeading,
  FormWrapper,
  StyledImg,
} from "./styles";

function AssignDevicePopUpForm({
  open,
  setOpen,
  fetchedData,
  saveDeviceDetailsData,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const defaultValues = {
    billing_start_date: new Date(),
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(AssignDeviceValidation()),
    mode: "onChange",
  });

  const isSelectionValid = useWatch({
    control,
    name: "device_name",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    setIsLoading(true);
    saveDeviceDetailsData({
      params: {
        deviceId: getDeviceID(fetchedData.deviceList, formValues?.device_name),
        organizationId: 9, //hardcoded
        billingStartDate: formValues?.billing_start_date.toISOString(),
      },
      cb: redirectToDeviceManagement,
    });
  };

  const redirectToDeviceManagement = () => {
    handleClose();
    window.location = ROUTES.DEVICE_MANAGEMENT;
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>{t("ASSIGN_A_DEVICE")}</FormHeading>
          <StyledImg src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChipInput
            required
            id="assign-device-device-name-chip-input"
            data={formatDeviceList(fetchedData.deviceList)}
            dataKey={DATA_KEY.DEVICE_NAME}
            name="device_name"
            label={t("DEVICE_ID")}
            placeholder={t("DEVICE_ID_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <DatePickerInputController
            required
            name="billing_start_date"
            control={control}
            setValue={setValue}
            id="assign-billing-start-date-input"
            label={t("BILLING_START_DATE")}
            placeholder={t("BILLING_START_DATE_PLACEHOLDER")}
          />

          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={t("ASSIGN_A_DEVICE")}
              type="submit"
              disabled={!isValid || isLoading || !isSelectionValid}
            />
            <Button
              className={"form-button outlined-button gray-border"}
              title={t("CANCEL")}
              onClick={handleClose}
              disabled={isLoading}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

function ReturnDevicePopUpForm({
  open,
  setOpen,
  fetchedData,
  returnDeviceDetailsData,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const defaultValues = {};

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(ReturnDeviceValidation()),
    mode: "onChange",
  });

  const handleClose = () => {
    setIsLoading(false);
    reset();
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    setIsLoading(true);
    returnDeviceDetailsData({
      params: {
        id: getDeviceID(
          fetchedData?.assignedDeviceList,
          formValues?.device_name
        ),
      },
      cb: redirectToDeviceManagement,
    });
  };
  const redirectToDeviceManagement = () => {
    handleClose();
    window.location = ROUTES.DEVICE_MANAGEMENT;
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>{t("RETURN_A_DEVICE")}</FormHeading>
          <StyledImg src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChipInput
            required
            id="assign-device-device-name-chip-input"
            data={formatDeviceList(fetchedData.assignedDeviceList)}
            dataKey={DATA_KEY.DEVICE_NAME}
            name="device_name"
            label={t("DEVICE_ID")}
            placeholder={t("DEVICE_ID_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={t("RETURN_DEVICE")}
              type="submit"
              disabled={!isValid || isLoading}
            />
            <Button
              className={"form-button outlined-button gray-border"}
              title={t("CANCEL")}
              onClick={handleClose}
              disabled={isLoading}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

export { AssignDevicePopUpForm, ReturnDevicePopUpForm };
