import styled from "styled-components";
import { SearchBar } from "../../components";

export const ContainerWrapper = styled.div`
  padding: 22px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 26.15%,
      #ffffff 47%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.75) 11.05%, #ffffff 41.67%),
    url("halfpage_graphics.png");
  background-blend-mode: normal, multiply, normal;
`;

export const StyledPageHeading = styled.span`
  margin-top: 16px;
  margin-bottom: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;

  /* Revival Orange */

  color: #ff5c1e;
`;

export const StyledPageSubHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;

  /* Black */

  color: #fff;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledSearchBar = styled(SearchBar)``;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.02em;
`;

export const CardContainer = styled.div`
  /* Frame 35878 */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  background: #ffffff;
  border: 1px solid #f1f1f5;
  border-radius: 16px;
`;

// styled(Card)(({ theme }) => ({
//   marginTop: "5px",
//   borderRadius: "16px",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   // padding: "24px",
// }));

export const HeadingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const BlankPageContainer = styled.div`
  padding: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeading1 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;

  /* identical to box height */

  text-align: left;

  color: #000000;
`;

export const StyledHeading2 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;

  /* identical to box height */

  text-align: left;
  margin-top: 14px;
  color: #ff5c1e;
`;

export const FormWrapper = styled.div`
  margin: 32px;
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d8dbe3;
  padding-bottom: 14px;
`;

export const FormHeading = styled.span`
  border-bottom: 1px solid #f9f9f9;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150.26%;
  /* identical to box height, or 30px */

  color: #11142d;
`;

export const FormFooterContainer = styled.div`
  border-top: 1px solid #d8dbe3;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  padding-top: 28px;
  gap: 10px;
`;

export const PanelContainer = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #f1f1f5;
  border-radius: 16px;
`;

export const AlertPanel = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`;
