import React from "react";

import { StyledButton } from "./styles";

function Button({ title, ...rest }) {
  return (
    <StyledButton variant="contained" {...rest}>
      {title}
    </StyledButton>
  );
}

export default Button;
