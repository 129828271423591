import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { customToast } from "../../utils/common";
import { TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { triggerGetUserRolesAPI } from "../../utils/restAPI/AdminAPI";
import { getOrganizationListAPI, saveOrganizationDetailsAPI } from "../../utils/restAPI/OrganizationManagementAPI";
import OrganizationManagementView from "../../views/OrganizationManagementView";

const log = logger("Organization-Management");
export default function OrganizationManagementContainer({ ...rest }) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [fetchedData, setFetchedData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setIsEmpty(false);
    getFormData();
  }, []);

  const getFormData = async () => {
    const { promise: userRolesPromise } = triggerGetUserRolesAPI();
    const [userRolesRes] = await Promise.all([userRolesPromise]);
    setFetchedData({
      userRolesList: userRolesRes.data,
    });
  };

  const saveOrganizationDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveOrganizationDetailsAPI(params);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_ORGANISATION_CREATION`),
          TOAST_TYPES.SUCCESS,
          () => cb(),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  return (
    <OrganizationManagementView
      isEmpty={isEmpty}
      saveOrganizationDetailsData={saveOrganizationDetailsData}
      fetchedData={fetchedData}
      api={getOrganizationListAPI}
      {...rest}
    />
  );
}
