import { Card } from "@mui/material";
import styled from "styled-components";

export const CardContainer = styled(Card)(({ theme }) => ({
  marginTop: "24px",
  display: "flex",
  padding: "20px",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "16px",
}));

export const HeaderContainer = styled("div")(({ theme, repeat }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${repeat},1fr)`,
}));

export const HeaderCells = styled.div`
  height: 40px;
  border-bottom: 2px solid #e4e7ec;
  display: flex;
  justify-content: flex-start;

  flex-direction: row;
  align-items: center;
  padding: 12px 24px;

  /* Gray/50 */

  background: #f9fafb;
`;
export const DataCells = styled.div`
  height: 72px;
  border-bottom: 2px solid #e4e7ec;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  overflow: hidden;
`;

export const StyledPara = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomCell = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Color / System / Green / Green 5 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;

  height: 22px;

  /* Color / System / Green / Green 1 */

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ArrayChip = styled.div`
  padding: 5px;
  padding-inline: 13px;
  color: #ff5c1e;
  margin-left: 4px;

  background: rgba(255, 92, 30, 0.1);
  border: 1px solid #ffffff;
  border-radius: 15px;
`;
