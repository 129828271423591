import React from "react";
import { Redirect, Route } from "react-router-dom";

import MainLayout from "../../components/MainLayout/index.js";
import { isLoggedIn } from "../index.js";

const PrivateRoutes = ({ component: Component, halfPageLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          halfPageLayout ? (
            <Component {...props} {...rest} />
          ) : (
            <MainLayout component={Component} props={props} {...rest} />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoutes;
