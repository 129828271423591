import { map } from "lodash";
import React from "react";
import ReactTooltip from "react-tooltip";

import { ArrayChip, CustomCell, DataCells, HeaderContainer, StyledPara } from "./styles";

const TableRowData = ({ headCells, rows, onRowClick }) => {
  const renderCell = (cellData, key) => {
    if (typeof cellData === "object") {
      var type = Array.isArray(cellData) ? "array" : "object";
    }

    switch (type) {
      case "object":
        return (
          <DataCells key={key}>
            <CustomCell style={cellData?.style}>
              <StyledPara data-tip data-for={"celldata" + key}>
                {cellData?.data}
              </StyledPara>
              <ReactTooltip id={"celldata" + key} place="top" effect="solid">
                {cellData?.data}
              </ReactTooltip>
            </CustomCell>
          </DataCells>
        );
      case "array":
        return (
          <DataCells key={key}>
            <StyledPara data-tip data-for={"celldata" + key}>
              {cellData[0]}{" "}
            </StyledPara>
            <ReactTooltip id={"celldata" + key} place="top" effect="solid">
              {cellData[0]}{" "}
            </ReactTooltip>
            {cellData.length > 1 && (
              <ArrayChip>{`${cellData.length - 1}+`}</ArrayChip>
            )}
          </DataCells>
        );
      default:
        return (
          <DataCells key={key}>
            <StyledPara data-tip data-for={"celldata" + key}>
              {cellData || ""}
            </StyledPara>
            <ReactTooltip id={"celldata" + key} place="top" effect="solid">
              {cellData || ""}
            </ReactTooltip>
          </DataCells>
        );
    }
  };

  if (rows.length > 0) {
    return rows.map((row, idx) => {
      return (
        <HeaderContainer
          key={idx}
          repeat={headCells.length}
          onClick={() => onRowClick(row.id)}
        >
          {map(headCells, (cell, index) => {
            const cellData = row[cell.name];
            return renderCell(cellData, idx * 10 + index);
          })}
        </HeaderContainer>
      );
    });
  } else {
    return <></>;
  }
};

export default TableRowData;
