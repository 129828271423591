import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  OutlinedInputContainer,
  StyledOutlinedInput,
  StyledSpan,
} from "./styles";

export const DropDown2 = ({
  value,
  handleChange,
  data = [],
  dataKey = null,
  dataVal = null,
  label,
  placeholder,
  name,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const onChange = (e, val) => {
    handleChange({ id: val.props.id, value: val.props.value });
  };

  useEffect(() => {
    setIsLoading(false);
  }, [data, dataKey]);

  return !isLoading ? (
    <OutlinedInputContainer>
      <StyledSpan>{label}</StyledSpan>
      <Select
        value={value?.[dataVal]}
        onChange={onChange}
        input={<StyledOutlinedInput fullWidth />}
        displayEmpty={value === 0}
        {...rest}
      >
        {data &&
          data.map((item, index) => (
            <MenuItem
              key={index}
              value={item[dataVal]}
              id={item[dataKey] ?? item[dataVal]}
            >
              {item[dataVal]}
            </MenuItem>
          ))}
      </Select>
    </OutlinedInputContainer>
  ) : null;
};
