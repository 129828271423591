import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import { customToast } from "../../utils/common";
import { TOAST_TYPES } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { logger } from "../../utils/logger";
import { resetAccountAdminPasswordAPI, saveAccountDetailsAPI } from "../../utils/restAPI/AdminAPI";
import SettingsView from "../../views/SettingsView";

const log = logger("Settings");
export default function SettingsContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const { accountDetails, triggerGetAccountDetails } = useActiveUserContext();

  const getAccountDetails = () => {
    setIsLoading(true);
    triggerGetAccountDetails();
    setIsLoading(false);
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  const saveAccountDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveAccountDetailsAPI(params);
    promise
      .then((res) => {
        customToast(t(`SUCCESS_MESSAGE_SAVE_CHANGES`), TOAST_TYPES.SUCCESS);
        getAccountDetails();
        cb && cb();
      })
      .catch((e) => {
        cb && cb();
        log.error(e);
      });

    return cancel;
  };

  const resetAccountPasswordData = ({ params, cb }) => {
    const { promise, cancel } = resetAccountAdminPasswordAPI(params);
    promise
      .then((res) => {
        customToast(t(`SUCCESS_MESSAGE_PASSWORD_RESET`), TOAST_TYPES.SUCCESS);
        cb && cb();
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  return !isLoading && accountDetails ? (
    <SettingsView
      accountDetails={accountDetails}
      saveAccountDetailsData={saveAccountDetailsData}
      resetAccountPasswordData={resetAccountPasswordData}
    />
  ) : (
    <Loader />
  );
}
