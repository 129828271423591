import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loader from "../../components/Loader";
import { customToast, getUserData } from "../../utils/common";
import { ROUTES, TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { triggerResetPasswordAPI } from "../../utils/restAPI/AuthAPI";
import ResetPasswordView from "../../views/ResetPasswordView";

const log = logger("Reset-Password");

export default function ResetPasswordContainer({ ...rest }) {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  const triggerResetPassword = ({ params, cb }) => {
    const { promise, cancel } = triggerResetPasswordAPI(params);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_PASSWORD_RESET`),
          TOAST_TYPES.SUCCESS,
          () => reDirecToLogin(),
          TOAST_DURATION.SHORT
        );
        cb && cb();
      })
      .catch((e) => log.error(e));

    return cancel;
  };

  const reDirecToLogin = () => {
    history.push(ROUTES.LOGIN);
  };

  return userData ? (
    <ResetPasswordView
      triggerResetPassword={triggerResetPassword}
      userData={userData}
      {...rest}
    />
  ) : (
    <Loader />
  );
}
