import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors, LAYOUT_CONSTANTS } from "../../assets/css/theme";

export const PrimaryLink = styled(Link)(() => ({
  transition: LAYOUT_CONSTANTS.BORDER_TRANSITION,
  color: colors.secondary,
  textDecoration: "none",
  lineHeight: "21px",
  userSelect: "none",

  // ...linkVariant(styledTheme.borders[5], styledTheme.borders[3]),
}));

export const SecondaryLink = styled(Link)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  user-select: none;
  color: #675c68;
  text-decoration: none;
`;

export const LinkIcon = styled.img`
  margin-inline: 5px;
  width: 17px;
  height: 18px;
  color: #675c68;
`;

export const AnchorStyled = styled.a`
  color: #000;
`;
