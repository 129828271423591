import { Card } from "@mui/material";
import styled from "styled-components";

import { ICON_ALARMS_COMPONENT_GRAY, ICON_CALENDAR_COMPONENT, ICON_DELETE_COMPONENT, ICON_WARNINGS_COMPONENT_GRAY } from "../../assets/images";

export const StyledAlertCard = styled(Card)`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;

  background: #ffffff;
  /* Color / Gray / Gray 4 */

  border: 1px solid #c2c2c2;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const CardContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAlarmHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Gray 1 */

  color: #333333;
`;

export const StyledAlertSectionHeading = styled.span`
  margin-top: 56px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;

  /* Black */

  color: #000000;
`;

export const StyledAlertSectionSubHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Color / Gray / Gray 10 */

  color: #050505;
`;

export const StyledAlarmCardHeader = styled(Card)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 11px 13px;
  gap: 10px;
  width: 280px;
  height: 46px;

  /* Color / System / Red / Red 2 */

  background: #ffcdcd;
  border-radius: 8px 8px 0px 0px;
  border-top: none;

  border: 1px solid #c2c2c2;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledWarningCardHeader = styled(Card)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 11px 13px;
  gap: 10px;
  width: 280px;
  height: 46px;

  /* Color / System / Red / Red 2 */

  background: #ffe7ca;
  border-radius: 8px 8px 0px 0px;
  border-top: none;

  border: 1px solid #c2c2c2;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledAlarmCardBody = styled(Card)`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  gap: 16px;

  width: 280px;

  /* Color/System/Gray/Gray-1 */

  background: #ffffff;

  border: 1px solid #c2c2c2;
  border-top: none;
  border-radius: 0px 0px 8px 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledCardHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Color / System / Gray / Gray-10 */

  color: #262626;
`;

export const StyledStrechedCard = styled(Card)`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 16px;

  /* Color / Gray / Gray 1 */

  background: #ffffff;
  /* Color / Gray / Gray 4 */

  border: 1px solid #c2c2c2;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const StyledValue = styled.span`
  &.alerts {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 69px;

    /* identical to box height */

    text-align: center;
    letter-spacing: 0.02em;
  }
  &.alert-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    text-align: center;
    letter-spacing: 0.02em;
  }
  &.alarms {
    /* Color / System / Red / Red 5 */
    color: #ff5757;
  }
  &.warnings {
    /* Color / System / Yellow / Yellow 5 */
    color: #ffae50;
  }

  &.streched-tile-title {
    margin-top: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* identical to box height */

    /* Color / Revival Mauve / Mauve 5 */

    color: #675c68;
  }

  &.streched-tile {
    font-weight: 700;
    font-size: 24px;

    /* Color / Gray / Gray 10 */

    color: #050505;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  &.alerts {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }
  &.streched-tile {
    flex-direction: column;
  }
`;

export const StyledHR = styled.div`
  border-top: 1px solid #c2c2c2;
  width: 100%;
`;

export const StyledActivityCard = styled(Card)`
  box-sizing: border-box;
  /* Auto layout */

  display: flex;
  padding-inline: 24px;
  padding-top: 12px;
  padding-bottom: 25px;
  gap: 16px;
  justify-content: space-between;

  background: #ffffff;
  /* Color / Gray / Gray 4 */

  border: 1px solid #c2c2c2;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledHorizontalDiv = styled.div`
  display: flex;
`;

export const StyledHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #050505;
  /* identical to box height, or 150% */

  &.alarms {
    /* Color / System / Red / Red 5 */
    color: #ff5757;
  }
  &.warnings {
    /* Color / System / Yellow / Yellow 5 */
    color: #ffae50;
  }
`;
export const StyledText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-inline: 8px;
  line-height: 15px;
  /* identical to box height */

  /* Gray 1 */

  color: #333333;
`;

export const StyledPara = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Gray 2 */

  color: #4f4f4f;
`;

export const StyledDeleteIcon = styled(ICON_DELETE_COMPONENT)`
  margin: 0px;
  width: 18px;
`;
export const StyledCalendarIcon = styled(ICON_CALENDAR_COMPONENT)`
  margin: 0px;
  width: 15px;
`;

export const StyledAlarmIcon = styled(ICON_ALARMS_COMPONENT_GRAY)`
  margin: 0px;
  width: 24px;
`;

export const StyledWarningIcon = styled(ICON_WARNINGS_COMPONENT_GRAY)`
  margin: 0px;
  width: 24px;
`;
