import React from "react";

import { REVIVAL_LOGO_IMG } from "../../assets/images";
import {
  ComponentContainer,
  FormContainer,
  HalfPageContent,
  HalfPageFooter,
  HalfPageHeader,
  HalfPageLayoutContainer,
  LayoutImageContainer,
} from "./styles";

export default function HalfPageLayout({ children, footer }) {
  return (
    <HalfPageLayoutContainer>
      <ComponentContainer>
        <FormContainer>
          <HalfPageHeader>
            <img src={REVIVAL_LOGO_IMG} alt="Revival Logo" loading="eager" />
          </HalfPageHeader>
          <HalfPageContent>{children}</HalfPageContent>
        </FormContainer>
        {footer && <HalfPageFooter>{footer}</HalfPageFooter>}
      </ComponentContainer>
      <LayoutImageContainer />
    </HalfPageLayoutContainer>
  );
}
