import { makeCancellableAPIRequest } from ".";

export function triggerLoginAPI(data) {
  const params = {
    urlPath: "user/validatelogin",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(params);
}

export function triggerValidateLinkAPI(link) {
  const params = {
    urlPath: `user/validatelink/link?link=${link}`,
    options: {},
  };
  return makeCancellableAPIRequest(params);
}

export function triggerResetPasswordAPI(data) {
  const params = {
    urlPath: "user/resetpassword",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(params);
}

export function triggerForgotPasswordAPI(data) {
  const params = {
    urlPath: "user/resendpasswordlink",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(params);
}

export function triggerRefreshTokenAPI(data) {
  const params = {
    urlPath: "user/authenticatebyrefreshtoken",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(params);
}
