import React from "react";

import { LinkIcon, PrimaryLink, SecondaryLink } from "./styles";

export function LinkWithText({ text, to, handleClick, className }) {
  return (
    <SecondaryLink
      to={to ?? "/login"}
      onClick={handleClick}
      className={className}
    >
      {text}
    </SecondaryLink>
  );
}

export function LinkWithIcon({ text, icon, to, handleClick, className }) {
  return (
    <SecondaryLink
      to={to ?? "#"}
      onClick={() => {
        window.location = "mailto:support@revivalanalytics.com";
      }}
      className={className}
    >
      {icon && <LinkIcon src={icon} alt="Image icon" />}
      {text}
    </SecondaryLink>
  );
}

export function LinkTo({
  text,
  to,
  handleClick,
  className = "",
  underLined = false,
  ...rest
}) {
  return (
    <PrimaryLink
      {...rest}
      to={to ?? "#"}
      onClick={handleClick}
      className={`${underLined ? "underlined" : "default"} ${className}`}
    >
      {text}
    </PrimaryLink>
  );
}
