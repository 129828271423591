import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import { API_BASE_URL } from "../../utils/config";
import ChartsView from "../../views/ChartsView";

export default function ChartsContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getWellSiteData({ id });
    }
  }, [id]);

  useEffect(() => {
    if (devices.length > 0) {
      setSelectedDevice(devices[0] || null);
    }
  }, [devices]);

  const handleChangeDevice = ({ id, value }) => {
    setSelectedDevice({
      deviceId: id,
      customName: value,
    });
  };

  const getWellSiteData = async ({ id }) => {
    let accessToken = Cookies.get("ACCESS_TOKEN");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .get(`${API_BASE_URL}/Wellsite/${id}`, { headers })
      .then((res) => {
        setDevices(res?.data?.data?.wellsite?.devices);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return !isLoading ? (
    <ChartsView
      handleChangeDevice={handleChangeDevice}
      selectedDevice={selectedDevice}
      devices={devices}
    />
  ) : (
    <Loader responsive />
  );
}
