import React from "react";
import { useTranslation } from "react-i18next";

import { ICON_EMAIL } from "../../assets/images";
import { ROUTES } from "../../utils/constants";
import { LinkWithIcon, LinkWithText } from "../Links";
import { StyledFooterContainer } from "./styles";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <StyledFooterContainer>
      <LinkWithText text={t("COPYRIGHT_REVIVAL_PORTAL")} />
      <LinkWithIcon text={t("HELP_AT_REVIVAL_DOTCOM")} icon={ICON_EMAIL} />
    </StyledFooterContainer>
  );
}
