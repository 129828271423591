import moment from "moment";

import { makeCancellableAPIRequest } from ".";

export function getWellSiteChartsAPI(id) {
  const apiParams = {
    // urlPath: `Telemetry/deviceid/day?deviceid=${id}&day=11/04/2022`,
    urlPath: `Telemetry/deviceid/day?deviceid=${id}&day=${moment().format(
      "MM/DD/YYYY"
    )}`,
  };
  return makeCancellableAPIRequest(apiParams);
}
