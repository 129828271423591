import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ICON_CLOSE } from "../../assets/images";
import { Button, Input } from "../../components";
import { ChipInput } from "../../components/ChipInput";
import { ScrollDialog } from "../../components/Dialog";
import { DATA_KEY, MAX_LENGTH, ROUTES } from "../../utils/constants";
import { AddUserValidation, formatGroupNames, formatUserRole, formatWellSitesList, getGroupID, getUserRoleID, getWellSiteID } from "./helper";
import { FormFooterContainer, FormHeaderContainer, FormHeading, FormWrapper, StyledImg } from "./styles";

function PopUpForm({ open, setOpen, saveUserDetailsData, fetchedData }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    user_roles: "AccountAdmin",
    assigned_groups: [],
    assigned_well_site: [],
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(AddUserValidation()),
  });

  const isSelectionValid = useWatch({
    control,
    name: "user_roles",
  }).length;

  const handleClose = () => {
    setIsLoading(false);
    reset();
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    setIsLoading(true);
    saveUserDetailsData({
      params: {
        firstName: formValues?.first_name,
        lastName: formValues?.last_name,
        email: formValues?.email,
        mobileNumber: formValues?.phone_number,
        isActive: true,
        roleID: getUserRoleID(
          fetchedData.userRolesList,
          formValues?.user_roles
        ),
        organizationId: 1, //TODO : hardcoded
        isBillingContact: false,
        billingAddress: "",
        password: "TorinitRevivalTest!@#$1234",
        groupIds:
          getGroupID(
            fetchedData.assignedGroupsList,
            formValues?.assigned_groups
          ) || [],
        wellsiteIds:
          getWellSiteID(
            fetchedData.assignedWellSitesList,
            formValues?.assigned_well_site
          ) || [],
      },
      cb: redirectToUserManagement,
    });
  };

  const redirectToUserManagement = () => {
    handleClose();
    window.location = ROUTES.USER_MANAGEMENT;
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>{t("ADD_A_USER")}</FormHeading>
          <StyledImg src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                required
                id="add-user-first-name-input"
                label={t("FIRST_NAME")}
                placeholder={t("FIRST_NAME_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.TEXT,
                  },
                }}
                {...register("first_name")}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                id="add-user-last-name-input"
                label={t("LAST_NAME")}
                placeholder={t("LAST_NAME_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.TEXT,
                  },
                }}
                {...register("last_name")}
                helperText={errors.last_name?.message}
              />
            </Grid>
          </Grid>
          <ChipInput
            required
            id="add-user-user-roles-input"
            data={formatUserRole(fetchedData.userRolesList)}
            dataKey={DATA_KEY.USER_ROLES}
            name="user_roles"
            label={t("USER_ROLE")}
            placeholder={t("USER_ROLE_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <Input
            required
            id="add-user-email-input"
            label={t("EMAIL")}
            placeholder={t("EMAIL_PLACEHOLDER")}
            fullWidth
            extraInputProps={{
              inputProps: {
                autoComplete: "text",
                maxLength: MAX_LENGTH.EMAIL,
              },
            }}
            {...register("email")}
            helperText={errors.email?.message}
          />
          <Input
            required
            id="add-user-phone-number-input"
            label={t("MOBILE_NUMBER")}
            placeholder={t("MOBILE_NUMBER_PLACEHOLDER")}
            fullWidth
            extraInputProps={{
              inputProps: {
                autoComplete: "text",
                maxLength: MAX_LENGTH.PHONE,
              },
            }}
            {...register("phone_number")}
            helperText={errors.phone_number?.message}
          />

          <ChipInput
            id="add-user-assigned-group-input"
            data={formatGroupNames(fetchedData.assignedGroupsList)}
            dataKey={DATA_KEY.GROUP_NAME}
            name="assigned_groups"
            multiple
            defaultValue={["AccountAdmin"]}
            label={t("ASSIGNED_GROUPS")}
            placeholder={t("ASSIGNED_GROUPS_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <ChipInput
            id="add-user-assigned-well-site-input"
            data={formatWellSitesList(fetchedData.assignedWellSitesList)}
            dataKey={DATA_KEY.WELL_SITES}
            name="assigned_well_site"
            multiple
            label={t("ASSIGNED_WELL_SITE")}
            placeholder={t("ASSIGNED_WELL_SITE_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={t("ADD_USER")}
              type="submit"
              disabled={!isValid || isLoading || !isSelectionValid}
            />
            <Button
              className={"form-button outlined-button gray-border"}
              disabled={isLoading}
              title={t("CANCEL")}
              onClick={handleClose}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

export default PopUpForm;
