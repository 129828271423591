import styled from "styled-components";

export const CardContainer = styled.div`
  margin-top: ${(props) => (props.opacity === 0 ? "0px" : "24px")};
  padding: ${(props) => (props.opacity === 0 ? "0px" : "20px")};
  padding-top: 34px;
  background: ${(props) => (props.opacity === 0 ? "#ff000000" : "#ffffff")};
  border-radius: 16px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
