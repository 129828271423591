import { makeCancellableAPIRequest } from ".";

export function triggerAlertListAPI() {
  const apiParams = {
    urlPath: `alert/config`,
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveChangesConfigAPI(data) {
  const apiParams = {
    urlPath: "alert/config/save",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function toggleAlertAPI(data) {
  const apiParams = {
    urlPath: "alert/config/detail/save",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function restoreDefaultsAlertsAPI(tenantID, id) {
  const apiParams = {
    urlPath: `alert/config/reset/${id}?tenantID=${tenantID}`,
    method: "DELETE",
  };
  return makeCancellableAPIRequest(apiParams);
}
