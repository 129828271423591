import styled from "styled-components";

import { ICON_EMAIL_AS_COMPONENT } from "../../assets/images";
import { LinkWithText } from "../../components/Links";

export const StyledHead = styled.h1`
  margin-top: 60px;
  font-size: 36px;
  margin-bottom: 0px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledHead2 = styled.span`
  margin-top: 20px;
  font-size: 22px;
  margin-bottom: 12px;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLinkWithText = styled(LinkWithText)`
  flex-grow: 0;
  font-family: Inter;
  font-size: 15px;
  text-decoration: underline;
  margin-top: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const StyledInputIcon = styled(ICON_EMAIL_AS_COMPONENT)``;

export const FormFoooterContainer = styled.div`
  margin-top: 24px;
`;
