import React, { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import CustomTable from "../CustomTable";
import SearchBar from "../SearchBar";
import { CardContainer, FooterWrapper } from "./styles";

const TableLayout = ({
  searchEnabled = false,
  opacity = 100,
  onChange,
  defaultView = null,
  ...rest
}) => {
  const [searchText, setSearchText] = useState("");

  const debounced = useDebouncedCallback(
    // to memoize debouncedFunction we use useCallback hook.
    // In this case all linters work correctly
    useCallback((value) => {
      setSearchText(value);
    }, []),
    700,
    // The maximum time func is allowed to be delayed before it's invoked.
    { maxWait: 1000 }
  );

  return (
    <>
      <CardContainer opacity={opacity} elevation={0}>
        {searchEnabled && (
          <SearchBar onChange={(e) => debounced(e.target.value)} />
        )}
        {!searchText.length && defaultView ? (
          defaultView
        ) : (
          <CustomTable
            searchEnabled={searchEnabled}
            searchText={searchText}
            {...rest}
          />
        )}
      </CardContainer>
      <FooterWrapper>{/* <div>pagination</div> */}</FooterWrapper>
    </>
  );
};

export default TableLayout;
