import React, { useEffect, useState } from "react";

import Loader from "../../components/Loader";
import { logger } from "../../utils/logger";
import { getMyDashboardDataAPI } from "../../utils/restAPI/DashboardAPI";
import DashboardView from "../../views/DashboardView";

const log = logger("Dashboard");
export default function DashboardContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  useEffect(() => {
    fetchDashboardData();
    setIsLoading(false);
  }, []);

  const fetchDashboardData = () => {
    const { promise, cancel } = getMyDashboardDataAPI();
    promise
      .then((res) => {
        console.log('res', res)
        setDashboardData(res?.data);
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  return !isLoading ? (
    <DashboardView dashboardData={dashboardData} />
  ) : (
    <Loader />
  );
}
