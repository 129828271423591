import { StyledTab, StyledTabs } from "./styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Tabs = ({ index, children, ...rest }) => {
  return (
    <StyledTabs {...a11yProps(index)} {...rest}>
      {children}
    </StyledTabs>
  );
};

export const Tab = ({ children, ...rest }) => {
  return <StyledTab {...rest}>{children}</StyledTab>;
};

export const TabPanel = ({ value, index, children, ...rest }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={
        {
          // display: "flex",
          // flexDirection: "column",
        }
      }
      {...rest}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
