import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import MainLayout from "../../components/MainLayout";
import { isLoggedIn } from "../../utils";
import { FileNotFound } from "../../views/ErrorView";

export default function ErrorContainer() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setLoggedIn(isLoggedIn());
    setIsLoading(false);
  }, []);

  return !isLoading ? (
    loggedIn ? (
      <MainLayout component={FileNotFound} />
    ) : (
      <FileNotFound />
    )
  ) : (
    <Loader />
  );
}
