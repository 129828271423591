import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = ({ relative = false, responsive = false }) => {
  const styles = relative
    ? {
        margin: "50px",
        position: "relative",
      }
    : responsive
    ? {
        position: "absolute",
        top: "calc(50% - 40px)",
        left: "calc(50% - 40px)",
      }
    : {
        position: "absolute",
        top: "calc(50%)",
        left: "calc(50%)",
      };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <TailSpin
        height="80"
        width="80"
        color="#F05423"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={styles}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
