import { TableContainer } from "@mui/material";
import { isEmpty, map } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Loader from "../Loader";
import { HeaderCells, HeaderContainer } from "./styles";
import TableRowData from "./TableRowData";

export default function CustomTable({
  searchEnabled = false,
  searchText = "",
  isLoading = false,
  api,
  relative = false,
  getDataArray,
  formatRowData,
  headCells,
  emptyView = <div></div>,
  onRowClick = () => null,
}) {
  const [loading, setLoading] = useState(isLoading);
  // const [rowsPerPage, setRowsPerPage] = useState(15);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [order, setOrder] = useState(defaultSortOrder);
  // const [orderBy, setOrderBy] = useState(defaultSortColumn);
  // const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const isMountedRef = useRef(false);
  // const [searchText, setSearchText] = useState("");

  const fetchRowsData = useCallback(() => {
    setLoading(true);
    // let data = { page: page };
    // if (sort_column) {
    //   data["sort_column"] = sort_column;
    //   data["sort_type"] = sort_type;
    // } TODO: Pagination

    const data = {
      // searchText: "",
      // startRowIndex: 0,
      // pageSize: 0,
      search: searchEnabled ? searchText : "",
    };

    const { promise, cancel } = api(data);
    promise
      .then((res) => {
        if (isMountedRef.current === null) return;
        setLoading(false);
        const fetchedData = getDataArray(res?.data);

        if (!isEmpty(fetchedData)) {
          // setPage(fetchedData.current_page);
          // setRowsPerPage(fetchedData.per_page);
          // setTotalRecords(fetchedData.total); // Pagination
          const data = fetchedData;

          const rowsData = map(data, (row) => formatRowData(row));
          setRows(rowsData);
        } else {
          setRows([]);
        }
      })
      .catch(() => {
        // setLoading(false);
      });
    return cancel;
  }, [formatRowData, api, searchText, getDataArray, searchEnabled]);

  useEffect(() => {
    fetchRowsData();
    return () => {
      setRows([]);
    };
  }, [fetchRowsData]);

  if (!loading) {
    return !!rows.length ? (
      <TableContainer>
        <HeaderContainer repeat={headCells.length}>
          {headCells?.map((data) => {
            return <HeaderCells key={data.id}>{data.title}</HeaderCells>;
          })}
        </HeaderContainer>
        <TableRowData
          rows={rows}
          headCells={headCells}
          onRowClick={onRowClick}
          // order={order}
        />
      </TableContainer>
    ) : (
      emptyView
    );
  } else {
    return <Loader relative={relative} />;
  }
}
