import React from "react";
import { useTranslation } from "react-i18next";

import { ICON_PLUS_WHITE_COMPONENT, NO_CONTENT_IMG } from "../../assets/images";
import { Button, Image } from "../../components";
import {
  BlankPageContainer,
  ButtonsContainer,
  CardContainer,
  StyledHeading1,
  StyledHeading2,
} from "./styles";

export default function NoDataView({ handleAddUser }) {
  const { t } = useTranslation();
  return (
    <CardContainer elevation={0}>
      <BlankPageContainer>
        <Image src={NO_CONTENT_IMG} alt="no content image" />
        <StyledHeading1>{t("NO_USERS_HAVE_BEEN_ADDED")}</StyledHeading1>
        <StyledHeading2>
          {t("ONCE_USERS_HAVE_BEEN_ADDED_YOU_WILL_SEE_THEM_HERE")}
        </StyledHeading2>
        <ButtonsContainer>
          <Button
            className={"form-button"}
            title={t("ADD_A_USER")}
            startIcon={<ICON_PLUS_WHITE_COMPONENT />}
            onClick={handleAddUser}
          />
        </ButtonsContainer>
      </BlankPageContainer>
    </CardContainer>
  );
}
