export const getDerivedData = (data) => {
  return data?.alertList?.alertconfigs;
};

export const getDataArrays = (data) => {
  const alarmsArray = data?.alerts?.filter(
    (alert) => alert?.alertConfigurationTypeId === 1
  );
  const warningsArray = data?.alerts?.filter(
    (alert) => alert?.alertConfigurationTypeId === 2
  );

  return { alarmsArray, warningsArray };
};

export const isLeak = (type) => {
  return type === "Leak";
};
