import React from "react";

import { Button } from "..";
import { ICON_DELETE } from "../../assets/images";
import {
  ButtonsContainer,
  ContentWrapper,
  StyledContainer,
  StyledDialog,
  StyledHeading1,
  StyledPara,
} from "./styles";

export const ConfirmDeleteDialog = ({
  open,
  setOpen,
  handleDelete,
  ...rest
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <StyledDialog open={open} {...rest}>
      <StyledContainer>
        <img src={ICON_DELETE} alt="delete icon" loading="eager" />

        <ContentWrapper>
          <StyledHeading1>Are you sure you want to delete this?</StyledHeading1>
          <StyledPara>
            Once you proceed, this will be permanently deleted. This action
            cannot be reversed afterwards.
          </StyledPara>
          <ButtonsContainer>
            <Button
              className={"form-button outlined-button"}
              title={"CANCEL"}
              onClick={handleClose}
            />
            <Button
              className={"form-button"}
              title={"DELETE"}
              onClick={handleDelete || null}
            />
          </ButtonsContainer>
        </ContentWrapper>
      </StyledContainer>
    </StyledDialog>
  );
};
