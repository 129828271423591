import styled from "styled-components";

export const StyledContainer = styled.div`
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  display: flex;
  background: #f9f9f9;
  flex-grow: 1;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 108px;
  /* or 169% */

  text-transform: capitalize;

  /* Color / Revival Mauve / Mauve 10 */

  color: #0a090a;
`;

export const StyledSubheading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 108px;
  /* or 360% */

  text-transform: capitalize;

  /* Color / Gray / Gray 6 */

  color: #707070;
`;
