import i18n from "i18next";
import _ from "lodash";
import * as Yup from "yup";

import { BADGE_TYPES, DATA_KEY } from "../../utils/constants";

export const AssignDeviceValidation = () => {
  const t = i18n.t;

  return Yup.object().shape({
    device_name: Yup.string().required(t("ERROR_FIELD_REQUIRED")),
    billing_start_date: Yup.date().required(
      t("ERROR_BILLING_START_DATE_REQUIRED")
    ),
  });
};
export const ReturnDeviceValidation = () => {
  const t = i18n.t;

  return Yup.object().shape({
    device_name: Yup.string().required(t("ERROR_FIELD_REQUIRED")),
  });
};

export const headCells = [
  { id: 1, name: "device_id", title: "Device ID" },
  { id: 2, name: "device_type", title: "Device Type" },
  { id: 3, name: "device_status", title: "Device Status" },
];

export const formatRowData = ({
  deviceId,
  deviceName,
  billingStartDate,
  type,
  status,
  wellsiteId,
}) => {
  return {
    device_id: deviceName,
    device_type: { data: type, style: getBadgeType() },
    device_status: { data: status, style: getBadgeType(status) },
  };
};

export const getDeviceID = (devices, selectedDevice) => {
  const data = devices?.devices?.filter(
    (device) => device[DATA_KEY.DEVICE_NAME] === selectedDevice
  );
  return data[0]?.deviceId;
};

export const getBadgeType = (type = null) => {
  switch (type) {
    case BADGE_TYPES.ACTIVE:
      return {
        color: "#1AC12B",
        background: "#E5FCE8",
        fontWeight: 600,
      };
    case BADGE_TYPES.ASSIGNED:
      return {
        color: "#1890FF",
        background: "#E6F7FF",
        fontWeight: 600,
      };
    case BADGE_TYPES.DEPLOYED:
      return {
        color: "#FFAE50",
        background: "#FFF7EE",
        fontWeight: 600,
      };
    case BADGE_TYPES.FACTORY_DEFAULT:
      return {
        color: "#262626",
        background: "#F5F5F5",
        fontWeight: 600,
      };
    case BADGE_TYPES.RETURNED:
      return {
        color: "#F5222D",
        background: "#FFF1F0",
        fontWeight: 600,
      };
    default:
      return {
        color: "#667085",
        fontWeight: 500,
        fontSize: "14px",
        padding: "0px",
      };
  }
};

export const getDataArray = (data) => {
  return data?.devices;
};

export const formatDeviceList = (data) => {
  return data?.devices;
};

export const cleanObject = (object) => {
  return _.omitBy(object, _.isNil);
};

export const cleanArray = (array) => {
  return _.compact(array);
};
