import React from "react";
import { StyledDiv, StyledSpan } from "./styles.js";

const ImageCircle = ({ title = "Lorem Ipsum", ...rest }) => {
  const [first, last] = title.toUpperCase().split(" ");

  return (
    <StyledDiv {...rest}>
      <StyledSpan {...rest}>{`${first[0]}${last[0]}`}</StyledSpan>
    </StyledDiv>
  );
};

export default ImageCircle;
