import React from "react";
import { toast } from "material-react-toastify";
import { node, oneOf } from "prop-types";
import "material-react-toastify/dist/ReactToastify.css";

import {
  ICON_TOAST_ERROR_COMPONENT,
  ICON_TOAST_SUCCESS_COMPONENT,
} from "../../assets/images";
import { TOAST_DURATION, TOAST_TYPES } from "../constants";

const customToast = (
  message,
  toastType = TOAST_TYPES.DEFAULT,
  callback,
  autoClose = TOAST_DURATION.MEDIUM,
  position = toast.POSITION.BOTTOM_CENTER
) => {
  const getIcon = () => {
    switch (toastType) {
      case TOAST_TYPES.SUCCESS:
        return <ICON_TOAST_SUCCESS_COMPONENT />;
      case TOAST_TYPES.ERROR:
        return <ICON_TOAST_ERROR_COMPONENT />;
      default:
        return null;
    }
  };

  toast.configure({
    draggable: false,
    position,
    autoClose,
  });

  return toast(
    <div className="toastify-container">
      {getIcon()}
      {message}
    </div>,
    {
      type: toast.TYPE[toastType.toUpperCase()],
      onClose: () => callback && callback(),
      className: "toastify-container",
    }
  );
};
customToast.propTypes = {
  message: node.isRequired,
  toastType: oneOf(["info", "success", "warning", "error", "default"]),
  position: oneOf([
    "top-right",
    "top-center",
    "top-left",
    "bottom-right",
    "bottom-center",
    "bottom-left",
  ]),
};

export default customToast;
