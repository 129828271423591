import React, { createContext, useContext, useEffect, useReducer } from "react";

import { isLoggedIn } from "..";
import { getAccountName, setAccountName } from "../common";
import { ACCOUNT_TYPES } from "../constants";
import { triggerGetAccountNamesAPI } from "../restAPI/AdminAPI";
import { useActiveUserContext } from "./ActiveUserContext";

const ActiveClientContext = createContext({});

const initialState = {};

const Actions = {
  SET_SELECTED_ACCOUNT: "SET_SELECTED_ACCOUNT",
  SET_ACCOUNT_NAMES: "SET_ACCOUNT_NAMES",
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case Actions.SET_ACCOUNT_NAMES:
      return { ...state, accountNames: payload };
    case Actions.SET_SELECTED_ACCOUNT:
      setAccountName(payload);
      return { ...state, selectedAccount: payload };
    default:
      return state;
  }
};

function ActiveClientContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedAccount, accountNames } = state;

  const { userData, accountDetails } = useActiveUserContext();

  useEffect(() => {
    isLoggedIn() && triggerGetAccountNames();
  }, [userData]);

  useEffect(() => {
    if (accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN) {
      const selectedAccountDetails = getAccountName();
      accountNames &&
        dispatch({
          type: Actions.SET_SELECTED_ACCOUNT,
          payload: selectedAccountDetails
            ? selectedAccountDetails
            : accountNames[0],
        });
    }
  }, [accountNames, accountDetails]);

  const triggerGetAccountNames = () => {
    const { promise, cancel } = triggerGetAccountNamesAPI();
    promise
      .then((res) => {
        dispatch({
          type: Actions.SET_ACCOUNT_NAMES,
          payload: res?.data?.rows,
        });
      })
      .catch((e) => {
        console.error(e);
      });

    return cancel;
  };

  const handleChangeAccountName = ({ tenantId, organizationName }) => {
    dispatch({
      type: Actions.SET_SELECTED_ACCOUNT,
      payload: {
        organizationName: organizationName,
        tenantId: tenantId,
      },
    });
    window.location.reload();
  };

  return (
    <ActiveClientContext.Provider
      value={{
        selectedAccount,
        accountNames,
        handleChangeAccountName,
      }}
    >
      {children}
    </ActiveClientContext.Provider>
  );
}

export default ActiveClientContextProvider;

export function withActiveClientContext(Component) {
  return (props) => (
    <ActiveClientContext.Consumer>
      {(context) => <Component activeUserContext={context} {...props} />}
    </ActiveClientContext.Consumer>
  );
}

export function useActiveClientContext() {
  return useContext(ActiveClientContext);
}
