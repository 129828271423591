import { Box, styled } from "@mui/material";

import { LAYOUT_CONSTANTS } from "../../assets/css/theme";

export const ContainerWrapper = styled(Box)(({ topScroll, isDesktop }) => ({
  minHeight: "100vh",
  display: "block",

  ".MuiDrawer-paper": {
    maxWidth: isDesktop ? LAYOUT_CONSTANTS.SIDEBAR_WIDTH : "100%",
    transform: `translateY(${LAYOUT_CONSTANTS.TOP_SPACE})`,
    marginTop: `calc(16px - ${topScroll}px)`,
    width: "100%",
    border: 0,
  },
}));

export const MainWrapper = styled(Box)(() => ({
  minHeight: `calc(100vh - ${LAYOUT_CONSTANTS.TOP_SPACE})`,
  transition: LAYOUT_CONSTANTS.TRANSITION,
  display: "flex",
  width: "100%",
}));

export const MainContainer = styled(Box)(({ theme, isSideDrawerOpen }) => ({
  width: "100%",
}));
