import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ICON_PLUS_WHITE_COMPONENT } from "../../assets/images";
import { Button } from "../../components";
import TableLayout from "../../components/TableLayout";
import { formatRowData, getDataArray, headCells } from "./helper";
import NoDataView from "./NoDataView";
import PopUpForm from "./PopUpForm";
import {
  ButtonsContainer,
  ContainerWrapper,
  PageHeading,
  TopContainer,
} from "./styles";

export default function UserManagementView({
  isEmpty,
  api,
  onRowClick,
  ...rest
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleAddUser = () => {
    setOpen(true);
  };

  return (
    <>
      <PopUpForm open={open} setOpen={setOpen} {...rest} />
      <ContainerWrapper>
        <TopContainer>
          <PageHeading>{t("USER_MANAGEMENT")}</PageHeading>
          <ButtonsContainer>
            <Button
              className={"header-button"}
              title={t("ADD_A_USER")}
              startIcon={<ICON_PLUS_WHITE_COMPONENT />}
              onClick={handleAddUser}
            />
          </ButtonsContainer>
        </TopContainer>

        <TableLayout
          api={api}
          headCells={headCells}
          formatRowData={formatRowData}
          onRowClick={onRowClick}
          getDataArray={getDataArray}
          emptyView={<NoDataView handleAddUser={handleAddUser} />}
        />
      </ContainerWrapper>
    </>
  );
}

