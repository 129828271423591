import { makeCancellableAPIRequest } from ".";

export function getMyDashboardDataAPI(params) {
  const apiParams = {
    urlPath: "dashboard",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
