import React, { forwardRef, useState } from "react";

import Input from "../Input";
import { StyledLockIcon, VisibilityOff, VisibilityOn } from "./styles";

const InputPassword = forwardRef(({ ...rest }, ref) => {
  const [visibility, setVisibility] = useState(false);

  const showHidePassword = () => {
    setVisibility(!visibility);
  };

  return (
    <Input
      ref={ref}
      type={visibility ? "text" : "password"}
      // name="password"
      extraInputProps={{
        inputProps: {},
      }}
      // helperText={"Incorrect Password"}
      startAdornment={<StyledLockIcon />}
      endAdornment={
        <>
          {visibility ? (
            <VisibilityOn onClick={showHidePassword} />
          ) : (
            <VisibilityOff onClick={showHidePassword} />
          )}
        </>
      }
      {...rest}
    />
  );
});

export default InputPassword;
