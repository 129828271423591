import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "../../components/Button";
import Footer from "../../components/Footer";
import HalfPageLayout from "../../components/HalfPageLayout";
import Input from "../../components/Input";
import { checkEmailRegex } from "../../utils";
import { MAX_LENGTH } from "../../utils/constants";
import {
  FormFoooterContainer,
  StyledForm,
  StyledHead,
  StyledHead2,
  StyledInputIcon,
  StyledLinkWithText,
} from "./styles";

export default function ForgotPasswordView({
  params,
  isLoading,
  handleSendLink,
  handleRedirectToLogin,
}) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    !params?.resetSuccessfully && setFocus("email");
  }, [setFocus, params]);

  const onSubmit = (data) => {
    handleSendLink(data);
  };

  const renderFooter = () => <Footer />;

  return (
    <HalfPageLayout footer={renderFooter()}>
      {params?.resetSuccessfully ? (
        <StyledForm>
          <StyledHead>{t("PASSWORD_RESET")}</StyledHead>
          <StyledHead2>{t("PASSWORD_HAS_BEEN_SUCCESSFULLY_RESET")}</StyledHead2>
          <Button
            title={t("BACK_TO_LOGIN")}
            type="button"
            onClick={handleRedirectToLogin}
          />
        </StyledForm>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledHead>
            {params?.resendLink ? t("LINK_EXPIRED") : t("FORGOT_PASSWORD")}
          </StyledHead>
          <StyledHead2>
            {params?.resendLink
              ? t("PASSWORD_RESET_LINKS_EXPIRE_AFTER_15_MINUTES")
              : t("PLEASE_ENTER_YOUR_EMAIL")}
          </StyledHead2>
          <Input
            id="forgot-password-company-email-input"
            label={t("COMPANY_EMAIL")}
            placeholder={t("EMAIL_PLACEHOLDER")}
            startAdornment={<StyledInputIcon />}
            extraInputProps={{
              inputProps: {
                autoComplete: "off",
                maxLength: MAX_LENGTH.EMAIL,
              },
            }}
            {...register("email", {
              required: t("ERROR_EMAIL_REQUIRED"),
              pattern: {
                value: checkEmailRegex(),
                message: t("ERROR_INVALID_EMAIL"),
              },
            })}
            helperText={errors.email?.message}
          />
          {!params?.resendLink && (
            <StyledLinkWithText text={t("BACK_TO_LOGIN")} to="/login" />
          )}
          <FormFoooterContainer>
            <Button
              title={
                params?.resendLink ? t("RESEND_LINK") : t("SEND_RECOVERY_LINK")
              }
              fullWidth
              type="submit"
              disabled={!isValid || isLoading}
            />
          </FormFoooterContainer>
        </StyledForm>
      )}
    </HalfPageLayout>
  );
}
