import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  ICON_BACK,
  ICON_LOCK_COMPONENT,
  ICON_SAVE_COMPONENT,
} from "../../assets/images";
import { Button, Input } from "../../components";
import { Tab, TabPanel, Tabs } from "../../components/CustomTabs";
import ImageCircle from "../../components/ImageCircle";
import InputPassword from "../../components/InputPassword";
import { logout } from "../../utils/common";
import { MAX_LENGTH, ROUTES } from "../../utils/constants";
import { FormFoooterContainer } from "../ResetPasswordView/styles";
import { AccountDetailsValidation, ResetPasswordValidation } from "./helper";
import {
  CardContainer,
  ContainerWrapper,
  FormWrapper,
  PageHeading,
  StyledForm,
  StyledIcon,
  StyledSpan,
  TopContainer,
} from "./styles";

export default function SettingsView({ ...rest }) {
  const { t } = useTranslation();

  const [tabvalue, setTabValue] = useState(0);
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <>
      <ContainerWrapper>
        <div>
          <StyledSpan onClick={handleBack}>
            <StyledIcon src={ICON_BACK} alt="back icon" loading="eager" />
            {"Back"}
          </StyledSpan>
        </div>
        <TopContainer>
          <PageHeading>{t("SETTINGS")}</PageHeading>
        </TopContainer>
        <CardContainer elevation={0}>
          <FormWrapper className={"fullwidth-container"}>
            <Tabs value={tabvalue} onChange={handleChange}>
              <Tab label={t("GENERAL")} index={0} />
              <Tab label={t("RESET_PASSWORD")} index={1} />
            </Tabs>
            <TabPanel value={tabvalue} index={0}>
              <GeneralView {...rest} />
            </TabPanel>

            <TabPanel value={tabvalue} index={1}>
              <ResetPasswordView {...rest} />
            </TabPanel>
          </FormWrapper>
        </CardContainer>
      </ContainerWrapper>
    </>
  );
}

function GeneralView({ accountDetails, saveAccountDetailsData }) {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const defaultValues = { ...accountDetails };

  const { firstName, lastName } = accountDetails;

  const formSchema = AccountDetailsValidation();

  const {
    register,
    handleSubmit,

    formState: { errors ,isValid},
  } = useForm({ defaultValues, resolver: yupResolver(formSchema), mode : 'onChange' });

  const onSubmit = (formValues) => {
    setLoading(true);
    saveAccountDetailsData({
      params: {
        firstName: formValues?.firstName,
        lastName: formValues?.lastName,
        mobileNumber: formValues?.phoneNumber,
      },
      cb: () => setLoading(false),
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <ImageCircle title={`${firstName} ${lastName}`} />
      <Input
        id="add-user-first-name-input"
        label={t("FIRST_NAME")}
        placeholder={t("FIRST_NAME_PLACEHOLDER")}
        fullWidth
        extraInputProps={{
          inputProps: {
            autoComplete: "text",
            maxLength: MAX_LENGTH.TEXT,
          },
        }}
        {...register("firstName")}
        helperText={errors.firstName?.message}
      />
      <Input
        id="add-user-last-name-input"
        label={t("LAST_NAME")}
        placeholder={t("LAST_NAME_PLACEHOLDER")}
        fullWidth
        extraInputProps={{
          inputProps: {
            autoComplete: "text",
            maxLength: MAX_LENGTH.TEXT,
          },
        }}
        {...register("lastName")}
        helperText={errors.lastName?.message}
      />
      <Input
        id="add-user-phone-number-input"
        label={t("PHONE_NUMBER")}
        placeholder={t("PHONE_NUMBER_PLACEHOLDER")}
        fullWidth
        extraInputProps={{
          inputProps: {
            autoComplete: "text",
            maxLength: MAX_LENGTH.PHONE,
          },
        }}
        {...register("phoneNumber")}
        helperText={errors.phoneNumber?.message}
      />

      <FormFoooterContainer>
        <Button
          className={"form-button"}
          title={t("SAVE_CHANGES")}
          endIcon={<ICON_SAVE_COMPONENT />}
          type="submit"
          disabled={loading || !isValid}
        />
      </FormFoooterContainer>
    </StyledForm>
  );
}

function ResetPasswordView({ resetAccountPasswordData }) {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const formSchema = ResetPasswordValidation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(formSchema), mode: "onChange" });

  useEffect(() => {
    setFocus("old_password");
  }, [setFocus]);

  const onSubmit = (formValues) => {
    setLoading(true)
    resetAccountPasswordData({
      params: {
        oldPassword: formValues?.old_password,
        newPassword: formValues?.confirm_password,
      },
      cb: handleResetPassword,
    });
  };

  const handleResetPassword = () => {
    logout();
    history.push(ROUTES.LOGIN);
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <InputPassword
        id="reset-password-old-password-input"
        label={t("OLD_PASSWORD")}
        placeholder={t("OLD_PASSWORD_PLACEHOLDER")}
        InputLabelProps={{
          shrink: false,
        }}
        extraInputProps={{
          inputProps: {
            autoComplete: "off",
            maxLength: MAX_LENGTH.PASSWORD,
          },
        }}
        {...register("old_password")}
        helperText={errors.old_password?.message}
      />
      <InputPassword
        id="reset-password-new-password-input"
        label={t("NEW_PASSWORD")}
        placeholder={t("NEW_PASSWORD_PLACEHOLDER")}
        InputLabelProps={{
          shrink: false,
        }}
        extraInputProps={{
          inputProps: {
            autoComplete: "off",
            maxLength: MAX_LENGTH.PASSWORD,
          },
        }}
        {...register("new_password")}
        helperText={errors.new_password?.message}
      />
      <InputPassword
        id="reset-password-confirm-password-input"
        label={t("CONFIRM_NEW_PASSWORD")}
        placeholder={t("CONFIRM_NEW_PASSWORD_PLACEHOLDER")}
        InputLabelProps={{
          shrink: false,
        }}
        extraInputProps={{
          inputProps: {
            autoComplete: "off",
            maxLength: MAX_LENGTH.PASSWORD,
          },
        }}
        {...register("confirm_password")}
        helperText={errors.confirm_password?.message}
      />

      <FormFoooterContainer>
        <Button
          className={"form-button"}
          title={t("RESET_PASSWORD")}
          endIcon={<ICON_LOCK_COMPONENT />}
          type="submit"
          disabled={!isValid || loading}
        />
      </FormFoooterContainer>
    </StyledForm>
  );
}
