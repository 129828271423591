import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { ROUTES } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import LoginView from "../../views/LoginView";

export default function LoginContainer() {
  const { triggerLogin } = useActiveUserContext();
  const history = useHistory();

  const fetchAPIData = (params) => {
    const data = {
      params,
      cb: reDirecToDashboard,
    };
    triggerLogin(data);
  };

  const reDirecToDashboard = () => {
    history.push(ROUTES.DASHBOARD);
  };

  return <LoginView fetchAPIData={fetchAPIData} />;
}
