import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { VECTOR_FILE_NOT_FOUND } from "../../assets/images";
import { Button } from "../../components";
import {
  StyledColumn,
  StyledContainer,
  StyledHeading,
  StyledSubheading,
} from "./styles";

const FileNotFound = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const handleReturnToPreviousPage = () => {
    history.goBack();
  };

  return (
    <StyledContainer>
      <StyledColumn>
        <StyledHeading>Ooops....</StyledHeading>
        <StyledSubheading>{"Page Not Found"}</StyledSubheading>
        <Button
          className={"header-button"}
          title={t("RETURN_TO_PREVIOUS_PAGE")}
          onClick={handleReturnToPreviousPage}
        />
      </StyledColumn>
      <img src={VECTOR_FILE_NOT_FOUND} alt="Vector" />
    </StyledContainer>
  );
};

export default FileNotFound;
