import i18n from "i18next";
import * as Yup from "yup";
import { checkPasswordRegex, checkPhoneRegex } from "../../utils";

export const AccountDetailsValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    firstName: Yup.string().required(t("ERROR_FIRST_NAME_REQUIRED")),
    lastName: Yup.string().required(t("ERROR_LAST_NAME_REQUIRED")),
    phoneNumber: Yup.string()
      .required(t("ERROR_MOBILE_NUMBER_REQUIRED"))
      .matches(checkPhoneRegex(), t("ERROR_INVALID_MOBILE_NUMBER")),
  });
};

export const ResetPasswordValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    old_password: Yup.string().required(t("ERROR_PASSWORD_REQUIRED")),
    new_password: Yup.string()
      .required(t("ERROR_PASSWORD_REQUIRED"))
      .matches(checkPasswordRegex(), t("ERROR_INVALID_PASSWORD")),
    confirm_password: Yup.string()
      .required(t("ERROR_PASSWORD_REQUIRED"))
      .when("new_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          t("ERROR_PASSWORD_DO_NOT_MATCH")
        ),
      }),
  });
};

export const getDerivedData = (data) => {
  return data?.alertList?.alertconfigs;
};

export const getDataArrays = (data) => {
  const alarmsArray = data?.alerts?.filter(
    (alert) => alert?.alertConfigurationTypeId === 1
  );
  const warningsArray = data?.alerts?.filter(
    (alert) => alert?.alertConfigurationTypeId === 2
  );

  return { alarmsArray, warningsArray };
};
