import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { Controller } from "react-hook-form";

import Input from "../Input";
import { StyledSpan } from "./styles";

const DatePickerInputController = ({
  label = "",
  control,
  name,
  required = false,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, ...props }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledSpan className={`${required ? "required" : null}`}>
              {label}
            </StyledSpan>
            <DatePicker
              closeOnSelect
              value={field.value}
              toolbarPlaceholder={"MM/DD/YYYY"}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              renderInput={(params) => (
                <Input
                  className={"undecorated-input"}
                  {...params}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

const DatePickerInputField = ({
  label = "",
  required = false,
  value,
  setValue,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledSpan className={`${required ? "required" : null}`}>
        {label}
      </StyledSpan>
      <DatePicker
        value={value}
        toolbarPlaceholder={"MM/DD/YYYY"}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <Input {...params} />}
      />
    </LocalizationProvider>
  );
};

export { DatePickerInputController, DatePickerInputField };
