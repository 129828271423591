import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ICON_PLUS_WHITE_COMPONENT } from "../../assets/images";
import { Button } from "../../components";
import TableLayout from "../../components/TableLayout";
import {
  cleanArray,
  cleanObject,
  formatRowData,
  getDataArray,
  headCells,
} from "./helper";
import NoDataView from "./NoDataView";
import { AddOrganizationPopUpForm, BillingContactPopUpForm } from "./PopUpForm";
import {
  ButtonsContainer,
  ContainerWrapper,
  PageHeading,
  TopContainer,
} from "./styles";

export default function OrganizationManagementView({
  isEmpty,
  saveOrganizationDetailsData,
  api,
  ...rest
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  const [openAddOrganizationPopUp, setOpenAddOrganizationPopUp] =
    useState(false);
  const [openBillingContactPopUp, setOpenBillingContactPopUp] = useState(false);

  const handleAddOrganization = () => {
    setOpenAddOrganizationPopUp(true);
  };

  const handleNext = (data) => {
    setFormData(data);
    setOpenAddOrganizationPopUp(false);
    setOpenBillingContactPopUp(true);
  };

  const handleSubmit = async ({ params, cb }) => {
    saveOrganizationDetailsData({
      params: cleanObject({
        organizationName: formData?.organization_name,
        users: cleanArray([
          {
            firstName: formData?.first_name,
            lastName: formData?.last_name,
            email: formData?.work_email,
            mobileNumber: formData?.phone_number,
            isActive: true,
            roleID: 2,
            isBillingContact: params?.adminAddressChecked,
            billingAddress: params?.adminAddressChecked
              ? params?.billing_address
              : "",
          },
          !params?.adminAddressChecked
            ? {
                firstName: params?.first_name,
                lastName: params?.last_name,
                email: params?.work_email,
                mobileNumber: params?.phone_number,
                isActive: true,
                roleID: 4,
                isBillingContact: true,
                billingAddress: params?.billing_address,
              }
            : null,
        ]),
      }),
      cb,
    });
    return;
  };

  return (
    <>
      <AddOrganizationPopUpForm
        open={openAddOrganizationPopUp}
        setOpen={setOpenAddOrganizationPopUp}
        handleNext={handleNext}
        {...rest}
      />
      <BillingContactPopUpForm
        open={openBillingContactPopUp}
        setOpen={setOpenBillingContactPopUp}
        handleFormSubmit={handleSubmit}
        {...rest}
      />
      <ContainerWrapper>
        <TopContainer>
          <PageHeading>{t("ORGANIZATION_MANAGEMENT")}</PageHeading>
          <ButtonsContainer>
            <Button
              className={"header-button"}
              title={t("ADD_AN_ORGANIZATION")}
              startIcon={<ICON_PLUS_WHITE_COMPONENT />}
              onClick={handleAddOrganization}
            />
          </ButtonsContainer>
        </TopContainer>

        <TableLayout
          api={api}
          formatRowData={formatRowData}
          headCells={headCells}
          getDataArray={getDataArray}
          emptyView={
            <NoDataView handleAddOrganization={handleAddOrganization} />
          }
        />
      </ContainerWrapper>
    </>
  );
}
