import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ICON_CLOSE } from "../../assets/images";
import { Button, Input } from "../../components";
import { ChipInput } from "../../components/ChipInput";
import { ScrollDialog } from "../../components/Dialog";
import LocationSearch from "../../components/LocationSearch";
import { DATA_KEY, MAX_LENGTH, ROUTES } from "../../utils/constants";
import { AddOrganizationValidation, BillingContactValidation, formatUserRole } from "./helper";
import { FormFooterContainer, FormHeaderContainer, FormHeading, FormWrapper, StyledCheckBox, StyledFormControlLabel, StyledImg } from "./styles";

function AddOrganizationPopUpForm({ open, setOpen, handleNext, fetchedData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    user_roles: "Account Admin",
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(AddOrganizationValidation()),
  });

  const isSelectionValid = useWatch({
    control,
    name: "user_roles",
  });

  const handleClose = () => {
    setIsLoading(false);
    reset();
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    setIsLoading(false);
    reset();
    handleNext(formValues);
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>{t("ADD_AN_ORGANIZATION")}</FormHeading>
          <img src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            required
            id="add-organization-organization-name-input"
            label={t("ORGANIZATION_NAME")}
            placeholder={t("ORGANIZATION_NAME_PLACEHOLDER")}
            extraInputProps={{
              inputProps: {
                autoComplete: "text",
                maxLength: MAX_LENGTH.LONG_TEXT,
              },
            }}
            {...register("organization_name")}
            helperText={errors.organization_name?.message}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                required
                id="add-organization-first-name-input"
                label={t("FIRST_NAME")}
                placeholder={t("FIRST_NAME_PLACEHOLDER")}
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.LONG_TEXT,
                  },
                }}
                {...register("first_name")}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                id="add-organization-last-name-input"
                label={t("LAST_NAME")}
                placeholder={t("LAST_NAME_PLACEHOLDER")}
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.LONG_TEXT,
                  },
                }}
                {...register("last_name")}
                helperText={errors.last_name?.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                required
                label={t("MOBILE_NUMBER")}
                placeholder={t("MOBILE_NUMBER_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.PHONE,
                  },
                }}
                {...register("phone_number")}
                helperText={errors.phone_number?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                label={t("WORK_EMAIL")}
                placeholder={t("WORK_EMAIL_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.EMAIL,
                  },
                }}
                {...register("work_email")}
                helperText={errors.work_email?.message}
              />
            </Grid>
          </Grid>

          <ChipInput
            required
            data={formatUserRole(fetchedData.userRolesList)}
            dataKey={DATA_KEY.USER_ROLES}
            name="user_roles"
            label={t("USER_ROLE")}
            disabled
            defaultValue={"AccountAdmin"}
            placeholder={t("USER_ROLE_PLACEHOLDER")}
            control={control}
            setValue={setValue}
          />

          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={t("NEXT")}
              type="submit"
              disabled={!isValid || !isSelectionValid || isLoading}
            />
            <Button
              className={"form-button outlined-button gray-border"}
              title={t("CANCEL")}
              onClick={handleClose}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

function BillingContactPopUpForm({ open, setOpen, handleFormSubmit }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [adminAddressChecked, setAdminAddressChecked] = useState(false);

  const defaultValues = {};

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",

    resolver: yupResolver(BillingContactValidation(adminAddressChecked)),
  });

  const handleClose = () => {
    setIsLoading(false);
    reset();
    setOpen(false);
  };

  const onSubmit = (formValues) => {
    setIsLoading(true);

    handleFormSubmit({
      params: { ...formValues, adminAddressChecked },
      cb: redirectToOrganizationManagement,
    });
  };

  const redirectToOrganizationManagement = () => {
    handleClose();
    window.location = ROUTES.ORGANIZATION_MANAGEMENT;
    setIsLoading(false);
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>{t("BILLING_CONTACT")}</FormHeading>
          <StyledImg src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                checked={adminAddressChecked}
                onChange={() => setAdminAddressChecked(!adminAddressChecked)}
              />
            }
            label={t("USE_ACCOUNT_ADMIN_AS_BILLING_CONTACT")}
          />

          {!adminAddressChecked && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    required={!adminAddressChecked}
                    id="billing-contact-first-name-input"
                    label={t("FIRST_NAME")}
                    placeholder={t("FIRST_NAME_PLACEHOLDER")}
                    fullWidth
                    extraInputProps={{
                      inputProps: {
                        autoComplete: "text",
                        maxLength: MAX_LENGTH.LONG_TEXT,
                      },
                    }}
                    {...register("first_name")}
                    helperText={errors.first_name?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    required={!adminAddressChecked}
                    id="billing-contact-last-name-input"
                    label={t("LAST_NAME")}
                    placeholder={t("LAST_NAME_PLACEHOLDER")}
                    fullWidth
                    extraInputProps={{
                      inputProps: {
                        autoComplete: "text",
                        maxLength: MAX_LENGTH.LONG_TEXT,
                      },
                    }}
                    {...register("last_name")}
                    helperText={errors.last_name?.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    required={!adminAddressChecked}
                    id="billing-contact-phone-number-input"
                    label={t("MOBILE_NUMBER")}
                    placeholder={t("MOBILE_NUMBER_PLACEHOLDER")}
                    fullWidth
                    extraInputProps={{
                      inputProps: {
                        autoComplete: "text",
                        maxLength: MAX_LENGTH.PHONE,
                      },
                    }}
                    {...register("phone_number")}
                    helperText={errors.phone_number?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    required={!adminAddressChecked}
                    id="billing-contact-work-email-input"
                    label={t("WORK_EMAIL")}
                    placeholder={t("WORK_EMAIL_PLACEHOLDER")}
                    fullWidth
                    extraInputProps={{
                      inputProps: {
                        autoComplete: "text",
                        maxLength: MAX_LENGTH.EMAIL,
                      },
                    }}
                    {...register("work_email")}
                    helperText={errors.work_email?.message}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <LocationSearch
            required
            id="billing-contact-billing-address-input"
            label={t("BILLING_ADDRESS")}
            placeholder={t("BILLING_ADDRESS_PLACEHOLDER")}
            onPlaceSelect={(data) =>
              setValue("billing_address", data.description, {
                shouldValidate: true,
              })
            }
            fullWidth
            {...register("billing_address")}
            helperText={errors.billing_address?.message}
          ></LocationSearch>

          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={t("ADD_ORGANIZATION")}
              type="submit"
              disabled={!isValid || isLoading}
            />
            <Button
              className={"form-button outlined-button gray-border"}
              title={t("CANCEL")}
              disabled={isLoading}
              onClick={handleClose}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

export { AddOrganizationPopUpForm, BillingContactPopUpForm };
