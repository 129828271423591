import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { REVIVAL_LOGO_IMG } from "../../assets/images";
import { checkAccessLogic } from "../../utils";
import {
  ACCOUNT_TYPES,
  DRAWER_WIDTH,
  ROUTES,
  SIDE_MENU_NAV,
} from "../../utils/constants";
import { useActiveClientContext } from "../../utils/contexts/ActiveClientContext";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import {
  SideNavDrawer,
  StyledImage,
  StyledMenuItem,
  StyledMenuList,
} from "./styles";

const SideNav = () => {
  const history = useHistory();
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  const { accountDetails } = useActiveUserContext();

  const handleClick = (path, action) => {
    action && action();
    history.push(path);
  };

  useEffect(() => {
    setIsSystemAdmin(accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN);
  }, [accountDetails]);

  return (
    <SideNavDrawer
      sx={{
        padding: "20px",
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <StyledImage src={REVIVAL_LOGO_IMG} alt="Revival Logo" loading="eager" />

      <StyledMenuList>
        {SIDE_MENU_NAV.map(
          ({ name, path, iconName, action, systemAdminOnly }, index) =>
            checkAccessLogic(isSystemAdmin, systemAdminOnly) ? (
              <StyledMenuItem
                selected={
                  history.location.pathname === path ||
                  (path !== ROUTES.HOME &&
                    history.location.pathname.includes(path))
                }
                key={name}
                style={{
                  marginTop: "12px",
                }}
                onClick={() => handleClick(path, action)}
              >
                <img src={iconName} alt={name} />

                <ListItemText primary={name} />
              </StyledMenuItem>
            ) : null
        )}
      </StyledMenuList>
    </SideNavDrawer>
  );
};

export default SideNav;
