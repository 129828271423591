import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import { API_BASE_URL } from "../../utils/config";
import { getWellSiteChartsAPI } from "../../utils/restAPI/ChartsAPI";
import { DualAxesCharts } from "./DualAxesCharts";
import { UNITS } from "./helper";
import { SingleAxisCharts } from "./SingleAxisCharts";

export default function Charts({ selectedDevice = 0, cookieAccess = false }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});

  console.log("selectedDevice", selectedDevice);

  const fetchChartsData = useCallback(() => {
    setLoading(true);

    if (cookieAccess) {
      let accessToken = Cookies.get("ACCESS_TOKEN");

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (selectedDevice?.deviceId) {
        axios
          .get(
            `${API_BASE_URL}/Telemetry/deviceid/day?deviceid=${selectedDevice?.deviceId}&day=`,
            { headers }
          )
          .then((res) => {
            setChartData(res?.data?.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      const { promise } = getWellSiteChartsAPI(selectedDevice.deviceId);

      promise
        .then((res) => {
          setChartData(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
  }, [selectedDevice, cookieAccess]);

  useEffect(() => {
    (selectedDevice || cookieAccess) && fetchChartsData();
  }, [selectedDevice, fetchChartsData, cookieAccess]);

  console.log("chartData", chartData);

  return (
    !loading && (
      <>
        {chartData?.chart1 && <DualAxesCharts chartData={chartData?.chart1} />}

        {chartData?.chart2 && (
          <SingleAxisCharts
            chartData={chartData?.chart2}
            unit={UNITS.TEMPRATURE}
          />
        )}
        {chartData?.chart3 && (
          <SingleAxisCharts
            chartData={chartData?.chart3}
            unit={UNITS.BATTERY_VOLTAGE}
          />
        )}
      </>
    )
  );
}
