import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { ICON_LOCK } from "../../assets/images";

export const VisibilityOn = styled(VisibilityOutlined)`
  width: 21px;
  margin-inline: 2px;
  cursor: pointer;
`;
export const VisibilityOff = styled(VisibilityOffOutlined)`
  width: 21px;
  margin-inline: 2px;
  cursor: pointer;
`;
export const StyledLockIcon = styled(ICON_LOCK)`
  width: 21px;
  height: 24px;
  margin-inline: 2px;
`;
