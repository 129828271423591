import { clearToken, customToast, getAccessToken, getAccountName, getRefreshToken, setToken } from "../common";
import { API_BASE_URL } from "../config";
import { ROUTES, TOAST_DURATION, TOAST_TYPES } from "../constants";
import { logger } from "../logger";
import { triggerRefreshTokenAPI } from "./AuthAPI";
import axios from "axios";
import axiosCancel from "axios-cancel";
import _ from "lodash";
import makeCancellablePromise from "make-cancellable-promise";
import { v4 as uuidV4 } from "uuid";

const log = logger("APIs");

const APIClient = axios.create();

axiosCancel(APIClient, { debug: false });

const StatusCodeToIntercept = [400, 401, 403, 404];

// Request Interceptors
APIClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    const tenantId = getAccountName()?.tenantId;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    if (tenantId) {
      config.headers["tenantId"] = tenantId;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const handleErrorToasts = (errorMsg) => {
  if (typeof errorMsg === "object") {
    const errorArr = errorMsg && Object.values(errorMsg);
    if (errorArr?.length > 1) {
      const errors = (
        <ul>
          {errorArr?.map((error) => (
            <li>{String(error)}</li>
          ))}
        </ul>
      );

      customToast(
        errors,
        TOAST_TYPES.ERROR,
        () => window.location.reload(),
        TOAST_DURATION.VERY_SHORT
      );
    } else {
      const error = String(Object.values(errorMsg));
      customToast(
        error,
        TOAST_TYPES.ERROR,
        () => window.location.reload(),
        TOAST_DURATION.VERY_SHORT
      );
    }
  } else {
    customToast(
      errorMsg,
      TOAST_TYPES.ERROR,
      () => window.location.reload(),
      TOAST_DURATION.VERY_SHORT
    );
  }
};

// Response Interceptors
APIClient.interceptors.response.use(
  (response) => response,
  (e) => {
    const status = e?.response?.status;

    const errorMessage = e.response?.data?.message;

    if (StatusCodeToIntercept.includes(status)) {
      const orgRequest = e.config;

      if (status === 401 && !orgRequest._retry) {
        orgRequest._retry = true;

        const refresh_token = getRefreshToken();

        if (refresh_token) {
          const { promise, cancel } = triggerRefreshTokenAPI({
            refreshtoken: refresh_token,
          });

          promise
            .then((res) => {
              clearToken();
              setToken({
                access_token: res.data.access_token,
                refresh_token: res.data.refresh_token,
              });
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${getAccessToken()}`;
            })
            .catch((e) => {
              clearToken();

              handleErrorToasts(
                e.response?.data?.error || e.response?.data?.message
              );

              window.location = ROUTES.LOGIN;
            });

          return cancel;
        } else {
          clearToken();
          window.location = ROUTES.LOGIN;
        }
      }

      // if ([403, 422].includes(status)) {
      //   showAPIError(e);
      // }

      if (status >= 400) {
        errorMessage && handleErrorToasts(errorMessage);
      }

      if (status === 404) {
        // window.location = "/404";
      }
    }

    throw e;
  }
);

export default APIClient;

export async function failSafe(promise, showDefaultMessage = true) {
  try {
    const response = await promise;
    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      // Ignore silently
      return;
    }
    log.info(e);

    // show only AJAX erros here
    if (e.isAxiosError) {
      const status = e?.response?.status;
      // Check if response error is already handled by interceptor
      if (!StatusCodeToIntercept.includes(status) && showDefaultMessage) {
        // if (_.isEmpty(_.get(e, 'response.data.error'))) {
        showAPIError(e);
        // }
      }
    }
    throw e;
  }
}

export const showAPIError = _.throttle(function showAPIError(e) {
  const messageLocation = ["response.data.error"];

  let errorMessage;
  messageLocation.every((loc) => {
    const m = _.result(e, loc);
    if (m) {
      errorMessage = m;
      return false;
    }
    return true;
  });

  if (e?.response?.status >= 500 || e?.response?.status === 401) {
    errorMessage =
      "Something went wrong. Please refresh the page and try again!";
  }

  handleErrorToasts(errorMessage);
}, 1500);

export function cancellableFactory() {
  const requestId = uuidV4();
  return {
    requestId,
    cancel() {
      APIClient.cancel(requestId);
    },
  };
}

/*
 * url: API URL Path
 * method: GET, POST, PUT, DELETE, UPDATE (DEFAULT is set to "GET")
 * options: data, params, headers
 * showDefaultMessage: true, false (To Show Message handled by interceptors)
 */
export function makeCancellableAPIRequest(
  { urlPath, method = "GET", options = {} },
  showDefaultMessage = true
) {
  const { requestId, cancel: cancelRequest } = cancellableFactory();
  const { data, params, headers } = options;
  const url = `${API_BASE_URL}/${urlPath}`;
  const { promise, cancel: cancelPromise } = makeCancellablePromise(
    APIClient({
      url,
      method,
      requestId,
      data,
      params,
      headers,
    })
  );

  const handledPromise = failSafe(promise, showDefaultMessage);
  return {
    promise: handledPromise,
    cancel: () => {
      /**
       * Here, we are cancelling both API request and the promise.
       * If we only cancel the request, the promise still resolves with undefined and
       * throws error where the cases are not handled.
       * Cancelling the promise will prevent the promise from resolving as
       * we do not need that promise any more after cancelling it
       */
      cancelPromise();
      cancelRequest();
    },
  };
}
