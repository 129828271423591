import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { customToast, getGroupData, setGroupData } from "../../utils/common";
import { TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { ROUTES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { triggerAccountAdminsAPI, triggerAssignedWellSitesAPI, triggerOperatorsAPI } from "../../utils/restAPI/AdminAPI";
import { deleteGroupDetailsAPI, getGroupDetailsAPI, getGroupListAPI, saveGroupDetailsAPI } from "../../utils/restAPI/GroupManagementAPI";
import GroupManagementView from "../../views/GroupManagementView";
import EditView from "../../views/GroupManagementView/EditView";

const log = logger("GroupManagement");
export default function GroupManagementContainer({ params, ...rest }) {
  const history = useHistory();
  const [isEmpty, setIsEmpty] = useState(true);
  const [fetchedData, setFetchedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGroupData, setSelectedGroupData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const group = getGroupData();
    if (group) {
      setSelectedGroupData(group);
    }
    setIsEmpty(false);
    getFormData();
  }, []);

  const getGroupDetails = ({ id, cb }) => {
    const { promise, cancel } = getGroupDetailsAPI(id);
    promise
      .then((res) => {
        setSelectedGroupData(res?.data);
        setGroupData(res?.data);

        cb && cb(res.message);
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const getFormData = async () => {
    const { promise: accountAdminsPromise } = triggerAccountAdminsAPI();
    const { promise: operatorsPromise } = triggerOperatorsAPI();
    const { promise: assignedWellSitesPromise } = triggerAssignedWellSitesAPI();

    const [accountAdminsRes, operatorsRes, assignedWellSitesRes] =
      await Promise.all([
        accountAdminsPromise,
        operatorsPromise,
        assignedWellSitesPromise,
      ]);

    await setFetchedData({
      accountAdminsList: accountAdminsRes.data,
      operatorsList: operatorsRes.data,
      assignedWellSitesList: assignedWellSitesRes.data,
    });

    setIsLoading(false);
  };

  const saveGroupDetailsData = ({ params, cb }) => {
    const { promise, cancel } = saveGroupDetailsAPI(params);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_GROUP_ADDITION`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const deleteGroupData = ({ params, cb }) => {
    const { promise, cancel } = deleteGroupDetailsAPI(params?.id);
    promise
      .then((res) => {
        customToast(
          t(`SUCCESS_MESSAGE_GROUP_DELETION`),
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const handleRowClick = (id) => {
    getGroupDetails({ id, cb: redirectToEditUser });
  };
  const redirectToEditUser = (id) => {
    history.push(ROUTES.EDIT_GROUP);
  };

  return params?.isEdit && selectedGroupData && !isLoading ? (
    <EditView
      selectedGroupData={selectedGroupData}
      fetchedData={fetchedData}
      saveGroupDetailsData={saveGroupDetailsData}
      deleteGroupData={deleteGroupData}
      loading={isLoading}
    />
  ) : (
    <GroupManagementView
      fetchedData={fetchedData}
      saveGroupDetailsData={saveGroupDetailsData}
      isEmpty={isEmpty}
      onRowClick={handleRowClick}
      api={getGroupListAPI}
    />
  );
}
