import { styled as muiStyled, Toolbar } from "@mui/material";
import styled from "styled-components";

import { LAYOUT_CONSTANTS } from "../../assets/css/theme";
import {
  ICON_ANNOUCEMENT_COMPONENT,
  ICON_SETTINGS_COMPONENT,
} from "../../assets/images";

export const AppHeaderContainer = muiStyled(Toolbar)(({ theme }) => ({
  height: LAYOUT_CONSTANTS.TOP_SPACE,
  padding: "0 18px !important",
  boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",

  ".MuiIconButton-root": {
    color: theme.palette.common.white,
  },
}));

export const StyledDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const StyledDiv2 = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-inline: 20px;
`;

export const StyledSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  letter-spacing: 0.1px;

  color: #171725;
`;

export const StyledSettingsIcon = styled(ICON_SETTINGS_COMPONENT)`
  cursor: pointer;
`;
export const StyledAnnouncementsIcon = styled(ICON_ANNOUCEMENT_COMPONENT)`
  cursor: pointer;
`;
