import i18n from "i18next";
import * as Yup from "yup";

import { checkEmailRegex, checkPhoneRegex } from "../../utils";
import { DATA_KEY } from "../../utils/constants";

export const AddUserValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    first_name: Yup.string().required(t("ERROR_FIRST_NAME_REQUIRED")),
    last_name: Yup.string().required(t("ERROR_LAST_NAME_REQUIRED")),
    email: Yup.string()
      .required(t("ERROR_EMAIL_REQUIRED"))
      .matches(checkEmailRegex(), t("ERROR_INVALID_EMAIL")),
    phone_number: Yup.string()
      .required(t("ERROR_MOBILE_NUMBER_REQUIRED"))
      .matches(checkPhoneRegex(), t("ERROR_INVALID_MOBILE_NUMBER")),
    user_roles: Yup.string().required(t("ERROR_FIELD_REQUIRED")),
    assigned_groups: Yup.array().of(Yup.string()),
    assigned_well_site: Yup.array().of(Yup.string()),
  });
};
export const EditUserValidation = () => {
  const t = i18n.t;
  return Yup.object().shape({
    first_name: Yup.string().required(t("ERROR_FIRST_NAME_REQUIRED")),
    last_name: Yup.string().required(t("ERROR_LAST_NAME_REQUIRED")),
    email: Yup.string()
      .required(t("ERROR_EMAIL_REQUIRED"))
      .matches(checkEmailRegex(), t("ERROR_INVALID_EMAIL")),
    phone: Yup.string()
      .required(t("ERROR_MOBILE_NUMBER_REQUIRED"))
      .matches(checkPhoneRegex(), t("ERROR_INVALID_MOBILE_NUMBER")),
    assigned_groups: Yup.array().of(Yup.string()),
    assigned_well_site: Yup.array().of(Yup.string()),
  });
};

export const formatRowData = ({
  userId,
  fullName,
  roleName,
  email,
  groupNames,
  assignedGroupName,
  assignedWellsiteName,
}) => {
  return {
    id: userId,
    name: fullName,
    user_role: roleName,
    email,
    assigned_groups: groupNames || [],
    assigned_well_site: assignedWellsiteName || [],
  };
};

export const headCells = [
  { id: 1, name: "name", title: "Name" },
  { id: 2, name: "user_role", title: "Role" },
  { id: 3, name: "email", title: "Email" },
  { id: 4, name: "assigned_groups", title: "Group(s)" },
  { id: 5, name: "assigned_well_site", title: "Well Site(s)" },
];

export const getDataArray = (data) => {
  return data?.userList?.usersDetails;
};

export const getReducedArray = (array, key) => {
  return array.map((item) => item[key]);
};

export const formatUserRole = (data) => {
  return data?.roles;
};

export const formatGroupNames = (data) => {
  return data?.groups;
};

export const formatWellSitesList = (data) => {
  return data?.wellsites?.rows;
};

export const getUserRoleID = (roles, selectedRole) => {
  const data = roles.roles.filter(
    (role) => role[DATA_KEY.USER_ROLES] === selectedRole
  );
  return data[0]?.roleId;
};

export const getGroupID = (groups, selectedGroup) => {
  const data = groups.groups.filter((group) =>
    selectedGroup.includes(group[DATA_KEY.GROUP_NAME])
  );
  return data.map((item) => item["groupId"]);
};

export const getWellSiteID = (wellsites, selectedWellSite) => {
  const data = wellsites?.wellsites?.rows?.filter((wellSite) =>
    selectedWellSite.includes(wellSite[DATA_KEY.WELL_SITES])
  );
  return data.map((item) => item["wellsiteId"]);
};

export const getDerivedUserData = ({ user }) => {
  const {
    userId,
    auth0Id,
    firstName,
    lastName,
    email,
    isActive,
    roleID,
    organizationId,
    phoneNumber,
    assignedGroups,
    assignedWellsites,
  } = user;
  return {
    isActive,
    roleID,
    organizationId,
    userId,
    auth0Id,

    first_name: firstName,
    last_name: lastName,
    email,
    phone: phoneNumber,
    assigned_groups: getReducedArray(assignedGroups, DATA_KEY.GROUP_NAME),
    assigned_well_site: getReducedArray(assignedWellsites, DATA_KEY.WELL_SITES),
  };
};
