import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { ACCOUNT_TYPES, ROUTES } from "../../utils/constants";
import { useActiveClientContext } from "../../utils/contexts/ActiveClientContext";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { DropDown2 } from "../DropDown2";
import ImageCircle from "../ImageCircle";
import { AppHeaderContainer, StyledAnnouncementsIcon, StyledDiv, StyledDiv2, StyledSettingsIcon, StyledSpan } from "./styles";

const AppHeader = () => {
  const { accountNames, selectedAccount, handleChangeAccountName } =
    useActiveClientContext();
  const { accountDetails } = useActiveUserContext();
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  useEffect(() => {
    setIsSystemAdmin(accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN);
  }, [accountDetails]);

  const { firstName, lastName } = accountDetails || {
    firstName: "",
    lastName: "",
  };

  const history = useHistory();

  const handleChange = ({ id, value }) => {
    handleChangeAccountName({ tenantId: id, organizationName: value });
  };

  const handleSettings = () => {
    history.push(ROUTES.SETTINGS);
  };

  const handleAnnouncements = () => {
    // history.push(ROUTES.SETTINGS);
  };

  return (
    <AppHeaderContainer>
      <StyledDiv>
        <StyledAnnouncementsIcon onClick={handleAnnouncements} />
        <StyledSettingsIcon onClick={handleSettings} />

        {accountDetails && (
          <StyledDiv2>
            <ImageCircle title={`${firstName} ${lastName}`} sm={true} />
            <StyledSpan>{`${firstName} ${lastName}`} </StyledSpan>
          </StyledDiv2>
        )}
      </StyledDiv>

      {accountNames && selectedAccount && isSystemAdmin && (
        <DropDown2
          id="select-account"
          data={accountNames}
          dataKey={"tenantId"}
          dataVal={"organizationName"}
          value={selectedAccount}
          handleChange={handleChange}
        />
      )}
    </AppHeaderContainer>
  );
};

export default AppHeader;
