import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ICON_CLOSE } from "../../assets/images";
import { Button, Input } from "../../components";
import { ChipInput } from "../../components/ChipInput";
import { ScrollDialog } from "../../components/Dialog";
import { DATA_KEY, MAX_LENGTH, POPUP_TYPES, ROUTES } from "../../utils/constants";
import { AddGroupValidation, formatAccountAdminsNames, formatGroupNames, formatOperatorsNames, formatWellSitesList, getAccountAdminID, getOperatorID, getWellSiteID } from "./helper";
import { FormFooterContainer, FormHeaderContainer, FormHeading, FormWrapper, StyledImg } from "./styles";

function PopUpForm({ open, setOpen, type, fetchedData, saveGroupDetailsData }) {
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    type: type,
    account_admin_names: [],
    operators: [],
    assigned_well_site: "",
  };

  const { t } = useTranslation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(AddGroupValidation(type)),
  });

  const selections = [
    useWatch({
      control,
      name: "account_admin_names",
    }).length,
    useWatch({
      control,
      name: "operators",
    }).length,
  ];

  const isSelectionValid = Math.min(...selections);

  const onSubmit = (formValues) => {
    setIsLoading(true);
    saveGroupDetailsData({
      params: {
        groupName: formValues?.group_name,
        userIds:
          [
            ...getAccountAdminID(
              fetchedData?.accountAdminsList,
              formValues?.account_admin_names
            ),
            ...getOperatorID(fetchedData?.operatorsList, formValues?.operators),
          ] || [],
        wellsiteId:
          getWellSiteID(
            fetchedData?.assignedWellSitesList,
            formValues?.assigned_well_site
          ) || [],
      },
      cb: redirectToGroupManagement,
    });
  };

  const redirectToGroupManagement = () => {
    handleClose();
    window.location = ROUTES.GROUP_MANAGEMENT;
  };

  const handleClose = () => {
    setIsLoading(false);
    reset();
    setOpen(false);
  };

  return (
    <ScrollDialog open={open} setOpen={handleClose}>
      <FormWrapper>
        <FormHeaderContainer>
          <FormHeading>
            {type === POPUP_TYPES.CREATE_A_GROUP
              ? t("CREATE_A_GROUP")
              : t("ADD_A_WELL_SITE")}
          </FormHeading>

          <StyledImg src={ICON_CLOSE} onClick={handleClose} alt="Close Icon" />
        </FormHeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {type === POPUP_TYPES.CREATE_A_GROUP && (
              <Input
                required
                id="add-well-site-group-name-input"
                label={t("GROUP_NAME")}
                placeholder={t("GROUP_NAME_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.TEXT,
                  },
                }}
                {...register("group_name")}
                helperText={errors.group_name?.message}
              />
            )}
            {type === POPUP_TYPES.ADD_A_WELL_SITE && (
              <Input
                id="add-well-site-well-site-id-input"
                label={t("WELL_SITE_NAME")}
                placeholder={t("WELL_SITE_NAME_PLACEHOLDER")}
                fullWidth
                extraInputProps={{
                  inputProps: {
                    autoComplete: "text",
                    maxLength: MAX_LENGTH.TEXT,
                  },
                }}
                {...register("well_site_id")}
                helperText={errors.well_site_id?.message}
              />
            )}

            <ChipInput
              required
              id="add-well-site-account-admin-names-input"
              data={formatAccountAdminsNames(fetchedData.accountAdminsList)}
              dataKey={DATA_KEY.ACCOUNT_ADMINS}
              name="account_admin_names"
              multiple
              label={t("ACCOUNT_ADMINS")}
              placeholder={t("ACCOUNT_ADMINS_PLACEHOLDER")}
              control={control}
              setValue={setValue}
            />
            <ChipInput
              required
              id="add-well-site-operators-input"
              data={formatOperatorsNames(fetchedData.operatorsList)}
              dataKey={DATA_KEY.OPERATORS}
              name="operators"
              multiple
              label={t("OPERATORS")}
              placeholder={t("OPERATORS_PLACEHOLDER")}
              control={control}
              setValue={setValue}
            />
            {type === POPUP_TYPES.ADD_A_WELL_SITE && (
              <ChipInput
                id="add-well-site-assigned-group-input"
                data={formatGroupNames(fetchedData.assignedGroupsList)}
                dataKey={DATA_KEY.GROUP_NAME}
                name="assigned_groups"
                multiple
                defaultValue={["AccountAdmin"]}
                label={t("ASSIGNED_GROUPS")}
                placeholder={t("ASSIGNED_GROUPS_PLACEHOLDER")}
                control={control}
                setValue={setValue}
              />
            )}
            {type === POPUP_TYPES.CREATE_A_GROUP && (
              <ChipInput
                id="add-well-site-create-group-input"
                data={formatWellSitesList(fetchedData.assignedWellSitesList)}
                dataKey={DATA_KEY.WELL_SITES}
                name="assigned_well_site"
                multiple
                label={t("ASSIGNED_WELL_SITE")}
                placeholder={t("ASSIGNED_WELL_SITE_PLACEHOLDER")}
                control={control}
                setValue={setValue}
              />
            )}
          </div>
          <FormFooterContainer className="direction-row-reverse">
            <Button
              className={"form-button"}
              title={
                type === POPUP_TYPES.CREATE_A_GROUP
                  ? t("CREATE_GROUP")
                  : t("ADD_A_WELL_SITE")
              }
              type="submit"
              disabled={!isValid || isLoading || !isSelectionValid}
            />

            <Button
              className={"form-button outlined-button gray-border"}
              title={t("CANCEL")}
              disabled={isLoading}
              onClick={handleClose}
            />
          </FormFooterContainer>
        </form>
      </FormWrapper>
    </ScrollDialog>
  );
}

export default PopUpForm;
