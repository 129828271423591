import { makeCancellableAPIRequest } from ".";

export function triggerAccountDetailsAPI() {
  const apiParams = {
    urlPath: "user/accesstoken",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveAccountDetailsAPI(data) {
  const apiParams = {
    urlPath: "user/updateaccountadmindetail",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function resetAccountAdminPasswordAPI(data) {
  const apiParams = {
    urlPath: "user/resetpasswordaccountadmin",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function triggerUserDataAPI(params) {
  const apiParams = {
    urlPath: "account-admin/get-user-data",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function triggerGetMyRoleAPI(params) {
  const apiParams = {
    urlPath: "account-admin/get-my-role",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function triggerGetAccountNamesAPI() {
  const apiParams = {
    urlPath: "organization",
  };
  return makeCancellableAPIRequest(apiParams);
}

export function triggerGetUserRolesAPI(params) {
  const apiParams = {
    urlPath: "user/roles",
    method: "GET",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
export function triggerAssignedGroupsAPI(params) {
  const apiParams = {
    urlPath: "groups",
    method: "GET",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
export function triggerAssignedWellSitesAPI(params) {
  const apiParams = {
    urlPath: "wellsite",
    method: "GET",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
export function triggerAccountAdminsAPI() {
  const apiParams = {
    urlPath: `users/byrole?roleId=2`,
    method: "GET",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}
export function triggerOperatorsAPI() {
  const apiParams = {
    urlPath: `users/byrole?roleId=3`,
    method: "GET",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}
