import { ACCESS_TOKEN_KEY } from "./constants";

export function isLoggedIn() {
  return !!localStorage.getItem(ACCESS_TOKEN_KEY);
  // return true;
}

export function checkEmailRegex() {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export function checkPasswordRegex() {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
}
export function checkPasswordMatch() {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
}

export function checkPhoneRegex() {
  return /^(\+\d{1,2}\s?)?1??\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
}

/**
 * Description : To give logical Output for ( AB + A!B + !A!B )
 * Output : true or false
 * Example : checkAccessLogic(true,true) is true
 */
export function checkAccessLogic(exp1, exp2) {
  return !exp2 ? true : exp1 ? true : false;
}
