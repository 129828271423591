import { makeCancellableAPIRequest } from ".";

export function getDeviceDetailsAPI(id) {
  const apiParams = {
    urlPath: `group/groupdetails/id?groupId=${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function getDeviceListAPI(params) {
  const apiParams = {
    urlPath: "device",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function getUnassignedDeviceListAPI(params) {
  const apiParams = {
    urlPath: "device/unassigned",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveDeviceDetailsAPI(data) {
  const apiParams = {
    urlPath: "device",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}
export function returnDeviceDetailsAPI(id) {
  const apiParams = {
    urlPath: `device/return?deviceid=${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function deleteDeviceDetailsAPI(id) {
  const apiParams = {
    urlPath: `group/delete/id?id=${id}`,
    method: "DELETE",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}
