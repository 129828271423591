import { ICON_ACKNOWLEDGED_ALERTS_COMPONENT, ICON_ALARMS_COMPONENT, ICON_OPERATIONAL_WELL_SITES_COMPONENT, ICON_SWAP_COMPONENT, ICON_SWAP_COMPONENT_GRAY, ICON_WARNINGS_COMPONENT } from "../../assets/images";
import { ALERT_TYPES, TILE_TYPES } from "../../utils/constants";
import Button from "../Button";
import { CardContainerDiv, StyledActivityCard, StyledAlarmCardBody, StyledAlarmCardHeader, StyledAlarmHeading, StyledAlarmIcon, StyledAlertCard, StyledCalendarIcon, StyledCardHeading, StyledDiv, StyledHeading, StyledHorizontalDiv, StyledHR, StyledPara, StyledStrechedCard, StyledText, StyledValue, StyledWarningCardHeader, StyledWarningIcon, StyledWrapper } from "./styles";

export const AlertCard = ({ type, number, title }) => {
  switch (type) {
    case ALERT_TYPES.ALARMS:
      return (
        <StyledAlertCard elevation={0}>
          <StyledValue className="alerts alarms">{number}</StyledValue>
          <StyledHR />
          <StyledWrapper className="alerts">
            <ICON_ALARMS_COMPONENT />
            <StyledValue className="alert-title alarms">{title}</StyledValue>
          </StyledWrapper>
        </StyledAlertCard>
      );
    case ALERT_TYPES.WARNINGS:
      return (
        <StyledAlertCard elevation={0}>
          <StyledValue className="alerts warnings">{number}</StyledValue>
          <StyledHR />
          <StyledWrapper className="alerts">
            <ICON_WARNINGS_COMPONENT />
            <StyledValue className="alert-title warnings">{title}</StyledValue>
          </StyledWrapper>
        </StyledAlertCard>
      );
    default:
      return (
        <StyledAlertCard elevation={0}>
          <StyledValue className="alerts ">{number}</StyledValue>
          <hr />
          {title}
        </StyledAlertCard>
      );
  }
};

export const AlarmAndWarningCard = ({
  type,
  number,
  title,
  onClick,
  switchDisabled = false,
}) => {
  switch (type) {
    case ALERT_TYPES.ALARMS:
      return (
        <CardContainerDiv>
          <StyledAlarmCardHeader elevation={0}>
            <StyledAlarmIcon />
            <StyledAlarmHeading>{`Alarm`}</StyledAlarmHeading>
          </StyledAlarmCardHeader>
          <StyledAlarmCardBody elevation={0}>
            <StyledCardHeading>{title}</StyledCardHeading>
            <Button
              className={`form-button outlined-button  ${
                switchDisabled ? "disabled-button " : null
              }`}
              title={"Change to Warning"}
              disabled={switchDisabled}
              endIcon={
                switchDisabled ? (
                  <ICON_SWAP_COMPONENT_GRAY />
                ) : (
                  <ICON_SWAP_COMPONENT />
                )
              }
              onClick={onClick}
            />
          </StyledAlarmCardBody>
        </CardContainerDiv>
      );
    case ALERT_TYPES.WARNINGS:
      return (
        <CardContainerDiv>
          <StyledWarningCardHeader elevation={0}>
            <StyledWarningIcon />
            <StyledAlarmHeading>{`Warning`}</StyledAlarmHeading>
          </StyledWarningCardHeader>
          <StyledAlarmCardBody elevation={0}>
            <StyledCardHeading>{title}</StyledCardHeading>
            <Button
              className={`form-button outlined-button  ${
                switchDisabled ? "disabled-button " : null
              }`}
              title={"Change to Alarm"}
              disabled={switchDisabled}
              endIcon={
                switchDisabled ? (
                  <ICON_SWAP_COMPONENT_GRAY />
                ) : (
                  <ICON_SWAP_COMPONENT />
                )
              }
              onClick={onClick}
            />
          </StyledAlarmCardBody>
        </CardContainerDiv>
      );
    default:
      return <CardContainerDiv></CardContainerDiv>;
  }
};

export const StrechedCard = ({ type, number, title }) => {
  switch (type) {
    case TILE_TYPES.OPERATIONAL_WELL_SITES:
      return (
        <StyledStrechedCard elevation={0}>
          <StyledWrapper className="streched-tile">
            <StyledValue className="streched-tile">{number}</StyledValue>
            <StyledValue className="streched-tile-title">{title}</StyledValue>
          </StyledWrapper>
          <ICON_OPERATIONAL_WELL_SITES_COMPONENT />
        </StyledStrechedCard>
      );
    case TILE_TYPES.ACKNOWLEDGED_ALERTS:
      return (
        <StyledStrechedCard elevation={0}>
          <StyledWrapper className="streched-tile">
            <StyledValue className="streched-tile">{number}</StyledValue>
            <StyledValue className="streched-tile-title">{title}</StyledValue>
          </StyledWrapper>
          <ICON_ACKNOWLEDGED_ALERTS_COMPONENT />
        </StyledStrechedCard>
      );
    default:
      return (
        <StyledStrechedCard elevation={0}>
          <StyledValue className="streched-tile">{number}</StyledValue>
          <ICON_ACKNOWLEDGED_ALERTS_COMPONENT />
        </StyledStrechedCard>
      );
  }
};

export const ActivityCard = ({
  type,
  title,
  content,
  date,
  author,
  handleDelete,
}) => {
  const getClass = () => {
    switch (type) {
      case ALERT_TYPES.ALARMS:
        return "alarms";
      case ALERT_TYPES.WARNINGS:
        return "warnings";
      default:
        return "";
    }
  };

  return (
    <StyledActivityCard elevation={0}>
      <StyledDiv>
        <StyledHeading className={getClass()}>{title}</StyledHeading>
        <StyledPara>{content}</StyledPara>
        <StyledHorizontalDiv>
          <StyledCalendarIcon />
          <StyledText>{date}</StyledText>
          <StyledText>|</StyledText>
          <StyledText>{author}</StyledText>
        </StyledHorizontalDiv>
      </StyledDiv>

      {/* <StyledDeleteIcon onClick={handleDelete} /> */}
    </StyledActivityCard>
  );
};
