import styled from "@emotion/styled";
import { InputAdornment, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 10px;
    margin-top: 50px;
    background: white;

    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-gray-gray-6) !important;
    }

    & ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--color-gray-gray-6);
      opacity: 1;
    }
    & ::-moz-placeholder {
      /* Firefox 19+ */
      color: pink;
    }
    & :-ms-input-placeholder {
      /* IE 10+ */
      color: pink;
    }
    & :-moz-placeholder {
      /* Firefox 18- */
      color: pink;
    }
  }

  .MuiFormHelperText-root {
    color: var(--color-revival-red-red-1) !important;
  }

  .MuiInputLabel-root {
    margin-top: 8px;
    margin-left: -12px;
    line-height: 20px;
    font-weight: 500;
    font-size: 15px;
    color: var(--color-gray-gray-10);

    &.Mui-focused {
      color: var(--color-gray-gray-10) !important;
    }
  }

  &.searchInput .MuiInputBase-root {
    margin-top: 0px !important;
  }

  & input {
    animation-name: none;
    -webkit-animation-duration: 10ms;
    animation-duration: 1000s;
  }

  &.undecorated-input input {
    color: transparent !important;
    text-shadow: 0 0 black;
    cursor: default;
  }

  &.with-StartAdornment input {
    padding-left: 35px;
  }
  &.with-EndAdornment input {
    padding-right: 35px;
  }

  &.errorTextColor .MuiInputBase-root {
    &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-revival-red-red-1) !important;
    }
  }
`;

export const StyledInputAdornment = styled(InputAdornment)(
  {
    position: "absolute",
    top: "28px",
  },
  (props) => (props.position === "end" ? { right: "15px" } : { left: "15px" })
);

export const StyledLabel = styled.label`
  margin-bottom: -37px;
  font-weight: 500;

  &.errorTextColor {
    color: var(--color-revival-red-red-1) !important;
  }
  &.required:after {
    content: " *";
    color: red;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
