import styled from "styled-components";
import { ReactComponent as ICON_TOAST_SUCCESS } from "./icon-toast-success.svg";
import { ReactComponent as ICON_TOAST_ERROR } from "./icon-toast-error.svg";

const ICON_TOAST_SUCCESS_COMPONENT = styled(ICON_TOAST_SUCCESS)`
  width: 30px;
`;
const ICON_TOAST_ERROR_COMPONENT = styled(ICON_TOAST_ERROR)`
  width: 30px;
`;

export { ICON_TOAST_SUCCESS_COMPONENT, ICON_TOAST_ERROR_COMPONENT };
