import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";

import Button from "../../components/Button";
import Footer from "../../components/Footer";
import HalfPageLayout from "../../components/HalfPageLayout";
import InputPassword from "../../components/InputPassword";
import { checkPasswordRegex } from "../../utils";
import { logout } from "../../utils/common";
import { MAX_LENGTH, ROUTES } from "../../utils/constants";
import { FormFoooterContainer, StyledForm, StyledHead, StyledHead2 } from "./styles";

export default function ResetPasswordView({ triggerResetPassword, userData }) {
  const history = useHistory();

  const { t } = useTranslation();
  const formSchema = Yup.object().shape({
    create_password: Yup.string()
      .required(t("ERROR_PASSWORD_REQUIRED"))
      .matches(checkPasswordRegex(), t("ERROR_INVALID_PASSWORD")),
    confirm_password: Yup.string()
      .required(t("ERROR_PASSWORD_REQUIRED"))
      .when("create_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("create_password")],
          t("ERROR_PASSWORD_DO_NOT_MATCH")
        ),
      }),
  });

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  useEffect(() => {
    setFocus("create_password");
  }, [setFocus]);

  const onSubmit = (formValues) => {
    triggerResetPassword({
      params: {
        ...userData,
        password: formValues?.confirm_password,
      },
      cb: handleLogout,
    });
  };

  const handleLogout = () => {
    logout();
    history.push(ROUTES.LOGIN);
  };

  const renderFooter = () => <Footer />;

  return (
    <HalfPageLayout footer={renderFooter()}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledHead>{t("RESET_PASSWORD")}</StyledHead>
        <StyledHead2>{t("PLEASE_CREATE_A_NEW_PASSWORD")}</StyledHead2>

        <InputPassword
          id="reset-password-create-password-input"
          label={t("CREATE_PASSWORD")}
          placeholder={t("CREATE_PASSWORD")}
          InputLabelProps={{
            shrink: false,
          }}
          extraInputProps={{
            inputProps: {
              autoComplete: "off",
              maxLength: MAX_LENGTH.PASSWORD,
            },
          }}
          {...register("create_password")}
          helperText={errors.create_password?.message}
        />
        <InputPassword
          id="reset-password-confirm-password-input"
          label={t("CONFIRM_PASSWORD")}
          placeholder={t("CONFIRM_PASSWORD")}
          InputLabelProps={{
            shrink: false,
          }}
          extraInputProps={{
            inputProps: {
              autoComplete: "off",
              maxLength: MAX_LENGTH.PASSWORD,
            },
          }}
          {...register("confirm_password")}
          helperText={errors.confirm_password?.message}
        />

        <FormFoooterContainer>
          <Button title={t("RESET_PASSWORD")} type="submit" fullWidth />
        </FormFoooterContainer>
      </StyledForm>
    </HalfPageLayout>
  );
}
