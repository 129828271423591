import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import ErrorContainer from "./containers/ErrorContainer";
import { ROUTES } from "./utils/constants";
import ActiveClientContextProvider from "./utils/contexts/ActiveClientContext";
import ActiveUserContextProvider from "./utils/contexts/ActiveUserContext";
import { privateRoutesList, publicRoutesList } from "./utils/routes";
import PrivateRoutes from "./utils/routes/PrivateRoutes";
import PublicRoutes from "./utils/routes/PublicRoutes";

function App() {
  return (
    <ActiveUserContextProvider>
      <ActiveClientContextProvider>
        <BrowserRouter>
          <Switch>
            {publicRoutesList.map((routes, index) => {
              return (
                <PublicRoutes
                  key={`Public_Route_${index}`}
                  restricted={routes.restricted}
                  component={routes.component}
                  path={routes.path}
                  params={routes.params ?? null}
                  exact
                />
              );
            })}
            {privateRoutesList.map((routes, index) => {
              return (
                <PrivateRoutes
                  key={`Private_Route_${index}`}
                  component={routes.component}
                  path={routes.path}
                  halfPageLayout={routes?.params?.halfPageLayout ?? false}
                  params={routes.params ?? null}
                  exact
                />
              );
            })}

            <Redirect exact from="/" to={ROUTES.LOGIN} />
            <Route component={ErrorContainer} />
          </Switch>
        </BrowserRouter>
      </ActiveClientContextProvider>
    </ActiveUserContextProvider>
  );
}

export default App;
