import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { customToast } from "../../utils/common";

import { ROUTES, TOAST_DURATION, TOAST_TYPES } from "../../utils/constants";
import { logger } from "../../utils/logger";
import { triggerForgotPasswordAPI } from "../../utils/restAPI/AuthAPI";
import ForgotPasswordView from "../../views/ForgotPasswordView";

const log = logger("Forgot-Password");

export default function ForgotPasswordContainer({ ...rest }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  const triggerForgotPassword = (params) => {
    const { promise, cancel } = triggerForgotPasswordAPI(params);
    promise
      .then((res) => {
        customToast(
          t("RESET_PASSWORD_LINK_SENT"),
          TOAST_TYPES.SUCCESS,
          () => reDirecToLogin(),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        log.error(e);
      });

    return cancel;
  };

  const reDirecToLogin = () => {
    history.push(ROUTES.LOGIN);
  };

  const handleSendLink = (data) => {
    setIsLoading(true);
    triggerForgotPassword(data);
  };

  const handleRedirectToLogin = () => {
    history.push(ROUTES.LOGIN);
  };

  return (
    <ForgotPasswordView
      handleRedirectToLogin={handleRedirectToLogin}
      handleSendLink={handleSendLink}
      isLoading={isLoading}
      {...rest}
    />
  );
}
