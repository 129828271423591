import ActivateUserContainer from "../../containers/ActivateUserContainer";
import AlertManagementContainer from "../../containers/AlertManagementContainer";
import ChartsContainer from "../../containers/ChartsContainer";
import DashboardContainer from "../../containers/DashboardContainer";
import DeviceManagementContainer from "../../containers/DeviceManagementContainer";
import ForgotPasswordContainer from "../../containers/ForgotPasswordContainer";
import GroupManagementContainer from "../../containers/GroupManagementContainer";
import LoginContainer from "../../containers/LoginContainer";
import OrganizationManagementContainer from "../../containers/OrganizationManagementContainer";
import ResetPasswordContainer from "../../containers/ResetPasswordContainer";
import SettingsContainer from "../../containers/SettingsContainer";
import UserManagementContainer from "../../containers/UserManagementContainer";
import WellSiteManagementContainer from "../../containers/WellSiteManagementContainer";
import { ROUTES } from "../constants";

const publicRoutesList = [
  {
    path: ROUTES.LOGIN,
    restricted: false,
    component: LoginContainer,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    restricted: false,
    component: ForgotPasswordContainer,
  },
  {
    path: ROUTES.ACTIVATE_USER,
    restricted: false,
    component: ActivateUserContainer,
    params: {
      redirected: true,
    },
  },
  {
    path: ROUTES.RESEND_LINK,
    restricted: false,
    component: ForgotPasswordContainer,
    params: {
      resendLink: true,
    },
  },
  {
    path: ROUTES.PASSWORD_RESET_SUCCESSFULLY,
    restricted: false,
    component: ForgotPasswordContainer,
    params: {
      resetSuccessfully: true,
    },
  },
  {
    path: ROUTES.CHARTS,
    restricted: false,
    component: ChartsContainer,
  },
];

const privateRoutesList = [
  {
    path: ROUTES.RESET_PASSWORD,
    restricted: true,
    component: ResetPasswordContainer,
    params: {
      halfPageLayout: true,
    },
  },
  {
    path: ROUTES.DASHBOARD,
    restricted: true,
    component: DashboardContainer,
  },
  {
    path: ROUTES.WELL_SITE_MANAGEMENT,
    restricted: true,
    component: WellSiteManagementContainer,
  },
  {
    path: ROUTES.EDIT_WELL_SITE,
    restricted: true,
    component: WellSiteManagementContainer,
    params: {
      isEdit: true,
    },
  },
  {
    path: ROUTES.USER_MANAGEMENT,
    restricted: true,
    component: UserManagementContainer,
  },
  {
    path: ROUTES.EDIT_USER,
    restricted: true,
    component: UserManagementContainer,
    params: {
      isEdit: true,
    },
  },
  {
    path: ROUTES.GROUP_MANAGEMENT,
    restricted: true,
    component: GroupManagementContainer,
  },
  {
    path: ROUTES.EDIT_GROUP,
    restricted: true,
    component: GroupManagementContainer,
    params: {
      isEdit: true,
    },
  },
  {
    path: ROUTES.ORGANIZATION_MANAGEMENT,
    restricted: true,
    component: OrganizationManagementContainer,
  },
  {
    path: ROUTES.DEVICE_MANAGEMENT,
    restricted: true,
    component: DeviceManagementContainer,
  },
  {
    path: ROUTES.ALERT_MANAGEMENT,
    restricted: true,
    component: AlertManagementContainer,
  },
  {
    path: ROUTES.SETTINGS,
    restricted: true,
    component: SettingsContainer,
  },
];

export { publicRoutesList, privateRoutesList };
