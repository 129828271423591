import { makeCancellableAPIRequest } from ".";

export function getGroupDetailsAPI(id) {
  const apiParams = {
    urlPath: `group/groupdetails/id?groupId=${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function getGroupListAPI(params) {
  const apiParams = {
    urlPath: "groups",
    options: {
      params,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveGroupDetailsAPI(data) {
  const apiParams = {
    urlPath: "group/save",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function deleteGroupDetailsAPI(id) {
  const apiParams = {
    urlPath: `group/delete/id?id=${id}`,
    method: "DELETE",
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}
