import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ICON_ENCIRCLED_CHECK_WHITE_COMPONENT, ICON_ENCIRCLED_WHITE_COMPONENT } from "../../assets/images";
import { Button } from "../../components";
import TableLayout from "../../components/TableLayout";
import { ACCOUNT_TYPES } from "../../utils/constants";
import { useActiveUserContext } from "../../utils/contexts/ActiveUserContext";
import { formatRowData, getDataArray, headCells } from "./helper";
import NoDataView from "./NoDataView";
import { AssignDevicePopUpForm, ReturnDevicePopUpForm } from "./PopUpForm";
import { ButtonsContainer, ContainerWrapper, PageHeading, TopContainer } from "./styles";

export default function DeviceManagementView({
  isEmpty,

  api,
  ...rest
}) {
  const { t } = useTranslation();
  const { accountDetails } = useActiveUserContext();
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  useEffect(() => {
    setIsSystemAdmin(accountDetails?.role === ACCOUNT_TYPES.SYSTEM_ADMIN);
  }, [accountDetails]);

  const [openAssignDevicePopUp, setOpenAssignDevicePopUp] = useState(false);
  const [openReturnDevicePopUp, setOpenReturnDevicePopUp] = useState(false);

  const handleAssignDevice = () => {
    setOpenAssignDevicePopUp(true);
  };
  const handleReturnDevice = () => {
    setOpenReturnDevicePopUp(true);
  };

  return (
    <>
      <AssignDevicePopUpForm
        open={openAssignDevicePopUp}
        setOpen={setOpenAssignDevicePopUp}
        {...rest}
      />
      <ReturnDevicePopUpForm
        open={openReturnDevicePopUp}
        setOpen={setOpenReturnDevicePopUp}
        {...rest}
      />
      <ContainerWrapper>
        <TopContainer>
          <PageHeading>{t("DEVICE_MANAGEMENT")}</PageHeading>
          {isSystemAdmin ? (
            <ButtonsContainer>
              <Button
                className={"header-button"}
                title={t("RETURN_A_DEVICE")}
                endIcon={<ICON_ENCIRCLED_WHITE_COMPONENT />}
                onClick={handleReturnDevice}
              />
              <Button
                className={"header-button"}
                title={t("ASSIGN_A_DEVICE")}
                endIcon={<ICON_ENCIRCLED_CHECK_WHITE_COMPONENT />}
                onClick={handleAssignDevice}
              />
            </ButtonsContainer>
          ) : undefined}
        </TopContainer>

        <TableLayout
          api={api}
          formatRowData={formatRowData}
          headCells={headCells}
          emptyView={<NoDataView handleAssignDevice={handleAssignDevice} />}
          getDataArray={getDataArray}
        />
      </ContainerWrapper>
    </>
  );
}
