import React from "react";

import Charts from "../../components/Charts";
import { DropDown2 } from "../../components/DropDown2";
import { BlankPageContainer } from "./styles";

export default function ChartsView({
  selectedDevice,
  devices,
  handleChangeDevice,
}) {
  return (
    <BlankPageContainer>
      {selectedDevice && devices && (
        <div
          style={{
            paddingLeft: "6vw",
            paddingRight: "9vw",
          }}
        >
          <DropDown2
            id="select-indicator"
            data={devices ?? []}
            value={selectedDevice}
            dataKey={"deviceId"}
            dataVal={"customName"}
            handleChange={handleChangeDevice}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Charts selectedDevice={selectedDevice} cookieAccess={true} />
      </div>
    </BlankPageContainer>
  );
}
