import styled from "styled-components";

export const StyledDiv = styled.div`
  width: ${(props) => (props.sm ? "35px" : "183.4px")};
  height: ${(props) => (props.sm ? "35px" : "183.4px")};

  background: #ffefe8;
  border-radius: 1604px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.sm ? "17px" : "85px")};
  line-height: 142px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Color / Revival Orange / Orange 5 */

  color: #f05423;
`;
