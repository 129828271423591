import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/index.css";

import "./utils/translations/config";

const backup = console.warn;

console.warn = function filterWarnings(msg) {
  const supressedWarnings = [
    "warning text",
    "MUI: You have provided an out-of-range",
  ];

  if (!supressedWarnings.some((entry) => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
