import { makeCancellableAPIRequest } from ".";

export function getUserDetailsAPI(id) {
  const apiParams = {
    urlPath: `user/userid?userId=${id}`,
    options: {},
  };
  return makeCancellableAPIRequest(apiParams);
}

export function getUserListAPI(data) {
  const apiParams = {
    urlPath: "user/users",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function saveUserDetailsAPI(data) {
  const apiParams = {
    urlPath: "user/save",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function editUserDetailsAPI(data) {
  const apiParams = {
    urlPath: "user-management/edit",
    method: "POST",
    options: {
      data,
    },
  };
  return makeCancellableAPIRequest(apiParams);
}

export function deleteUserDetailsAPI(id) {
  const apiParams = {
    urlPath: `user/delete/id?id=${id}`,
    method: "DELETE",
  };
  return makeCancellableAPIRequest(apiParams);
}
