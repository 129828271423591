import { omit } from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";

import { isLoggedIn } from "..";
import { customToast, setToken, setUserData } from "../common";
import { TOAST_DURATION, TOAST_TYPES } from "../constants";
import { logger } from "../logger";
import { triggerAccountDetailsAPI } from "../restAPI/AdminAPI";
import { triggerLoginAPI, triggerValidateLinkAPI } from "../restAPI/AuthAPI";

const ActiveUserContext = createContext({});

const Actions = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_ACCOUNT_DETAILS_DATA: "SET_ACCOUNT_DETAILS_DATA",
};

const initialState = {
  userData: null,
  accountDetails: null,
};

function reducer(state, { type, payload }) {
  switch (type) {
    case Actions.SET_USER_DATA:
      // sets token to localstorage
      if (payload?.access_token) {
        setToken({
          access_token: payload?.access_token,
          refresh_token: payload?.refresh_token,
        });
        return {
          ...state,
          userData: omit(payload, ["token"]),
        };
      }
      return {
        ...state,
        userData: payload,
      };
    case Actions.SET_ACCOUNT_DETAILS_DATA:
      return { ...state, accountDetails: payload };
    default:
      return state;
  }
}

const log = logger("ActiveUserContextProvider");
function ActiveUserContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { userData, accountDetails } = state;

  useEffect(() => {
    isLoggedIn() && triggerGetAccountDetails();

    setIsLoading(false);
  }, []);

  const triggerLogin = useCallback(({ params, cb }) => {
    const { promise, cancel } = triggerLoginAPI(params);
    promise
      .then((res) => {
        dispatch({
          type: Actions.SET_USER_DATA,
          payload: res.data,
        });
        triggerGetAccountDetails();

        // trigger's callback function
        cb && cb(false);
      })
      .catch((e) => {
        console.error(e);
      });

    return cancel;
  }, []);

  const triggerGetAccountDetails = () => {
    dispatch({
      type: Actions.SET_ACCOUNT_DETAILS_DATA,
      payload: null,
    });

    const { promise, cancel } = triggerAccountDetailsAPI();
    promise
      .then((res) => {
        dispatch({
          type: Actions.SET_ACCOUNT_DETAILS_DATA,
          payload: res?.data?.user,
        });
      })
      .catch((e) => {
        log.error(e);
      });
    return cancel;
  };

  const triggerValidateLinkData = ({ link, cb }) => {
    const { promise, cancel } = triggerValidateLinkAPI(link);
    promise
      .then((res) => {
        setUserData({ userId: res?.data?.userId });
        dispatch({
          type: Actions.SET_USER_DATA,
          payload: res.data,
        });

        customToast(
          res.message,
          TOAST_TYPES.SUCCESS,
          () => cb && cb(res.message),
          TOAST_DURATION.SHORT
        );
      })
      .catch((e) => {
        cb && cb();
        log.error(e);
      });
    return cancel;
  };

  return (
    <ActiveUserContext.Provider
      value={{
        triggerLogin,
        triggerValidateLinkData,
        userData,
        accountDetails,
        triggerGetAccountDetails,
      }}
    >
      {!isLoading && children}
    </ActiveUserContext.Provider>
  );
}

export default ActiveUserContextProvider;

export function withActiveUserContext(Component) {
  return (props) => (
    <ActiveUserContext.Consumer>
      {(context) => <Component activeUserContext={context} {...props} />}
    </ActiveUserContext.Consumer>
  );
}

export function useActiveUserContext() {
  return useContext(ActiveUserContext);
}
